// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-activity-dashboard__time-container {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  grid-area: user-activity-dashboard__time-container;
}

.time-container__time {
  font-size: 64px;
  font-weight: bold;
  color: var(--font-color);
  margin-bottom: -10px;
  margin-top: -10px;
}

.time-container__status-text {
  /* color: var(--font-color); */
}

.time-container__jornada-info {
  font-size: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/UserActivity/TimeContainer/TimeContainer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oCAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,wCAAwC;EACxC,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;EACnB,kDAAkD;AACpD;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".user-activity-dashboard__time-container {\r\n  text-align: center;\r\n  background-color: rgba(0, 0, 0, 0.2);\r\n  border-radius: 8px;\r\n  padding: 16px;\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-evenly;\r\n  align-items: center;\r\n  grid-area: user-activity-dashboard__time-container;\r\n}\r\n\r\n.time-container__time {\r\n  font-size: 64px;\r\n  font-weight: bold;\r\n  color: var(--font-color);\r\n  margin-bottom: -10px;\r\n  margin-top: -10px;\r\n}\r\n\r\n.time-container__status-text {\r\n  /* color: var(--font-color); */\r\n}\r\n\r\n.time-container__jornada-info {\r\n  font-size: 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
