/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";
export class User  {
    static async list() {
      const response = await HttpMethods.request_get("/v1/user/list");
      if (!response) alert("Error al obtener los usuarios");
      return response?.users;
    }
  
    static async listflat(first = 0, pagination = 100) {
      let response = await HttpMethods.request_get(`/v1/user/list?format=flat`);
      if (!response) {
        alert("Error al obtener los usuarios");
        response = [];
      }
      return response;
    }
  
    static async get() {
      const response = await HttpMethods.request_get(`/v1/user/get`);
      if (!response) alert("Error al obtener los datos de tu usuario");
      return response?.user;
    }
  
    static async create(data) {
      const response = await HttpMethods.request_post("/v1/user/create", data);
      if (response.error) {
        alert(
          "Error al crear el usuario." +
          `\nError: ${response.error}: ${response.message}`
        );
        return;
      }
      if (response.email)
        alert(`Usuario creado correctamente: ${response.email}`);
    }
  
    static async read(email) {
      const response = await HttpMethods.request_get(`/v1/user/read/${email}`);
      if (!response) alert("Error al obtener el usuario");
      return response?.user;
    }
  
    static async update(data) {
      const response = await HttpMethods.request_post("/v1/user/update", data);
      if (response.error)
        alert(
          "Error al actualizar el usuario." +
          `\nError: ${response.error}: ${response.message}`
        );
      // if (response.message) alert(`${response.message}`);
      return response?.user;
    }
  
    static async delete(email) {
      const response = await HttpMethods.request_delete("/v1/user/delete", email);
      if (response) {
        alert("Usuario eliminado correctamente");
      } else {
        alert("Error al eliminar el usuario");
      }
      return response;
    }
    static async analysis(email) {
      const response = await HttpMethods.request_get(`/v1/user/analysis/${email}`);
      if (!response) alert("Error al obtener el analisis del usuario");
      return response?.user;
    }
  }