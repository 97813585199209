import React from "react";
import PropTypes from "prop-types";
import StructureCard from "../StructureCard/StructureCard";
import "./StructureList.css";

const StructureList = React.memo(
  ({
    structures,
    path = [],
    handleAddNewProperty,
    dispatch,
    editedItems,
    manageModal,
    setupDragAndDrop,
    nested,
  }) => {
    return (
      <ul
        className={nested ? "structure-nested" : "structure-list"}
        data-droppable="true"
      >
        {structures.map((structure, index) => {
          const currentPath = [...path, index];
          const pathKey = currentPath.join("-");

          return (
              <StructureCard
                key={pathKey}
                structure={structure}
                currentPath={currentPath}
                pathKey={pathKey}
                handleAddNewProperty={handleAddNewProperty}
                dispatch={dispatch}
                editedItems={editedItems}
                manageModal={manageModal}
                setupDragAndDrop={setupDragAndDrop}
              />
          );
        })}
      </ul>
    );
  }
);

StructureList.displayName = "StructureList";

StructureList.propTypes = {
  structures: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.string,
      varname: PropTypes.string,
      title: PropTypes.string,
      datatype: PropTypes.string,
      structure: PropTypes.array,
    })
  ).isRequired,
  path: PropTypes.arrayOf(PropTypes.number),
  handleAddNewProperty: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      structure: PropTypes.array,
    }),
  }).isRequired,
  manageModal: PropTypes.func.isRequired,
  setupDragAndDrop: PropTypes.func.isRequired,
  nested: PropTypes.bool,
};

export default StructureList;
