/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Flow {
    static async reset() {
        const response = await HttpMethods.request_get("/v1/flow/reset");
        if (!response) alert("Error al resetear el flow");
        return response;
    }
    static async list(framecode, first = 0, pagination = 100, props) {
      let datacode = props.datacode
        if (datacode) {
            datacode = `&filetype=${datacode}`;
        } else {
            datacode = "";
        }
        let response = await HttpMethods.request_get(
            `/v1/flow/list/-/${framecode}/${first}/${pagination}?format=flat${datacode}`
        );
        if (!response) {
            alert("Error al obtener los flows");
            response = [];
        }
        return response;
    }

    static async dossier(dossierCode) {
        let response = await HttpMethods.request_get(
            `/v1/flow/dossier/${dossierCode}`
        );
        if (!response) {
            alert("Error al obtener los flows");
            response = [];
        }
        return response;
    }

    static async resetOneFlow(filetype) {
        const response = await HttpMethods.request_get(`/v1/flow/reset/${filetype}`);
        if (!response) alert("Error al resetear el flow");
        return response;
    }
}