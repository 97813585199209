import React, { useContext } from 'react';
import './ClockIns.css';
import PropTypes from "prop-types"
import { UserActivityContext } from 'src/modules/contexts//UserActivityContextProvider';
import { milisecondsToHHHMMM } from 'src/modules/actions/userActivityActions';
import ActionBubbles from './ActionBubbles/ActionBubbles';
function ClockIns({ onRequest }) {
    const { state } = useContext(UserActivityContext)
    const activities = state.activities
    const activitiesGroupedByDate = groupActivitiesByDate(activities)
    const dateOrderedByRecent = Object.keys(activitiesGroupedByDate).sort((a, b) => new Date(b) - new Date(a));

    function groupActivitiesByDate(array) {
        return array.reduce((acc, obj) => {
            const fecha = obj.time.split('T')[0];
            if (!acc[fecha]) {
                acc[fecha] = [];
            }
            acc[fecha].push(obj);

            return acc;
        }, {});
    }

    function getTimeFromDate(date) {
        return date.split('T').pop().split('.')[0]
    }
    return (
        <div className="user-activity-dashboard__clock-ins">
            <div className="clock-ins__header">
                <h2 className="clock-ins__title">Fichajes</h2>
                <button className="clock-ins__request-btn" onClick={onRequest}>Solicitar fichaje</button>
            </div>
            <div className="clock-ins__items">
                {dateOrderedByRecent.map((date, index) => {
                    return (
                        <div key={index} className="clock-in">
                            <div className='clock-in__date-worktime'>
                                <div className='date-worktime__date'>{date}</div>
                                <div className='date-worktime__worktime'>{milisecondsToHHHMMM(activitiesGroupedByDate[date][0].workingTime * 1000)}</div>
                            </div>
                            <div className='clock-in__activities'>
                                {activitiesGroupedByDate[date].map((activity, index2) => {
                                    return (
                                        <div className='activity' key={index2}>
                                            <div className="activity__time">{getTimeFromDate(activity.time)}</div>
                                            <div className={`activity__circle`}><ActionBubbles action={activity.action} /></div>
                                            <span className="activity__duration">{milisecondsToHHHMMM(activity.workingTime * 1000)}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default ClockIns;

ClockIns.propTypes = {
    onRequest: PropTypes.func
}


