import React, {
  createContext,
  useReducer,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { viewReducer } from '../reducers/viewReducer';
import { SSEClient } from 'src/lib/api/SSEClient';
import { useAppContext } from './AppContextProvider';

const initialState = {
  view: {},
  data: {},
  metaWindowData: {},
  users: [],
  selectedTask: '',
  loading: false,
  error: null,
};

export const ViewContext = createContext(initialState);

export const ViewContextProvider = ({ children }) => {
  const { userSession } = useAppContext();
  const [state, dispatch] = useReducer(viewReducer, initialState);
  const [isConnected, setIsConnected] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [error, setError] = useState(null);

  const retryCountRef = useRef(0);
  const reconnectTimeoutRef = useRef(null);

  const handleMessage = useCallback((data) => {
    // Solo despachamos si hay datos relevantes para los frames
    if (data?.frames || data?.frameUpdates) {
      const newFrames = data.frames || data.frameUpdates;
      if (!newFrames) return state;

      return {
        ...state,
        view: {
          ...state.view,
          frames: state.view.frames?.map(frame => {
            const updates = newFrames[frame._id];
            return updates ? { ...frame, ...updates } : frame;
          })
        }
      };
    }
  }, []);

  const handleError = useCallback((err) => {
    setIsConnected(false);
    setError(err.message || 'Error en la conexión de datos en tiempo real.');
    attemptReconnect();
  }, []);

  const handleComplete = useCallback(() => {
    setIsConnected(false);
    setError('La conexión SSE se ha cerrado.');
    attemptReconnect();
  }, []);

  const subscribe = useCallback(() => {
    if (SSEClient.isConnected || SSEClient.isConnecting) {
      return () => {};
    }

    const unsubscribe = SSEClient.subscribe({
      next: handleMessage,
      error: handleError,
      complete: handleComplete,
    });

    setIsConnected(true);
    return unsubscribe;
  }, [handleMessage, handleError, handleComplete]);

  const attemptReconnect = useCallback(() => {
    if (isReconnecting || SSEClient.isConnecting || SSEClient.isConnected) {
      return;
    }

    setIsReconnecting(true);

    if (retryCountRef.current < 5) {
      const retryDelay = Math.pow(2, retryCountRef.current) * 1000;

      reconnectTimeoutRef.current = setTimeout(() => {
        if (userSession.token) {
          subscribe();
          setError(null);
          retryCountRef.current += 1;
        }
        setIsReconnecting(false);
      }, retryDelay);
    } else {
      setError(
        'No se pudo reconectar con el servidor. Por favor, intenta de nuevo más tarde.'
      );
      setIsReconnecting(false);
    }
  }, [subscribe, userSession.token, isReconnecting]);

  useEffect(() => {
    if (!userSession.token) {
      return;
    }

    const unsubscribe = subscribe();
    retryCountRef.current = 0;

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
      setIsConnected(false);
    };
  }, [userSession.token, subscribe]);

  const value = {
    state,
    dispatch,
    error,
    isConnected
  };

  return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>;
};

ViewContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useViewContext = () => useContext(ViewContext);