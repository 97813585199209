import React, { useEffect, useRef, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Value from "../../common/Value/Value";
import { EditElement } from "../EditElement2";
import MermaidEditor from "../../common/MermaidEditor/MermaidEditor";
import useFormatters from "src/modules/hooks/useFormatters";

function normalizePath(path) {
  return path.replace(/\[(\d+)\]/g, ".$1");
}

function PrimitiveElement({
  element,
  data,
  selectedTask,
  path,
  scrollContainerRef,
  ...props
}) {
  const {
    mustache,
    config,
    onChangeValue,
    setupDragAndDrop,
    view,
    frame,
    setView,
    dc,
    item,
  } = props;

  const elementRef = useRef(null);
  const { formatCurrency } = useFormatters();

  const name = useMemo(() => (path ? `${path}` : element.variable), [path, element.variable]);

  const normalizedSelectedTask = useMemo(() => normalizePath(selectedTask || ""), [selectedTask]);
  const normalizedName = useMemo(() => normalizePath(name), [name]);

  const isSelected = useMemo(() => normalizedSelectedTask === normalizedName, [normalizedSelectedTask, normalizedName]);

  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (isSelected && elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });

      const timer1 = setTimeout(() => {
        setIsHighlighted(true);
        const timer2 = setTimeout(() => {
          setIsHighlighted(false);
        }, 1000);
        return () => clearTimeout(timer2);
      }, 300);

      return () => clearTimeout(timer1);
    }
  }, [isSelected]);

  const selectedStyle = useMemo(
    () => (isSelected ? { border: "2px solid var(--warning-color)", outline: "none" } : {}),
    [isSelected]
  );

  const newElement = useMemo(() => ({ ...element, variable: `{{${element.variable}}}` }), [element]);

  const label = useMemo(
    () =>
      element.label && element.label.includes("{")
        ? mustache.replaceMustache(element.label, item)
        : element.label,
    [element.label, mustache, item]
  );

  const link = useMemo(
    () =>
      element.link && element.link.includes("{")
        ? mustache.replaceMustache(element.link, item)
        : element.link,
    [element.link, mustache, item]
  );

  const mustacheValue = useMemo(() => mustache.replaceMustache(newElement.variable, item), [
    newElement.variable,
    mustache,
    item,
  ]);

  const value = useMemo(
    () =>
      data !== undefined ? data : mustacheValue !== "" ? mustacheValue : undefined,
    [data, mustacheValue]
  );

  if (
    newElement.required !== "no" ||
    (newElement.required === "no" && value !== undefined)
  ) {
    if (config.mode === "edit") {
      switch (element.type) {
        case "boolean": {
          return (
            <div
              ref={elementRef}
              tabIndex={isSelected ? 0 : -1}
              className={`row-element ${isHighlighted ? "highlight" : ""}`}
              style={selectedStyle}
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <Input
                type="checkbox"
                name={name}
                onBlur={onChangeValue}
                placeholder={element.default}
                defaultChecked={!!value}
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
              />
            </div>
          );
        }
        case "enum": {
          return (
            <div
              ref={elementRef}
              tabIndex={isSelected ? 0 : -1}
              className={`row-element ${isHighlighted ? "highlight" : ""}`}
              style={selectedStyle}
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <Select
                height="34px"
                name={name}
                options={element.enum ?? []}
                placeholder={element.default}
                onChange={(e) => onChangeValue(e, name)}
                value={
                  element.enum
                    ? element.enum.find((opt) => opt.value === String(value))
                    : value
                    ? { label: String(value), value: String(value) }
                    : null
                }
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
                activeLabel={true}
                isMulti={element.config?.isMulti ?? false}
              />
            </div>
          );
        }
        case "mermaid": {
          const val =
            data && data.includes("{{")
              ? mustache.replaceMustache(data, item)
              : value;
          return (
            <div
              ref={elementRef}
              tabIndex={isSelected ? 0 : -1}
              className={`row-element ${isHighlighted ? "highlight" : ""}`}
              style={selectedStyle}
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <MermaidEditor
                label={label}
                graph={val ?? ""}
                onChange={(value) => onChangeValue(value, name)}
              />
            </div>
          );
        }
        case "currency": {
          return (
            <div
              ref={elementRef}
              tabIndex={isSelected ? 0 : -1}
              className={`row-element ${isHighlighted ? "highlight" : ""}`}
              style={selectedStyle}
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <Input
                type="number"
                name={name}
                onBlur={(e) => onChangeValue(e, undefined, path, name)}
                placeholder={element.default}
                defaultValue={value || ""}
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
                style={{ height: "30px" }}
              />
            </div>
          );
        }
        default: {
          let showValue = value || "";
          if (
            (element.type === "datetime-local" || element.type === "date") &&
            showValue
          ) {
            const validDate = Date.parse(showValue);
            if (isNaN(validDate) && typeof showValue === "string") {
              let splitChar = "";
              if (showValue.includes("/")) splitChar = "/";
              else if (showValue.includes("-")) splitChar = "-";

              const parts = showValue.split(splitChar);
              const newDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
              showValue = Date.parse(newDate);
            }
          }
          if (showValue.length >= 75 && element.type !== "url") {
            return (
              <div
                ref={elementRef}
                tabIndex={isSelected ? 0 : -1}
                className={`row-element ${isHighlighted ? "highlight" : ""}`}
                style={selectedStyle}
                draggable
                data-dragdrop-object="item"
                data-dragdrop-index={element.variable}
                onDragStart={setupDragAndDrop}
              >
                <EditElement
                  element={element}
                  view={view}
                  frame={frame}
                  setView={setView}
                  config={{ duplicateElement: true }}
                  dataCode={dc}
                />
                <TextArea
                  name={name}
                  onBlur={onChangeValue}
                  placeholder={element.default}
                  defaultValue={showValue}
                  label={
                    element.link ? (
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {label}
                      </a>
                    ) : (
                      label
                    )
                  }
                />
              </div>
            );
          }
          return (
            <div
              ref={elementRef}
              tabIndex={isSelected ? 0 : -1}
              className={`row-element ${isHighlighted ? "highlight" : ""}`}
              style={selectedStyle}
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <Input
                type={element.type === "string" ? "text" : element.type}
                name={name}
                onBlur={(e) => onChangeValue(e, undefined, path, name)}
                placeholder={element.default}
                defaultValue={showValue}
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
                style={{ height: "30px" }}
              />
            </div>
          );
        }
      }
    } else {
      let displayValue = value;
      if (element.type === "currency") {
        displayValue = formatCurrency(value);
      }

      return (
        <div
          ref={elementRef}
          tabIndex={isSelected ? 0 : -1}
          className={isHighlighted ? "highlight" : ""}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "baseline",
            ...selectedStyle,
          }}
          draggable
          data-dragdrop-object="item"
          data-dragdrop-index={element.variable}
          onDragStart={setupDragAndDrop}
        >
          <EditElement
            element={element}
            view={view}
            frame={frame}
            setView={setView}
            config={{ duplicateElement: true }}
            dataCode={dc}
          />
          <h3 style={{ margin: 0 }}>
            {element.link ? (
              <a href={link} target="_blank" rel="noopener noreferrer">
                {label}
              </a>
            ) : (
              label
            )}
            :
          </h3>
          <Value
            type={element.type === "string" ? "text" : element.type}
            content={displayValue}
            options={element.options || []}
          />
        </div>
      );
    }
  } else {
    return null;
  }
}

PrimitiveElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.any,
  selectedTask: PropTypes.string,
  path: PropTypes.string,
  mustache: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  setupDragAndDrop: PropTypes.func.isRequired,
  view: PropTypes.object.isRequired,
  frame: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
  dc: PropTypes.string.isRequired,
  deleteItemInArray: PropTypes.func,
  item: PropTypes.object.isRequired,
  buttonsStyle: PropTypes.object,
  scrollContainerRef: PropTypes.object,
};

export default React.memo(PrimitiveElement);
