import React, { useContext, useEffect } from 'react';
import StatusBubbles from '../StatusBubbles/StatusBubbles';
import JornadaInfo from '../JornadaInfo/JornadaInfo';
import './TimeContainer.css';
import { UserActivityContext } from 'src/modules/contexts/UserActivityContextProvider';
import { milisecondsToHHMM } from 'src/modules/actions/userActivityActions';
import { setCurrentTime } from 'src/modules/actions/userActivityActions';

function TimeContainer() {
  const { state, dispatch } = useContext(UserActivityContext);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeNow = new Date()
      const hoursNow = timeNow.getHours()
      const minutesNow = timeNow.getMinutes()
      const hoursPrevious = Math.floor(state.currentTime / (1000 * 60 * 60))
      const minutesPrevious = Math.floor((state.currentTime % (1000 * 60 * 60)) / (1000 * 60))
      if (hoursNow !== hoursPrevious || minutesNow !== minutesPrevious) {
        setCurrentTime(dispatch);
      }
    }, 1_000)
    return () => clearInterval(intervalId)
  }, [dispatch, state.currentTime])


  return (
    <div className="user-activity-dashboard__time-container">
      <div className="time-container__time">{milisecondsToHHMM(state.currentTime)}</div>
      <StatusBubbles />
      <div className="time-container__status-text">Tu jornada ahora</div>
      <JornadaInfo />
    </div>
  );
}


export default TimeContainer;
