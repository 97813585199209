import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

const observersMap = new Map();
const observerCallbacksMap = new Map();

const getObserver = (root, rootMargin, threshold) => {
  const key = `${root || 'null'}|${rootMargin}|${Array.isArray(threshold) ? threshold.join(',') : threshold}`;
  if (!observersMap.has(key)) {
    const observer = new IntersectionObserver((entries, obs) => handleIntersections(obs, entries), {
      root: root || null,
      rootMargin,
      threshold,
    });
    observersMap.set(key, observer);
    observerCallbacksMap.set(observer, new Map());
  }
  return observersMap.get(key);
};

const handleIntersections = (observer, entries) => {
  const callbacks = observerCallbacksMap.get(observer);
  if (!callbacks) return;

  entries.forEach(entry => {
    const callback = callbacks.get(entry.target);
    if (callback) {
      callback(entry);
    }
  });
};

const LazyLoad = ({ children, rootMargin = "200px 0px 200px 0px", threshold = 0.1, root, forceLoad }) => {
  const [isVisible, setIsVisible] = useState(forceLoad || false);
  const ref = useRef();

  const observer = useMemo(() => getObserver(root, rootMargin, threshold), [root, rootMargin, threshold]);

  const handleIntersection = useCallback(
    (entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target);
        const callbacks = observerCallbacksMap.get(observer);
        if (callbacks) {
          callbacks.delete(entry.target);
        }
      }
    },
    [observer]
  );

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return;

    if (forceLoad) {
      setIsVisible(true);
      return;
    }

    const callbacks = observerCallbacksMap.get(observer);
    if (callbacks) {
      callbacks.set(currentRef, handleIntersection);
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
        if (callbacks) {
          callbacks.delete(currentRef);
        }
      }
    };
  }, [handleIntersection, observer, forceLoad]);

  return <div ref={ref}>{isVisible ? children : null}</div>;
};

LazyLoad.propTypes = {
  children: PropTypes.node.isRequired,
  rootMargin: PropTypes.string,
  threshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  root: PropTypes.object,
  forceLoad: PropTypes.bool,
};

export default React.memo(LazyLoad);
