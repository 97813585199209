import {
  addErrorMessageGlobal,
  addMessageGlobal,
} from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Executive {
  static async list() {
    let response = await HttpMethods.request_get(
      `/v1/executive/list?format=flat`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los executives");
      response = [];
    }
    return response;
  }

  static async listWithoutFlat() {
    let response = await HttpMethods.request_get(`/v1/executive/list`);
    if (!response) {
      addErrorMessageGlobal("Error al obtener los executives");
      response = [];
    }
    return response;
  }

  static async create(data) {
    const response = await HttpMethods.request_post(
      "/v1/executive/crupdate",
      data
    );
    // if (response.error) {
    //   addErrorMessageGlobal(
    //     `Error al crear el executive.\nError: ${response.error}: ${response.message}`
    //   );
    // }
    // if (response.result) {
    //   addMessageGlobal(`${response.result}`);
    // }
    return response?.executive;
  }

  static async read(executiveid) {
    const response = await HttpMethods.request_get(
      `/v1/executive/read/${executiveid}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al leer el executive");
    }
    return response;
  }

  static async delete(executiveid) {
    const response = await HttpMethods.request_delete(
      "/v1/executive/delete",
      executiveid
    );
    // if (response.result) {
    //   addMessageGlobal(`${response.result}`);
    // }
    return response;
  }

  static async question(agentid, messages, threadid) {
    const data = {
      agentid: agentid,
      messages: messages,
    };
    if (threadid) data.threadid = threadid;
    const response = await HttpMethods.request_post(
      "/v1/executive/question",
      data
    );
    if (!response)
      addErrorMessageGlobal("Error al obtener la respuesta del executivo");
    return response.agent;
  }
}
