import React from 'react'
import PropTypes from "prop-types"; // Importa PropTypes
import Table from "src/modules/components/common/Table/Table";

const DevicesTable = ({ inputValues, editDevice }) => {
    const columns = [
        {
            label: "Permitido",
            key: "permited",
            type: "checkbox",
            style_header_cell: { maxWidth: "100px" },
            style_body_cell: { maxWidth: "100px" },
        },
        {
            label: "Primer inicio de sesión",
            key: "first",
            type: "string",
            style_header_cell: { minWidth: "200px" },
            style_body_cell: { minWidth: "200px" },
        },
        {
            label: "Último inicio de sesión",
            key: "last",
            type: "string",
            style_header_cell: { minWidth: "200px" },
            style_body_cell: { minWidth: "200px" },
        },
        {
            label: "ID",
            key: "id",
            type: "string",
            style_header_cell: { minWidth: "100px" },
            style_body_cell: { minWidth: "100px" },
        },
        {
            label: "Sistema Operativo",
            key: "OS",
            type: "string",
            style_header_cell: { minWidth: "100px" },
            style_body_cell: { minWidth: "100px" },
        },
        {
            label: "Navegador",
            key: "browser",
            type: "string",
            style_header_cell: { minWidth: "100px" },
            style_body_cell: { minWidth: "100px" },
        },
        {
            label: "Versión del navegador",
            key: "version",
            type: "string",
            style_header_cell: { minWidth: "100px" },
            style_body_cell: { minWidth: "100px" },
        },
        {
            label: "Dispositivo",
            key: "platform",
            type: "string",
            style_header_cell: { minWidth: "100px" },
            style_body_cell: { minWidth: "100px" },
        },
        {
            label: "Sistema Operativo Versión",
            key: "OSversion",
            type: "string",
            style_header_cell: { minWidth: "300px" },
            style_body_cell: { minWidth: "300px" },
        },
        {
            label: "Versión del dispositivo",
            key: "pversion",
            type: "string",
            style_header_cell: { minWidth: "300px" },
            style_body_cell: { minWidth: "300px" },
        },
        {
            label: "Acciones",
            key: "table_actions",
            type: "actions",
            style_header_cell: { minWidth: "100px" },
            style_body_cell: { minWidth: "100px" },
            buttons: [
                { icon: "EditIcon", onClick: (e) => { } },
                { icon: "DeleteIcon", onClick: (e) => { } },
            ],
        },
    ];

    const fakeData = [
        {
            first: "2022-01-01T00:00:00Z",
            last: "2022-01-01T00:00:00Z",
            id: "abc123",
            name: "Mi dispositivo",
            ip: "192.168.1.1",
            OS: "Windows",
            OSversion: "10.0",
            browser: "Chrome",
            version: "96.0.4664.110",
            platform: "Desktop",
            pversion: "10.0.0",
            permited: true,
        },
        {
            first: "2022-01-01T00:00:00Z",
            last: "2022-01-01T00:00:00Z",
            id: "abc123",
            name: "Mi dispositivo",
            ip: "192.168.1.1",
            OS: "Windows",
            OSversion: "10.0",
            browser: "Chrome",
            version: "96.0.4664.110",
            platform: "Desktop",
            pversion: "10.0.0",
            permited: true,
        },
        {
            first: "2022-01-01T00:00:00Z",
            last: "2022-01-01T00:00:00Z",
            id: "abc123",
            name: "Mi dispositivo",
            ip: "192.168.1.1",
            OS: "Windows",
            OSversion: "10.0",
            browser: "Chrome",
            version: "96.0.4664.110",
            platform: "Desktop",
            pversion: "10.0.0",
            permited: true,
        },
    ];

    const tableStyle = {
        maxWidth: "800px",
        maxHeight: "492px",
    };

    return (
        <>
            <div style={{ fontSize: 30, textAlign: "center", fontWeight: "bold" }}>
                Dispositivos
            </div>
            <Table columns={columns} data={fakeData} style={tableStyle} />
        </>
    );
};

export default DevicesTable

DevicesTable.propTypes = {
    inputValues: PropTypes.arrayOf(PropTypes.shape({
        first: PropTypes.string,
        last: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        ip: PropTypes.string,
        OS: PropTypes.string,
        OSversion: PropTypes.string,
        browser: PropTypes.string,
        version: PropTypes.string,
        platform: PropTypes.string,
        pversion: PropTypes.string,
        permited: PropTypes.boolean,
    })),
    editDevice: PropTypes.func.isRequired,
};