// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group {
  display: flex;
  align-items: center; /* Centramos verticalmente los elementos */
  margin-bottom: 20px;
}
.label {
  width: 20%;
}
.input {
  width: 80%;
}
.users-columns {
  align-items: center;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Users/User.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAE,0CAA0C;EAC/D,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".input-group {\r\n  display: flex;\r\n  align-items: center; /* Centramos verticalmente los elementos */\r\n  margin-bottom: 20px;\r\n}\r\n.label {\r\n  width: 20%;\r\n}\r\n.input {\r\n  width: 80%;\r\n}\r\n.users-columns {\r\n  align-items: center;\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
