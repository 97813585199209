import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ArrayElement from "./ArrayElement";
import ObjectElement from "./ObjectElement";
import PrimitiveElement from "./PrimitiveElement";
import LazyLoad from "src/routes/Structure/LazyLoad/LazyLoad";

function normalizePath(path) {
  return path.replace(/\[(\d+)\]/g, ".$1");
}

const ElementRenderer = React.memo(function ElementRenderer({
  forceChildLoad = false,
  element,
  data,
  path,
  rootRef,
  selectedTask,
  scrollContainerRef,
  ...props
}) {
  const normalizedSelectedTask = useMemo(
    () => normalizePath(selectedTask || ""),
    [selectedTask]
  );

  const normalizedPath = useMemo(
    () => normalizePath(path || ""),
    [path]
  );

  const isSelected = normalizedSelectedTask === normalizedPath;

  const root = useMemo(
    () => (rootRef ? rootRef.current : null),
    [rootRef]
  );

  const forceLoad = forceChildLoad || isSelected;

  const lazyLoadProps = useMemo(
    () => ({
      root,
      forceLoad,
    }),
    [root, forceLoad]
  );

  switch (element.type) {
    case "array":
      return (
        <LazyLoad {...lazyLoadProps}>
          <ArrayElement
            element={element}
            data={data}
            path={path}
            selectedTask={selectedTask}
            scrollContainerRef={scrollContainerRef}
            {...props}
          />
        </LazyLoad>
      );
    case "object":
      return (
        <LazyLoad {...lazyLoadProps}>
          <ObjectElement
            element={element}
            data={data}
            path={path}
            selectedTask={selectedTask}
            scrollContainerRef={scrollContainerRef}
            {...props}
          />
        </LazyLoad>
      );
    default:
      return (
        <LazyLoad {...lazyLoadProps}>
          <PrimitiveElement
            element={element}
            data={data}
            path={path}
            selectedTask={selectedTask}
            scrollContainerRef={scrollContainerRef}
            {...props}
          />
        </LazyLoad>
      );
  }
});

ElementRenderer.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.any,
  path: PropTypes.string,
  selectedTask: PropTypes.string,
  scrollContainerRef: PropTypes.object,
  deleteItemInArray: PropTypes.func,
  rootRef: PropTypes.shape({ current: PropTypes.any }),
  forceChildLoad: PropTypes.bool,
};

ElementRenderer.displayName = "ElementRenderer";

export default ElementRenderer;
