import mermaid from "mermaid";
import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';

mermaid.initialize({
  startOnLoad: false, // Desactivar la carga automática para mayor control
  theme: "default",
  securityLevel: "loose",
  suppressErrorRendering:true,
  themeCSS: `
    g.classGroup rect {
      fill: #282a36;
      stroke: #6272a4;
    } 
    g.classGroup text {
      fill: #f8f8f2;
    }
    g.classGroup line {
      stroke: #f8f8f2;
      stroke-width: 0.5;
    }
    .classLabel .box {
      stroke: #21222c;
      stroke-width: 3;
      fill: #21222c;
      opacity: 1;
    }
    .classLabel .label {
      fill: #f1fa8c;
    }
    .relation {
      stroke: #ff79c6;
      stroke-width: 1;
    }
    #compositionStart, #compositionEnd {
      fill: #bd93f9;
      stroke: #bd93f9;
      stroke-width: 1;
    }
    #aggregationEnd, #aggregationStart {
      fill: #21222c;
      stroke: #50fa7b;
      stroke-width: 1;
    }
    #dependencyStart, #dependencyEnd {
      fill: #00bcd4;
      stroke: #00bcd4;
      stroke-width: 1;
    } 
    #extensionStart, #extensionEnd {
      fill: #f8f8f2;
      stroke: #f8f8f2;
      stroke-width: 1;
    }`,
  fontFamily: "Fira Code",
});

const Graph = ({ chart }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const updateChart = async () => {
      if (chartRef.current && chart) {

        try {
          // Validar la sintaxis del diagrama Mermaid de forma asíncrona
          const resultParse = await mermaid.parse(chart)

          if(!resultParse) return

          // Si el gráfico es válido, limpiamos el contenido anterior
          chartRef.current.innerHTML = ''; 

          // Crear un nuevo contenedor para el gráfico
          const div = document.createElement('div');
          div.classList.add('mermaid');
          div.innerHTML = chart.trim();
          chartRef.current.appendChild(div);

          // Inicializar y renderizar el gráfico
          mermaid.init({suppressErrorRendering:true, flowchart:{htmlLabels:false}}, div);

        } catch (parseError) {
          // Capturamos cualquier error de sintaxis o renderizado
          console.error("Error rendering Mermaid chart:", parseError.message);
        }
      }
    };

    updateChart();
  }, [chart]);

  const style = { height: "100%", width: "100%" };

  return (
    <div style={style}>
      <div ref={chartRef} />
    </div>
  );
};

Graph.propTypes = {
  chart: PropTypes.string.isRequired,
};

export default Graph;
