import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Editor } from "@monaco-editor/react";
import "./planner.css";
import constants from "src/definitions/constants";
import NodeViewer from "./NodeViewer/NodeViewer";
import { CustomEdge } from "./Edges/CustomEdge";
import PlannerNode from "./Nodes/PlannerNode/PlannerNode";
import { Agent, Filetype, Template } from "src/lib/api";
import NotesNode from "./Nodes/NotesNode/NotesNode";
import useReconstructPlanner from "./useReconstructPlanner";
import * as helper from "./helper";
import DefaultSidebarItems from "./Sidebar/DefaultSidebarItems";
import ActionNodeSidebar from "./Sidebar/Nodetypes/ActionNodeSidebar";
import AiNodeSidebar from "./Sidebar/Nodetypes/AiNodeSidebar";
import CreateDocNodeSidebar from "./Sidebar/Nodetypes/CreateDocNodeSidebar";
import DocumentNodeSidebar from "./Sidebar/Nodetypes/DocumentNodeSidebar";
import EventNodeSidebar from "./Sidebar/Nodetypes/EventNodeSidebar";
import VariablesNodeSidebar from "./Sidebar/Nodetypes/VariablesNodeSidebar";

const edgeTypes = {
  customEdge: CustomEdge,
};

const nodeTypes = {
  plannerNode: PlannerNode,
  stickyNote: NotesNode,
};

const Planner = ({ cartridge, getInfo, initialNodes }) => {
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [availableVariables, setAvailableVariables] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const directionToCreate = useRef(null);
  const { screenToFlowPosition } = useReactFlow();

  useEffect(() => {
    const fetchData = async () => {
      const templates = await Template.list();
      setTemplateOptions(
        templates
          .filter((template) => template.scope === "dossier")
          .map((template) => ({ label: template.name, value: template.code }))
      );
      const { agents } = await Agent.list();
      if (!agents) return;
      setAgentOptions(
        agents.map((agent) => ({ label: agent.name, value: agent.id }))
      );
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!cartridge.filetype) return;
      const filetype = await Filetype.read(cartridge.filetype);
      const { structure } = filetype.filetype;
      const values = helper.extractLabelsAndValues(structure);
      setAvailableVariables(values);
    };
    fetchData();
  }, [cartridge]);

  
  const onClickDelete = () => {
    setNodes((prevNodes) => {
      const newNodes = prevNodes.filter((node) => node.id !== selectedNode.id);
      return newNodes;
    }, [])
  }

  const areEdgesEqual = (edgesA, edgesB) => {
    if (edgesA.length !== edgesB.length) {
      return false;
    }

    for (let i = 0; i < edgesA.length; i++) {
      // Eliminamos la propiedad 'selected' de la comparación
      const edgeAWithoutSelected = { ...edgesA[i] };
      const edgeBWithoutSelected = { ...edgesB[i] };
      delete edgeAWithoutSelected.selected;
      delete edgeBWithoutSelected.selected;

      if (!helper.isEqual(edgeAWithoutSelected, edgeBWithoutSelected)) {
        return false;
      }
    }

    return true;
  };

  const checkEdgesStroke = (edgesToLoop) => {
    const getEdgeStyle = (targetNode) => {
      const { logic, must, type, variables } = targetNode?.data || {};
      if (!logic || (typeof logic === "string" && logic.trim().length <= 0))
        return {};
      const isColored =
        must ||
        type === "ai" ||
        type === "createdoc" ||
        (Array.isArray(variables) &&
          variables.some((variable) => variable.must));

      return {
        strokeDasharray: "5 5",
        animation: "dashdraw 0.5s linear infinite",
        stroke: isColored ? "red" : "#b1b1b7",
      };
    };

    return edgesToLoop.map((edge) => {
      const targetNode = getNodeInfoById(edge.target);
      return {
        ...edge,
        style: getEdgeStyle(targetNode),
        type: "customEdge",
      };
    });
  };

  useEffect(() => {
    if (edges.length > 0 || selectedNode?.data.logic) {
      const newEdges = checkEdgesStroke(edges);
      if (!areEdgesEqual(newEdges, edges)) {
        setEdges(newEdges);
      }
    }
  }, [edges.length, selectedNode?.data?.logic]);

  const getNodeInfoById = (id, nodesToUse = nodes) => {
    return nodesToUse.find((node) => node.id === id);
  };

  const onConnect = useCallback((params) => {
    // reset the start node on connections
    connectingNodeId.current = null;
    setEdges((eds) => addEdge(params, eds));
  }, []);

  const onConnectStart = useCallback((_, { nodeId }) => {
    directionToCreate.current = _.target.dataset.handlepos;
    connectingNodeId.current = nodeId;
  }, []);

  const createNewNode = (
    event,
    id,
    type = constants.nodeTypes.document.value
  ) => {
    const nodeID = id || helper.getId();

    const position = helper.getPosition(event, nodes, screenToFlowPosition);
    const newNode =
      type !== constants.nodeTypes.note.value
        ? helper.createPlannerNode(nodeID, position, type)
        : helper.createStickyNote(nodeID, position, onChange);

    setNodes((nds) => {
      const nodes = nds.map((node) =>
        node.id === selectedNode?.id ? { ...node, selected: false } : node
      );
      return nodes.concat(newNode);
    });

    setSelectedNode(newNode);
  };

  const createNewEdge = (id) => {
    const edgeDirections = {};
    if (
      directionToCreate.current === "right" ||
      directionToCreate.current === "bottom"
    ) {
      edgeDirections.source = connectingNodeId.current;
      edgeDirections.target = id;
      edgeDirections.sourceHandle = directionToCreate.current;
      edgeDirections.targetHandle =
        directionToCreate.current === "right" ? "left" : "top";
    } else {
      edgeDirections.source = id;
      edgeDirections.target = connectingNodeId.current;
      edgeDirections.targetHandle = directionToCreate.current;
      edgeDirections.sourceHandle =
        directionToCreate.current === "left" ? "right" : "bottom";
    }
    const newEdge = {
      id,
      source: edgeDirections.source,
      target: edgeDirections.target,
      sourceHandle: edgeDirections.sourceHandle || null,
      targetHandle: edgeDirections.targetHandle || null,
      interactionWidth: 1000,
      data: { label: "" },
      type: "customEdge",
    };
    setEdges((eds) => eds.concat(newEdge));
  };

  const onConnectEnd = (event) => {
    const targetIsPane = event.target.classList.contains("react-flow__pane");
    if (!targetIsPane) {
      return;
    }
    const id = helper.getId();

    createNewNode(event, id);

    createNewEdge(id);
  };

  const onNodeClick = (event, node) => {
    setSelectedNode(node);
  };

  const closeEditor = () => {
    setSelectedNode(null);
  };

  const onNodesDelete = () => {
    closeEditor();
  };

  const shouldUpdateName = (data, name) => {
    const nameByType = helper.nameByType(data.type);
    return (
      (nameByType === data.name || data.name === "") &&
      name !== "name" &&
      name === "type"
    );
  };

  const editPlannerNode = (targetNode, event, name) => {
    const value = getValueByEvent(event, targetNode.data.type);

    if (shouldUpdateName(targetNode.data, name)) {
      targetNode.data.name =
        helper.nameByType(value) || helper.nameByType(targetNode.data.type);
      targetNode.data.label = targetNode.data.name;
    }

    const updatedData = {
      ...targetNode.data,
      [name]: value,
      ...(name === "type" && value === "variable" && { variables: [] }),
    };

    updatedData.label = updatedData.name;

    const updatedStyle = {
      ...targetNode.style,
      backgroundColor: helper.colorByType(updatedData.type),
    };

    const updatedClassName = updatedData.logic
      ? "border-logic"
      : "base-border-node";

    return {
      ...targetNode,
      data: updatedData,
      style: updatedStyle,
      className: updatedClassName,
    };
  };

  const getValueByEvent = (event, type) => {
    if (!event) return "";
    if (type === "variable" && !event.type && !event.value) return event;
    if (event.target?.type === "checkbox") return event.target.checked;
    if (event.value) return event.value;
    if (!event.value && !event.target) return event;
    return event.target.value;
  };

  const onChange = (name, id) => (event) => {
    setNodes((prevNodes) => {
      const targetNode = getNodeInfoById(id || selectedNode.id, prevNodes);
      const targetIndex = prevNodes.indexOf(targetNode);

      if (targetIndex === -1) return prevNodes;

      const nodeUpdaters = {
        [constants.nodeByType.plannerNode]: (node) =>
          editPlannerNode(node, event, name),
        [constants.nodeByType.stickyNote]: (node) => ({
          ...node,
          data: { ...node.data, [name]: event },
        }),
      };

      const updateNode =
        nodeUpdaters[targetNode.type] || ((node) => ({ ...node }));

      const updatedNode = updateNode(targetNode);

      const updatedNodes = [...prevNodes];
      updatedNodes[targetIndex] = updatedNode;

      setSelectedNode(updatedNode);
      return updatedNodes;
    });
  };

  const createOption = (value) => ({
    label: value,
    value,
  });

  const onCreateOption = (inputValue) => {
    const newOption = createOption(inputValue);
    setAvailableVariables((prev) => [...prev, newOption]);
    // onChange(newOption);
  };

  useEffect(() => {
    getInfo(nodes, edges);
  }, [nodes, edges]);

  const renderSidebar = () => {
    if (!selectedNode) return;
    const data = selectedNode.data;

    const mappedComponents = {
      createdoc: () => {
        return (
          <CreateDocNodeSidebar
            data={data}
            onChange={onChange}
            templateOptions={templateOptions}
          />
        );
      },
      document: () => {
        return <DocumentNodeSidebar data={data} onChange={onChange} />;
      },
      event: () => {
        return <EventNodeSidebar data={data} onChange={onChange} />;
      },
      action: () => {
        return <ActionNodeSidebar data={data} onChange={onChange} />;
      },
      ai: () => {
        return (
          <AiNodeSidebar
            data={data}
            onChange={onChange}
            agentOptions={agentOptions}
            availableVariables={availableVariables}
          />
        );
      },
      variable: () => {
        return (
          <VariablesNodeSidebar
            data={data}
            onChange={onChange}
            onCreateOption={onCreateOption}
            setVariables={onChange("variables")}
            availableVariables={availableVariables}
          />
        );
      },
      default: () => {
        return <DefaultSidebarItems data={data} onChange={onChange} />;
      },
    };

    return (mappedComponents[data.type] || mappedComponents.default)();
  };

  useReconstructPlanner(initialNodes, setNodes, setEdges, onChange);

  const renderMonaco = () => {
    if (!selectedNode) return;

    return (
      <Editor
        defaultLanguage="markdown"
        value={selectedNode.data.content}
        onChange={onChange("content")}
        theme="vs-dark"
        width={500}
        height={500}
      />
    );
  };
  return (
    <NodeViewer
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      onNodeClick={onNodeClick}
      hasSidebar={true}
      renderSidebar={renderSidebar}
      renderMonaco={renderMonaco}
      canCreateNodes={true}
      createNewNode={createNewNode}
      onCloseEditor={closeEditor}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodesDelete={onNodesDelete}
      onClickDelete={onClickDelete}
    />
  );
};

Planner.propTypes = {
  initialNodes: PropTypes.array.isRequired,
  getInfo: PropTypes.func.isRequired,
  cartridge: PropTypes.shape({
    filetype: PropTypes.string,
  }).isRequired,
};

const PlannerWithProvider = (props) => (
  <ReactFlowProvider>
    <Planner {...props} />
  </ReactFlowProvider>
);

export default PlannerWithProvider;
