// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test-group {
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.test-group p {
  margin: 0;
}

/* Estilos para la tabla de variables */
.vars {
  margin-top: 10px;
}

.test-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Estilos para el botón "Mostrar Vars" */
.toggle-vars {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 5px;
}

.toggle-vars[data-state="shown"] {
  background-color: #3659f4;
}`, "",{"version":3,"sources":["webpack://./src/routes/Checks/Test/Test.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA,uCAAuC;AACvC;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA,yCAAyC;AACzC;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".test-group {\r\n  margin-bottom: 10px;\r\n  background: rgba(0, 0, 0, 0.2);\r\n  padding: 10px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  row-gap: 1rem;\r\n}\r\n\r\n.test-group p {\r\n  margin: 0;\r\n}\r\n\r\n/* Estilos para la tabla de variables */\r\n.vars {\r\n  margin-top: 10px;\r\n}\r\n\r\n.test-title{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n/* Estilos para el botón \"Mostrar Vars\" */\r\n.toggle-vars {\r\n  background-color: #4caf50;\r\n  color: white;\r\n  border: none;\r\n  padding: 5px 10px;\r\n  cursor: pointer;\r\n  border-radius: 4px;\r\n  margin-top: 5px;\r\n}\r\n\r\n.toggle-vars[data-state=\"shown\"] {\r\n  background-color: #3659f4;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
