import React, { useEffect, useState } from "react";
import "./Agentes.css";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Select from "src/modules/components/common/Select/Select";
import constants from "src/definitions/constants";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Chat from "../Chat/Chat";
import Icon from "@mui/material/Icon";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const tiposAgentes = Object.entries(constants.tiposAgentes).map(
  ([key, value]) => ({
    value: value.value,
    label: value.label,
  })
);

const Agentes = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();

  const [agentes, setAgentes] = useState(setupInfo?.agents || []);
  const [selectedAgente, setSelectedAgente] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const [editedAgente, setEditedAgente] = useState(null);
  const [newAgente, setNewAgente] = useState(false);
  const [selectedChatAgent, setSelectedChatAgent] = useState(null);

  useEffect(() => {
    setSetupInfo((prev) => {
      return { ...prev, agents: agentes };
    });
  }, [setAgentes, setSetupInfo, agentes]);

  const handleAgenteClick = (index) => {
    setSelectedAgente(agentes[index]);
    setEditedAgente({ ...agentes[index] }); // Al empezar a editar, se copian los valores del agente para realizar un seguimiento de los cambios
    setOpenBox(true);
  };

  const handleEditAgente = (e) => {
    setEditedAgente((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const agregarElemento = () => {
    const item = {
      id: crypto.randomUUID(),
      name: "",
      description: "",
      type: "",
      apikey: "",
      agentid: "",
      systemprompt: "",
      url: "",
      model: "",
    };
    setEditedAgente(item);
    setSelectedAgente(item);
    setNewAgente(true);
    setOpenBox(true);
  };
  const handleSaveAgente = () => {
    if (newAgente) {
      setAgentes((prev) => {
        return [...prev, editedAgente];
      });
    } else {
      const updatedAgentes = agentes.map((agente) =>
        agente === selectedAgente ? editedAgente : agente
      );
      setAgentes(updatedAgentes);
    }
    setOpenBox(false);
    setSelectedAgente(null);
    setEditedAgente(null);
    setNewAgente(false);
  };

  const handleDeleteAgente = () => {
    const filteredAgentes = agentes.filter(
      (agente) => agente !== selectedAgente
    );
    setAgentes(filteredAgentes);
    setOpenBox(false);
    setSelectedAgente(null);
    setEditedAgente(null);
  };

  function onSelectChangeSingle(event, setValues, property) {
    const values = event?.value;
    setValues((prev) => {
      return { ...prev, [property]: values };
    });
  }

  const onClickExit = () => {
    setOpenBox(false);
    setEditedAgente(null); // Limpiar el estado de edición si se cierra el editor
    setNewAgente(false);
    setSelectedAgente(null);
  };
  const footerBoxFloat = (
    <div className="agente-container-buttons">
      <Button icon="save" onClick={handleSaveAgente} />
      {!newAgente && <Button icon="delete" onClick={handleDeleteAgente} />}
    </div>
  );
  return (
    <div className="agentes-container">
      {openBox && (selectedAgente || newAgente) && (
        <BoxFloat
          title={selectedAgente ? "Editar Agente" : "Agregar Agente"}
          onClickExit={onClickExit}
          onClose={selectedAgente ? onClickExit : undefined}
          footer={footerBoxFloat}
        >
          <Input
            name="name"
            type="text"
            placeholder=""
            onChange={handleEditAgente}
            value={editedAgente ? editedAgente["name"] : ""}
            label="Nombre"
          />
          <Input
            name="description"
            type="text"
            placeholder=""
            onChange={handleEditAgente}
            value={editedAgente ? editedAgente["description"] : ""}
            label="Descripción"
          />
          <Select
            name="type"
            options={tiposAgentes}
            onChange={(event) =>
              onSelectChangeSingle(event, setEditedAgente, "type")
            }
            label="Tipo"
            activeLabel={true}
            value={tiposAgentes.find(
              (tipo) => tipo.value === editedAgente["type"]
            )}
          />
          {/** OPEN API Agent*/}
          {editedAgente["type"] === constants.tiposAgentes.openai.value && (
            <>
              <Input
                name="apikey"
                type="text"
                placeholder=""
                onChange={handleEditAgente}
                value={editedAgente ? editedAgente["apikey"] : ""}
                label="API Key"
              />
              <Input
                name="agentid"
                type="text"
                placeholder=""
                onChange={handleEditAgente}
                value={editedAgente ? editedAgente["agentid"] : ""}
                label="Agent ID"
              />
            </>
          )}
          {/** OPEN LLM */}
          {editedAgente["type"] === constants.tiposAgentes.openllm.value && (
            <>
              <Input
                name="url"
                type="text"
                placeholder="http://192.168.40.90:11434/api/chat"
                onChange={handleEditAgente}
                value={editedAgente ? editedAgente["url"] : ""}
                label="URL"
              />
              <Input
                name="model"
                type="text"
                placeholder="llama3"
                onChange={handleEditAgente}
                value={editedAgente ? editedAgente["model"] : ""}
                label="Modelo"
              />
              <TextArea
                name="systemprompt"
                onBlur={handleEditAgente}
                defaultValue={editedAgente["systemprompt"]}
                label="System Prompt"
                styleTextArea={{ minHeight: "80px" }}
              />
            </>
          )}
        </BoxFloat>
      )}
      <div className="display-agentes">
        {agentes?.map((agente, index) => (
          <div
            key={index}
            className="agente-item"
            onClick={() => handleAgenteClick(index)}
          >
            <div className="agente-item-title">{agente.name}</div>
            <div className="agente-item-description">{agente.description}</div>
            <div
              className="agente-item-chatIcon"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedChatAgent(agente);
              }}
            >
              <Icon>chaticon</Icon>
            </div>
          </div>
        ))}
        <div
          className="agente-item agente-item-add-last"
          onClick={agregarElemento}
        >
          <p>Agregar más</p>
        </div>
      </div>
      {selectedChatAgent && (
        <div className="agente-chat-box">
          <Chat agente={selectedChatAgent} setAgente={setSelectedChatAgent} />
        </div>
      )}
    </div>
  );
};

export default Agentes;
