import { useState } from 'react';

export const useCookie = (key, initialValue) => {
  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        try {
          return JSON.parse(c.substring(nameEQ.length, c.length));
        } catch (error) {
          console.error("Error parsing cookie JSON:", error);
          return null;
        }
      }
    }
    return null;
  };

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + JSON.stringify(value) + expires + "; path=/;";
  };

  const [value, setValue] = useState(() => {
    const cookieValue = getCookie(key);
    return cookieValue !== null ? cookieValue : initialValue;
  });

  const updateValue = (newValue) => {
    const currentCookieValue = getCookie(key) || {};
    const updatedValue = { ...currentCookieValue, ...newValue };

    setValue(updatedValue);
    setCookie(key, updatedValue, 7);
  };

  return [value, updateValue];
};
