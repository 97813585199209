// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fakeTable-demo-container {
  overflow-x: auto;
  flex: 1 1;
  height: -webkit-fill-available;
  padding-right: 4px;
  color: var(---demo-font-color);
}

.fakeTable-demo-container table {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-collapse: collapse;
  border-radius: 10px;
}

.fakeTable-demo-container th,
.fakeTable-demo-container td {
  padding: 12px;
  text-align: left;
}

.fakeTable-demo-container th {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-weight: bold;
  border-right: 1px solid #ffffff27;
  
}

.fakeTable-demo-container th:last-child {
  border-right: none;
}

.fakeTable-demo-container td {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border-right: 1px solid #ffffff27;
  border-bottom: 1px solid #ffffff27;
}

.fakeTable-demo-container td:last-child {
  border-right: none;
}

.fakeTable-demo-container tr:last-child td {
  border-bottom: none;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Aspecto/Demo/FakeTable/FakeTable.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,8BAA8B;EAC9B,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,iBAAiB;EACjB,iCAAiC;;AAEnC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,iCAAiC;EACjC,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".fakeTable-demo-container {\r\n  overflow-x: auto;\r\n  flex: 1 1;\r\n  height: -webkit-fill-available;\r\n  padding-right: 4px;\r\n  color: var(---demo-font-color);\r\n}\r\n\r\n.fakeTable-demo-container table {\r\n  width: 100%;\r\n  margin-bottom: 10px;\r\n  margin-top: 10px;\r\n  border-collapse: collapse;\r\n  border-radius: 10px;\r\n}\r\n\r\n.fakeTable-demo-container th,\r\n.fakeTable-demo-container td {\r\n  padding: 12px;\r\n  text-align: left;\r\n}\r\n\r\n.fakeTable-demo-container th {\r\n  background-color: rgba(0, 0, 0, 0.8);\r\n  color: white;\r\n  font-weight: bold;\r\n  border-right: 1px solid #ffffff27;\r\n  \r\n}\r\n\r\n.fakeTable-demo-container th:last-child {\r\n  border-right: none;\r\n}\r\n\r\n.fakeTable-demo-container td {\r\n  background-color: rgba(0, 0, 0, 0.2);\r\n  color: white;\r\n  border-right: 1px solid #ffffff27;\r\n  border-bottom: 1px solid #ffffff27;\r\n}\r\n\r\n.fakeTable-demo-container td:last-child {\r\n  border-right: none;\r\n}\r\n\r\n.fakeTable-demo-container tr:last-child td {\r\n  border-bottom: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
