import React, { useEffect, useRef, useState } from "react";
import MenuList from "./MenuList/MenuList";
import PropTypes from "prop-types";
import { generateStructureMenu, plainStructureMenu } from "./helpers";
import "./Menu.css";
import dragAndDropCanva from "./dragAndDropCanva"
import Button from "src/modules/components/common/Button/Button";
import MenuListBoxEdit from "./MenuListBoxEdit/MenuListBoxEdit";
import MenuCardBoxEdit from "./MenuCardBoxEdit/MenuCardBoxEdit";
import { Dev } from "src/lib/api";
const Menu = ({ setupData, setSetupData }) => {
  const VAR_ADD_MENULIST = "addMenuList";
  const VAR_ADD_MENUCARD = "addMenuCard";
  const VAR_EDIT_MENULIST = "editMenuList";
  const VAR_EDIT_MENUCARD = "editMenuCard";
  const [menuData, setMenuData] = useState(() => generateStructureMenu(setupData.menu));
  const [showModal, setShowModal] = useState(null);
  const [selectEditMenuList, setSelectEditMenuList] = useState({});
  const [selectEditMenuCard, setSelectEditMenuCard] = useState({})
  const initialMenuData = useRef(generateStructureMenu(setupData.menu))

  //Updating SetupData Each time menuData changes
  useEffect(() => {
    const plainedMenu = plainStructureMenu(menuData);
    setSetupData((prevSetupData) => ({
      ...prevSetupData,
      menu: plainedMenu,
    }));
  }, [menuData, setMenuData, setSetupData]);


  const setupDragAndDropCards = async () => {
    try {
      const pos = await dragAndDropCanva();

      const dragIndex = pos.drag.index;
      const dropIndex = pos.drop.index;
      const dropList = pos.drop.list.split(' ').pop()


      const dragList = pos.drag.object.split("-")[0];
      // const dropList = pos.drop.object.split("-")[0];

      const cardId = pos.drag.object.split("-")[1];

      const updatedMenuData = [...menuData];

      const sourceList = updatedMenuData.find((list) => list.id === dragList);
      const targetList = updatedMenuData.find((list) => list.id === dropList);

      const draggedCard = sourceList.submenus.find(
        (submenu) => submenu.id === cardId
      );
      //Update the pathmenu of the dragged card
      draggedCard.pathmenu = targetList.pathmenu + "/" + draggedCard.id;

      sourceList.submenus.splice(dragIndex, 1);

      targetList.submenus.splice(dropIndex, 0, draggedCard);

      setMenuData(updatedMenuData);
    } catch (error) {
      console.error(error);
    }
  };
  const setupDragAndDropLists = async () => {
    try {
      const pos = await dragAndDropCanva();

      const dragIndex = parseInt(pos.drag.index, 10);
      const dropListId = pos.drop.object.split("-")[0];
      const dropListIndex = menuData.findIndex((el) => el.id === dropListId)

      const updatedMenuData = [...menuData];

      const [draggedList] = updatedMenuData.splice(dragIndex, 1);

      updatedMenuData.splice(dropListIndex, 0, draggedList);

      setMenuData(updatedMenuData);
    } catch (error) {
      console.error(error);
    }
  };
  const menuReset = async () => {
    await Dev.fillmenu();
    window.location.reload();
  };
  const handleDeleteMenuList = (data) => {
    setMenuData((prev) => {
      const prevWithoutOneList = prev.filter((el) => data.id !== el.id);
      return prevWithoutOneList;
    });
  };

  const handleAddMenuList = () => {
    setShowModal(VAR_ADD_MENULIST);
  };
  const handleEditMenuList = (data) => {
    setShowModal(VAR_EDIT_MENULIST);
    setSelectEditMenuList(data);
  };

  const handleAddMenuCard = (menuListId) => {
    const menuList = menuData.find(el => el.id === menuListId)
    setSelectEditMenuList(menuList)
    setShowModal(VAR_ADD_MENUCARD)
  }

  const handleEditMenuCard = (menuListId, menuCardIndex) => {
    const findMenu = menuData.find(el => el.id === menuListId)
    const findCard = findMenu.submenus[menuCardIndex]
    setSelectEditMenuList(findMenu)
    setSelectEditMenuCard(findCard)
    setShowModal(VAR_EDIT_MENUCARD)
  }
  const handleDeleteMenuCard = (menuListId, menuCardIndex) => {
    setMenuData((prevMenuData) => {
      const updatedMenuData = [...prevMenuData];
      const list = updatedMenuData.find(menu => menu.id === menuListId);

      if (!list) {
        console.error("Lista no encontrada");
        return prevMenuData;
      }

      if (menuCardIndex < 0 || menuCardIndex >= list.submenus.length) {
        console.error("Índice fuera de rango");
        return prevMenuData;
      }

      list.submenus.splice(menuCardIndex, 1);

      return updatedMenuData;
    });
  }
  const onSaveAddMenuList = (data) => {
    setMenuData((prev) => [...prev, data])
  }
  const onSaveEditMenuList = (data) => {
    const indexMenuList = menuData.findIndex((el) => el.id === selectEditMenuList.id)
    setMenuData((prev) => {
      const newValues = [...prev]
      newValues[indexMenuList] = data
      return newValues
    })
  }

  const onSaveAddMenuCard = (data) => {
    const newSubMenus = [...selectEditMenuList.submenus, data];
    setMenuData((prev) => {
      const index = prev.findIndex((el) => el.id === selectEditMenuList.id);

      if (index === -1) return prev;

      const updatedMenu = {
        ...prev[index],
        submenus: newSubMenus,
      };
      return [
        ...prev.slice(0, index),
        updatedMenu,
        ...prev.slice(index + 1),
      ];
    });
    setSelectEditMenuList({})
  }

  const onSaveEditMenuCard = (datasubmenu, listId) => {
    const menuCopy = [...menuData]
    const menuIndex = menuCopy.findIndex((el) => el.id === listId)
    const menuSelected = menuCopy[menuIndex]
    const submenuIndex = menuSelected.submenus.findIndex((el) => el.id === selectEditMenuCard.id)
    setMenuData((prev) => {
      const copyPrev = [...prev]
      copyPrev[menuIndex].submenus[submenuIndex] = datasubmenu
      return copyPrev
    })
  }

  return (
    <div className="setup-menu__dashboard">
      <div className="setup-menu__buttons">
        <Button content={"Deshacer cambios"} onClick={() => setMenuData(structuredClone(initialMenuData.current))} />
        <Button content={"Actualizar menús"} onClick={menuReset} />
      </div>
      <div className="setup-menu__lists" data-droppable>
        {menuData.map((menu, index) => {
          const menuToIgnore = ["login", "register"];
          if (menuToIgnore.includes(menu.id)) return null;
          return (
            <MenuList
              key={menu.id + index}
              indexMenuList={index}
              data={menu}
              setupDragAndDropCards={setupDragAndDropCards}
              setupDragAndDropLists={setupDragAndDropLists}
              deleteMenuList={handleDeleteMenuList}
              editMenuList={handleEditMenuList}
              addMenuCard={handleAddMenuCard}
              handleEditMenuCard={handleEditMenuCard}
              handleDeleteMenuCard={handleDeleteMenuCard}
            />
          );
        })}
        <div className="menu-list__container">
          <div className="menu-list-add"
            onClick={() => handleAddMenuList()}
          >
            +
          </div>

        </div>
      </div>
      {showModal === VAR_ADD_MENULIST && (
        <MenuListBoxEdit
          setShowModal={setShowModal}
          data={{}}
          setData={onSaveAddMenuList}
        />
      )}
      {showModal === VAR_EDIT_MENULIST && (
        <MenuListBoxEdit
          setShowModal={setShowModal}
          data={selectEditMenuList}
          setData={onSaveEditMenuList}
        />
      )}
      {showModal === VAR_ADD_MENUCARD && (
        <MenuCardBoxEdit
          setShowModal={setShowModal}
          data={{}}
          setData={onSaveAddMenuCard}
          menuListId={selectEditMenuList.id}
        />
      )}
      {showModal === VAR_EDIT_MENUCARD && (
        <MenuCardBoxEdit
          setShowModal={setShowModal}
          data={selectEditMenuCard}
          setData={onSaveEditMenuCard}
          menuListId={selectEditMenuList.id}
        />
      )}
    </div>
  );
};

export default Menu;

Menu.propTypes = {
  setupData: PropTypes.object.isRequired,
  setSetupData: PropTypes.func.isRequired,
};
