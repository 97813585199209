import React from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Input from "src/modules/components/common/Input/Input";
import { saveStructureData } from "src/modules/actions/structureActions";

const StructureMenuChat = ({ editedItems, dispatch }) => {
  const handleCheckBoxChange = (key, checked) => {
    const payload = {
      filetype: {
        header: {
          ...editedItems.filetype.header,
          [key]: checked,
        },
        structure: [
          ...editedItems.filetype.structure,
        ],
      },
    };
    saveStructureData(payload, dispatch);
  };

  return (
    <div className="dossier-dashboard__section-container">
      <h3>Chat</h3>
      <p>Indica si el filetype se relaciona con el chat.</p>
      <div className="structure-header__value">
        <span className="structure-header-key">Activado:</span>
        <Input
          type="checkbox"
          value={editedItems.filetype.header["chat"] || false}
          onChange={(e) => handleCheckBoxChange("chat", e.target.checked)}
          autoFocus
        />
      </div>
    </div>
  );
};

// Validación de props
StructureMenuChat.propTypes = {
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      header: PropTypes.shape({
        chat: PropTypes.bool,
      }),
      structure: PropTypes.object
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default StructureMenuChat;
