import React from 'react'
import PropTypes from "prop-types"
import "./FakeTable.css"
const FakeTable = ({ head, body }) => {
    return (
        <div className='fakeTable-demo-container'>
            <table>
                <thead>
                    <tr>
                        {
                            head.map((el) => (
                                <th key={el.id}>
                                    {el.label}
                                    {/* Aquí podrías añadir el ícono si es necesario */}
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        body.map((row, index) => (
                            <tr key={index}>
                                {head.map((column) => (
                                    <td key={column.id}>{row[column.id]}</td>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default FakeTable

FakeTable.propTypes = {
    head: PropTypes.array.isRequired,
    body: PropTypes.array.isRequired
}