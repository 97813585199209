import React, { createContext, useReducer } from "react";
import PropTypes from 'prop-types';
import { userActivityReducer } from "../reducers/userActivityReducer";

const initialState = {
    currentTime: 0, //ms of the day
    activity: "none",
    duration: 0, // Total clocked time on ms
    lastDateUpdatedDuration: null,// Date()
    activities: [],
    analysis: { nerrors: 0, nflows: 0, ndossiers: 0 }
};

export const UserActivityContext = createContext(initialState);

export const UserActivityContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userActivityReducer, initialState);

    return (
        <UserActivityContext.Provider value={{ state, dispatch }}>
            {children}
        </UserActivityContext.Provider>
    );
};

UserActivityContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
