// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-dashboard-floating {
  position: absolute;
  right: -41px;
  top: 22%;
  transform: rotate(90deg);
  padding: 10px;
  font-weight: bold;
  display: flex;

  span {
    font-weight: bold;
    color: var(--font-color);
    padding: 2px 5px;
    width: max-content;
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.demo-dashboard-floating-error {
  background-color: var(--demo-error-color);
}

.demo-dashboard-floating-warn {
  background-color: var(--demo-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Aspecto/Demo/DashboardFloating/DashboardFloating.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,QAAQ;EACR,wBAAwB;EACxB,aAAa;EACb,iBAAiB;EACjB,aAAa;;EAEb;IACE,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;AACF;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".demo-dashboard-floating {\r\n  position: absolute;\r\n  right: -41px;\r\n  top: 22%;\r\n  transform: rotate(90deg);\r\n  padding: 10px;\r\n  font-weight: bold;\r\n  display: flex;\r\n\r\n  span {\r\n    font-weight: bold;\r\n    color: var(--font-color);\r\n    padding: 2px 5px;\r\n    width: max-content;\r\n    font-size: 12px;\r\n    white-space: nowrap;\r\n    cursor: pointer;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n}\r\n\r\n.demo-dashboard-floating-error {\r\n  background-color: var(--demo-error-color);\r\n}\r\n\r\n.demo-dashboard-floating-warn {\r\n  background-color: var(--demo-warning-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
