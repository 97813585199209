import React from "react";
import Input from "src/modules/components/common/Input/Input";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import "./App.css"
const App = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();

  const handleInputChangeApp = (event) => {
    const { name, value } = event.target;
    setSetupInfo((prevValues) => ({
      ...prevValues,
      app: {
        ...prevValues.app,
        [name]: value,
      },
    }));
  };

  return (
    <div className="tab-container">
      <Input
        name="title"
        type="text"
        placeholder="Valeria"
        defaultValue={setupInfo.app?.title}
        onBlur={handleInputChangeApp}
        label="Titulo de la pestaña:"
        activeLabel={true}
      />
      <Input
        name="shortname"
        type="text"
        placeholder="ValerIA"
        defaultValue={setupInfo.app?.shortname}
        onBlur={handleInputChangeApp}
        label="Nombre corto:"
        activeLabel={true}
      />
      <Input
        name="name"
        type="text"
        placeholder="Tu ayudante con las gestiones."
        defaultValue={setupInfo.app?.name}
        onBlur={handleInputChangeApp}
        label="Nombre completo:"
        activeLabel={true}
      />
      <Input
        name="description"
        type="text"
        placeholder="Sistema asistencia a la direccion de la empresa para el control de calidad, produccion y timming de los procesos de la empresa."
        defaultValue={setupInfo.app?.description}
        onBlur={handleInputChangeApp}
        label="Descripción:"
        activeLabel={true}
      />

      <Input
        name="linklogo"
        type="text"
        placeholder="www.google.es/img/logo.png"
        defaultValue={setupInfo.app?.linklogo}
        onBlur={handleInputChangeApp}
        label="Link del logo:"
        activeLabel={true}
      />
    </div>
  );
};

export default App;
