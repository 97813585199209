/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";


export class Agent {
    static async question(agentid, messages, threadid) {
      const data = {
        agentid: agentid,
        messages: messages,
      }
      if (threadid) data.threadid = threadid
      const response = await HttpMethods.request_post("/v1/agent/question", data);
      if (!response) alert("Error al obtener los checks");
      return response;
    }
    static async list() {
      const response = await HttpMethods.request_get("/v1/agent/list")
      if (!response) alert("Error al obtener los checks");
      return response;
    }
  }
  