// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-activity-dashboard__analysis {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  grid-area: user-activity-dashboard__analysis;
}
.analysis__analytics {
  margin: 10px 0px;
}
.analysis__analytic {
  font-size: 24px;
  display: flex;
  gap: 4px;
  justify-content: center;
}

.analysis__title {
  font-size: 32px;
  justify-content: center;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/routes/UserActivity/Analysis/Analysis.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,wCAAwC;EACxC,4CAA4C;AAC9C;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,aAAa;EACb,QAAQ;EACR,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,aAAa;AACf","sourcesContent":[".user-activity-dashboard__analysis {\r\n  background-color: white;\r\n  border-radius: 8px;\r\n  padding: 16px;\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n  grid-area: user-activity-dashboard__analysis;\r\n}\r\n.analysis__analytics {\r\n  margin: 10px 0px;\r\n}\r\n.analysis__analytic {\r\n  font-size: 24px;\r\n  display: flex;\r\n  gap: 4px;\r\n  justify-content: center;\r\n}\r\n\r\n.analysis__title {\r\n  font-size: 32px;\r\n  justify-content: center;\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
