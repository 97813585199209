// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mermaid-edit-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; /* Asegúrate de que use el 100% del contenedor padre */

  .mermaid-edit-label {
    min-width: 150px;
    max-width: 150px;
    margin-right: 24px;
  }
}

.mermaid-edit-graph-container {
  width: 100%; /* Asegúrate de que use el 100% del contenedor padre */
  position: relative;
  display: flex;
  align-items: center; /* Cambiado a flex-start para evitar que el editor crezca innecesariamente */
  justify-content: space-between;

  .mermaid-graph-container {
    flex-shrink: 0; /* Evita que el contenedor del gráfico se reduzca */
    width: auto; /* Permite que el ancho se ajuste automáticamente según el SVG */
  }
  svg {
    height: 200px; /* Mantén la altura del gráfico */
    width: auto; /* Permite que el ancho se ajuste automáticamente */
  }

}

`, "",{"version":3,"sources":["webpack://./src/modules/components/common/MermaidEditor/MermaidEditor.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,WAAW,EAAE,sDAAsD;;EAEnE;IACE,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;EACpB;AACF;;AAEA;EACE,WAAW,EAAE,sDAAsD;EACnE,kBAAkB;EAClB,aAAa;EACb,mBAAmB,EAAE,4EAA4E;EACjG,8BAA8B;;EAE9B;IACE,cAAc,EAAE,mDAAmD;IACnE,WAAW,EAAE,gEAAgE;EAC/E;EACA;IACE,aAAa,EAAE,iCAAiC;IAChD,WAAW,EAAE,mDAAmD;EAClE;;AAEF","sourcesContent":[".mermaid-edit-container {\r\n  position: relative;\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%; /* Asegúrate de que use el 100% del contenedor padre */\r\n\r\n  .mermaid-edit-label {\r\n    min-width: 150px;\r\n    max-width: 150px;\r\n    margin-right: 24px;\r\n  }\r\n}\r\n\r\n.mermaid-edit-graph-container {\r\n  width: 100%; /* Asegúrate de que use el 100% del contenedor padre */\r\n  position: relative;\r\n  display: flex;\r\n  align-items: center; /* Cambiado a flex-start para evitar que el editor crezca innecesariamente */\r\n  justify-content: space-between;\r\n\r\n  .mermaid-graph-container {\r\n    flex-shrink: 0; /* Evita que el contenedor del gráfico se reduzca */\r\n    width: auto; /* Permite que el ancho se ajuste automáticamente según el SVG */\r\n  }\r\n  svg {\r\n    height: 200px; /* Mantén la altura del gráfico */\r\n    width: auto; /* Permite que el ancho se ajuste automáticamente */\r\n  }\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
