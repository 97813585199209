// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo {
  height: 100%;
  box-shadow: 10px 10px 31px 0px rgba(0,0,0,0.75);
  padding:10px;
  padding-right: 20px;
  background-color: var(--demo-bg-color);
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Aspecto/Demo/Demo.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EAGZ,+CAA+C;EAC/C,YAAY;EACZ,mBAAmB;EACnB,sCAAsC;EACtC,kBAAkB;AACpB","sourcesContent":[".demo {\r\n  height: 100%;\r\n  -webkit-box-shadow: 10px 10px 31px 0px rgba(0,0,0,0.75);\r\n  -moz-box-shadow: 10px 10px 31px 0px rgba(0,0,0,0.75);\r\n  box-shadow: 10px 10px 31px 0px rgba(0,0,0,0.75);\r\n  padding:10px;\r\n  padding-right: 20px;\r\n  background-color: var(--demo-bg-color);\r\n  position: relative;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
