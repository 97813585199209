const viewActionTypes = {
    FETCH_VIEW_REQUEST: "FETCH_VIEW_REQUEST",
    FETCH_VIEW_SUCCESS: "FETCH_VIEW_SUCCESS",
    FETCH_VIEW_FAILURE: "FETCH_VIEW_FAILURE",
    FETCH_DATA_REQUEST: "FETCH_DATA_REQUEST",
    FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
    FETCH_DATA_FAILURE: "FETCH_DATA_FAILURE",
    FETCH_SHOW_REQUEST: "FETCH_SHOW_REQUEST",
    FETCH_SHOW_SUCCESS: "FETCH_SHOW_SUCCESS",
    FETCH_SHOW_FAILURE: "FETCH_SHOW_FAILURE",
    UPDATE_VIEW_REQUEST: "UPDATE_VIEW_REQUEST",
    UPDATE_VIEW_SUCCESS: "UPDATE_VIEW_SUCCESS",
    UPDATE_VIEW_FAILURE: "UPDATE_VIEW_FAILURE",
    SET_SELECTED_FRAME: "SET_SELECTED_FRAME",
    FETCH_PATCH_REQUEST: "FETCH_PATCH_REQUEST",
    FETCH_PATCH_SUCCESS: "FETCH_PATCH_SUCCESS",
    FETCH_PATCH_FAILURE: "FETCH_PATCH_FAILURE",
    SET_SELECTED_TASK: "SET_SELECTED_TASK",
};

export default viewActionTypes;