import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Filetype, Setup, View } from "src/lib/api";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const CreateNewMenuByFiletype = () => {
  const { filetype } = useParams();
  const { refreshSetup } = useAppContext();

  useEffect(() => {
    const fetchSetup = async () => {
      const setup = await Setup.read();
      const newSetup = structuredClone(setup);
      const dossierListView = {
        name: `Lista de dossiers tipo ${filetype}`,
        viewcode: `dlist-${filetype}`,
        active: true,
        frames: [
          {
            type: "list",
            scope: "dossier",
            filter: `{"header.filetype" : "${filetype}"}`,
            filetype,
            elements: [
              {
                link: `/view/dshow-${filetype}`,
                label: "Añadir",
                type: "button",                
                icon:"add_circle"
              },
              {
                variable: "{{header.name}}",
                link: `/view/dshow-${filetype}?dc={{header.code}}`,
                label: "Nombre del expediente",
              },
              {
                variable: "{{header.description}}",
                label: "description",
              },
              {
                variable: "{{header.status}}",
                label: "status",
                options: '{"filter":true}',
              },
              {
                variable: "{{header.owner}}",
                label: "owner",
                options: '{"filter":true}',
              },
              {
                label: "Plantilla",
                icon: "summarize",
                link: `/view/dtemplate?dc={{header.code}}`,
              },
              
            ],
          },
        ],
      };
      const {view: dossierShowView} = await Filetype.view('show', filetype)
      if(!dossierShowView.name) dossierShowView.name = `Show de dossiers tipo ${filetype}`
      await View.crupdate(dossierListView);
      await View.crupdate(dossierShowView);
      const workPath = newSetup.menu.filter(e => e.pathmenu=== '/work')
      if(workPath.length === 0) {
        const workMenu = {
          id: "view",
          title: "Trabajo",
          pathmenu: "/work",
          roles: [
            "superadmin",
            "robot",
            "hyperuser",
            "admin",
            "user",
            "developer",
          ],
          url: "/view/dlist",
          active: true,
        }
        newSetup.menu.push(workMenu);
      }
      const newMenu = {
        id: filetype,
        title: filetype,
        pathmenu: `/work/${filetype}`,
        roles: [
          "superadmin",
          "admin",
          "user",
          "robot",
          "developer",
          "hyperuser",
        ],
        url: `/view/dlist-${filetype}`,
        active: true,
      };
      newSetup.menu.push(newMenu);
      
      await Setup.write(newSetup);
      refreshSetup()
      window.open(newMenu.url,"_self");
    };
    fetchSetup();
  }, []);

  return <h1>Creando nuevo menú para el filetype: {filetype}...</h1>;
};

export default CreateNewMenuByFiletype;
