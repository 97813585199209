import React, { useState } from "react";
import PropTypes from "prop-types";
import { View } from "src/lib/api.js";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Icon } from "@mui/material";
import DropDownBox from "../common/DropDownBox/DropDownBox";
import LazyLoad from "src/routes/Structure/LazyLoad/LazyLoad";
import constants from "src/definitions/constants";

export function EditElement({
  element,
  setView,
  view,
  frame,
  align = "left",
  ogDatacode,
  config = { duplicateElement: false }
}) {
  const { role, publicSetup } = useAppContext();
  const [showDropdown, setShowDropdown] = useState();

  const updateView = async () => {
    if (Object.keys(view).length === 0) return;
    await View.update(view);
    setView(view);
  };

  function updateElements(elements, element, name, value) {
    return elements.map((el) => {
      if (
        el._id
          ? el._id === element._id
          : el.variable === element.variable && el.type === element.type
      ) {
        el[name] = value;
      }

      if (Array.isArray(el.elements)) {
        el.elements = updateElements(el.elements, element, name, value);
      }

      return el;
    });
  }

  function removeElement(elements, element) {
    return elements.reduce((acc, el) => {
      // Si el elemento actual tiene sub-elementos, procesarlos recursivamente
      if (Array.isArray(el.elements)) {
        el = { ...el, elements: removeElement(el.elements, element) };
      }

      // Solo agregar el elemento a la lista acumulada si no coincide con el elemento a eliminar
      if (!(el.variable === element.variable && el.type === element.type)) {
        acc.push(el);
      }

      return acc;
    }, []);
  }

  const duplicateElement = (element) => {
    const newElement = structuredClone(element);
    newElement.variable = "Nuevo Elemento";
    newElement.label = "Nuevo Elemento";
    newElement.link = "";
    newElement.icon = "";
    delete newElement._id;
    const newFrame = { ...frame, elements: [...frame.elements, newElement] };
    const frames = view.frames.map((fr) => {
      if (fr._id === frame._id) return newFrame;
      return fr;
    });
    const newView = { ...view, frames };
    view = newView;
    updateView();
    setShowDropdown(false);
  };

  const deleteElement = (element) => {
    const newElements = removeElement(frame.elements, element);
    const newFrame = { ...frame, elements: newElements };
    const frames = view.frames.map((fr) => {
      if (fr._id === frame._id) return newFrame;
      return fr;
    });
    const newView = { ...view, frames };
    view = newView;
    updateView();
    setShowDropdown(false);
  };

  const editableOptions = [
    { label: "Solo Lectura", value: "readonly" },
    { label: "Escritura", value: "write" },
    { label: "Protegido", value: "protected" },
    { label: "Borrar", value: "erase" }
  ];

  const requiredOptions = [
    { label: "Obligatorio", value: "data" },
    { label: "No necesario", value: "no" },
    { label: "Sin datos", value: "empty" }
  ];

  const onChangeInput = (e, prop) => {
    let name = "";
    let value = "";
    if (!e.target) {
      name = prop;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    }
    const newElements = updateElements(frame.elements, element, name, value);
    const newFrame = { ...frame, datacode: ogDatacode, elements: newElements };
    const frames = view.frames.map((fr) => {
      if (fr._id === frame._id) return newFrame;
      return fr;
    });
    const newView = { ...view, frames };
    view = newView;
  };

  return (
    <LazyLoad>
      {publicSetup.developmode && (role === "developer" || role === "superadmin") && (
        <div className="editColumnsModal" title="Haga click para editar la vista.">
          <Icon
            style={{
              color: "orange",
              border: "2px solid blue",
              borderRadius: "50%",
              scale: "70%",
              cursor: "pointer"
            }}
            onClick={(e) => {
              setShowDropdown(true);
            }}
          >
            edit
          </Icon>
          {showDropdown && (
            <DropDownBox
              title="Editor de elemento"
              setOpen={setShowDropdown}
              onClosed={updateView}
              style={{ right: align === "right" ? 0 : "unset" }}
            >
              <div className="css-175oi2r">
                <div className="edit-viewcode-title-container">
                  <div className="edit-viewcode-buttons">
                    <div className="edit-viewcode-button-delete">
                      <Icon
                        title="Haz click para eliminar el elemento"
                        onClick={() => {
                          deleteElement(element);
                        }}
                      >
                        delete
                      </Icon>
                    </div>
                    {config.duplicateElement && (
                      <div className="edit-viewcode-button-delete">
                        <Icon
                          title="Haz click para duplicar el elemento"
                          onClick={() => {
                            duplicateElement(element);
                          }}
                        >
                          content_copy
                        </Icon>
                      </div>
                    )}
                  </div>
                </div>
                <>
                  <Input
                    label={"Variable"}
                    name={"variable"}
                    onBlur={(e) => onChangeInput(e)}
                    placeholder="file.name"
                    defaultValue={element.variable}
                  />
                  <Input
                    label={"Etiqueta"}
                    name={"label"}
                    onBlur={(e) => onChangeInput(e)}
                    placeholder="Nombre"
                    defaultValue={element.label}
                  />
                  <Input
                    label={"Link"}
                    name={"link"}
                    onBlur={(e) => onChangeInput(e)}
                    placeholder="http://valeria.grupohereda.com"
                    defaultValue={element.link}
                  />
                  <Select
                    onChange={(e) => onChangeInput(e, "type")}
                    options={constants.dataTypeOptions}
                    label={"Tipo"}
                    name={"type"}
                    value={constants.dataTypeOptions.find(
                      (opt) => element.type === opt.value
                    )}
                  />
                  <Select
                    onChange={(e) => onChangeInput(e, "editable")}
                    options={editableOptions}
                    label={"Editable"}
                    name={"editable"}
                    value={editableOptions.find(
                      (opt) => element.editable === opt.value
                    )}
                  />{" "}
                  <Select
                    onChange={(e) => onChangeInput(e, "required")}
                    options={requiredOptions}
                    label={"Obligatorio"}
                    name={"required"}
                    value={requiredOptions.find(
                      (opt) => element.required === opt.value
                    )}
                  />
                  <Input
                    label={"Icono"}
                    name={"icon"}
                    onBlur={(e) => onChangeInput(e)}
                    placeholder="add_circle..."
                    defaultValue={element.icon}
                  />
                  <Input
                    label={"Align"}
                    name={"align"}
                    onBlur={(e) => onChangeInput(e)}
                    placeholder="right, left..."
                    defaultValue={element.align}
                  />
                  <Input
                    label={"Format"}
                    name={"format"}
                    onBlur={(e) => onChangeInput(e)}
                    placeholder="format..."
                    defaultValue={element.format}
                  />
                  <Input
                    label={"Opciones"}
                    name={"options"}
                    onBlur={(e) => onChangeInput(e)}
                    placeholder="{filter: true}..."
                    defaultValue={element.options}
                  />
                  {element.type === "enum" && (
                    <Input
                      label={"Lista"}
                      name={"enum"}
                      onBlur={(e) => onChangeInput(e)}
                      placeholder="[{value: 'spain', label: 'España'}...]"
                      defaultValue={JSON.stringify(element.enum)}
                    />
                  )}
                  {(element.type === "array" || element.type === "object") && (
                    <Input
                      label={"Abierto por defecto"}
                      type="checkbox"
                      name={"isOpen"}
                      onBlur={(e) => onChangeInput(e)}
                      defaultValue={element.isOpen}
                    />
                  )}
                </>
              </div>
            </DropDownBox>
          )}
        </div>
      )}
    </LazyLoad>
  );
}

EditElement.propTypes = {
  element: PropTypes.shape({
    _id: PropTypes.string,
    variable: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
    align: PropTypes.string,
    format: PropTypes.string,
    options: PropTypes.string,
    enum: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),
    isOpen: PropTypes.bool,
    editable: PropTypes.string,
    required: PropTypes.string
  }).isRequired,
  setView: PropTypes.func.isRequired,
  view: PropTypes.shape({
    frames: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        elements: PropTypes.array
      })
    )
  }).isRequired,
  frame: PropTypes.shape({
    _id: PropTypes.string,
    elements: PropTypes.array
  }).isRequired,
  align: PropTypes.string,
  ogDatacode: PropTypes.string,
  config: PropTypes.shape({
    duplicateElement: PropTypes.bool
  })
};

export default EditElement;
