import React, { useEffect, useState } from "react";
import { Global, Info as InfoAPI } from "src/lib/api";
import "./Export.css";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import { API_URL } from "../../config.js";
import LoadingSpinner  from "src/modules/components/common/LoadingSpinner/LoadingSpinner";
import { useAppContext } from 'src/modules/contexts/AppContextProvider';

const Export2 = () => {
  const { token } = useAppContext();
  const [checkedItems, setCheckedItems] = useState();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const info = await InfoAPI.basics();
      setData(info.info);
      initializeCheckedItems(info.info);
    };
    fetchData();
  }, []);

  const initializeCheckedItems = (data) => {
    const newCheckedItems = {
      all: false,
      setup: false,
      users: false,
      dossiers: false,
      dossiersfiletypes:
        data.dossiers?.filetypes.map((filetype) => ({
          filetypename: filetype.name || "null",
          export: false,
        })) || [],
      flows: false,
      movements: false,
      templates: false,
      cartridges: false,
      views: false,
      checks: false,
      docs: false,
      events: false,
      resumes: false,
      measures: false,
      content: false,
      structures: false,
      filetypesstructures:
        data.structures?.filetypes.map((filetype) => ({
          filetypename: filetype.name || "null",
          export: false,
        })) || [],
      logs: false,
    };
    setCheckedItems(newCheckedItems);
  };

  function checkProperties(obj) {
    // Función auxiliar para verificar un objeto
    function hasTrueProperty(o) {
      for (const key in o) {
        if (Object.prototype.hasOwnProperty.call(o, key)) {
          if (o[key] === true) {
            return true;
          }
          if (Array.isArray(o[key])) {
            for (const item of o[key]) {
              if (item.export === true) {
                return true;
              }
            }
          }
        }
      }
      return false;
    }
    
    // Verificar el objeto principal
    return hasTrueProperty(obj);
}
  const handleCheckChange = (key, isChecked) => {
    setCheckedItems((prevState) => {
      const updatedState = { ...prevState, [key]: isChecked };

      if (key === "dossiers") {
        updatedState["dossiersfiletypes"] = prevState["dossiersfiletypes"].map(
          (filetype) => ({
            ...filetype,
            export: isChecked,
          })
        );
      } else if (key === "structures") {
        updatedState["filetypesstructures"] = prevState[
          "filetypesstructures"
        ].map((filetype) => ({
          ...filetype,
          export: isChecked,
        }));
      }

      return updatedState;
    });
  };

  const handleFileTypeCheckChange = (parentKey, index, isChecked) => {
    setCheckedItems((prevState) => {
      const updatedState = { ...prevState };

      if (parentKey === "dossiersfiletypes") {
        updatedState[parentKey][index].export = isChecked;
        updatedState.dossiers = updatedState[parentKey].every(
          (filetype) => filetype.export
        );
      } else if (parentKey === "filetypesstructures") {
        updatedState[parentKey][index].export = isChecked;
        updatedState.structures = updatedState[parentKey].every(
          (filetype) => filetype.export
        );
      }

      return updatedState;
    });
  };

  const renderCheckboxes = (data, parentKey = "") => {
    return Object.keys(data).map((key) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const value = data[key];

      if (Array.isArray(value)) {
        return null;
      } else if (typeof value === "object" && value !== null) {
        return (
          <div key={currentKey} style={{ marginLeft: 20 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              <Input
                type="checkbox"
                name={currentKey}
                styleInputContainer={{ flex: "none" }}
                checked={checkedItems[currentKey] || false}
                onChange={(e) =>
                  handleCheckChange(currentKey, e.target.checked)
                }
              />
              <b>{key}</b>
            </div>
            {key === "dossiers" && value.filetypes && (
              <div style={{ marginLeft: 20 }}>
                {value.filetypes.map((filetype, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginBottom: "10px",
                      marginLeft: 20,
                    }}
                  >
                    <Input
                      type="checkbox"
                      name={`dossier-${filetype.name}`}
                      styleInputContainer={{ flex: "none" }}
                      checked={checkedItems.dossiersfiletypes[index].export}
                      onChange={(e) =>
                        handleFileTypeCheckChange(
                          "dossiersfiletypes",
                          index,
                          e.target.checked
                        )
                      }
                    />
                    <i>{filetype.name || "null"}</i>
                  </div>
                ))}
              </div>
            )}
            {key === "structures" && value.filetypes && (
              <div
                style={{
                  marginLeft: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {value.filetypes.map((filetype, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginBottom: "10px",
                      marginLeft: 20,
                    }}
                  >
                    <Input
                      type="checkbox"
                      name={`structure-${filetype.name}`}
                      styleInputContainer={{ flex: "none" }}
                      checked={checkedItems.filetypesstructures[index].export}
                      onChange={(e) =>
                        handleFileTypeCheckChange(
                          "filetypesstructures",
                          index,
                          e.target.checked
                        )
                      }
                    />
                    <i>{filetype.name || "null"}</i>
                  </div>
                ))}
              </div>
            )}
            <div style={{ marginLeft: 20 }}>
              {renderCheckboxes(value, currentKey)}
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    const updatedCheckedItems = { ...checkedItems };

    Object.keys(data).forEach((key) => {
      const currentKey = key;
      updatedCheckedItems[currentKey] = isChecked;

      if (data[key]?.filetypes) {
        data[key].filetypes.forEach((_, index) => {
          updatedCheckedItems[`dossiersfiletypes.${index}`] = isChecked;
        });
      } else if (data[key]?.structures) {
        data[key].structures.forEach((_, index) => {
          updatedCheckedItems[`filetypesstructures.${index}`] = isChecked;
        });
      }
    });

    setCheckedItems(updatedCheckedItems);
  };

  const isSelectAllChecked = () => {
    return Object.keys(checkedItems).every((key) => {
      if (Array.isArray(checkedItems[key])) {
        return checkedItems[key].every((filetype) => filetype.export);
      }
      return checkedItems[key];
    });
  };

  const onClickExport = async (e) => {
    setIsLoading(true);
    const result = await Global.export(checkedItems);
    if (result.error) return;
    setIsLoading(false);
  };
  if (!data) return <h1>Cargando datos...</h1>;
  return (
    <FormContainer style={{ margin: "20px", padding: "20px", rowGap: "5px" }}>
      <form
        onSubmit={onClickExport}
        method="POST"
        encType="application/json"
        action={`${API_URL}/v1/global/export?TOKEN=${token.split(' ')[1]}`}
      >
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <Input
            type="checkbox"
            label={<b>Seleccionar todos los elementos</b>}
            styleInputContainer={{
              flex: "none",
              flexDirection: "row-reverse",
              gap: "10px",
              justifyContent: "flex-end",
            }}
            onChange={handleSelectAllChange}
            checked={isSelectAllChecked()}
            defaultChecked={false}
          />
          <Button
            type="submit"
            content={
              isLoading ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LoadingSpinner /> Exportando
                </div>
              ) : (
                "Exportar"
              )
            }
            className={"navbutton-save"}
            disabled={checkProperties(checkedItems) && !isLoading  ? "" : "disabled"}
            
          />

        </div>
        {renderCheckboxes(data)}
      </form>
    </FormContainer>
  );
};

export default Export2;