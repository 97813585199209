/**
 * @param {Array} data Must be an array of objects. 
 * @returns {Array} An array of objects with the structure of the menu and submenus.
 */
export const generateStructureMenu = (data) => {
    const dataWithUniqueIds = verifyUniqueIds(data)
    // let usedSubmenus = []
    const principal = dataWithUniqueIds.filter((item) => item.pathmenu.split("/").length === 2)
    const submenus = dataWithUniqueIds.filter((item) => item.pathmenu.split("/").length > 2)
    //Falta contemplar cuando existen submenus pero no el menu principal 
    principal.forEach((item) => {
        const submenusToAd = submenus.filter((submenu) => submenu.pathmenu.includes(item.pathmenu))
        item.submenus = submenusToAd
        // usedSubmenus = usedSubmenus.concat(submenusToAd)
    })
    // const orphanSubmenus = submenus.filter((submenu) => !usedSubmenus.includes(submenu));
    // if (orphanSubmenus.length > 0 && principal.length > 0) {
    //     const firstMenuPath = principal[0].pathmenu;

    //     orphanSubmenus.forEach((orphan) => {
    //         orphan.pathmenu = `${firstMenuPath}/${orphan.pathmenu.split("/").pop()}`;
    //     });

    //     principal[0].submenus = principal[0].submenus.concat(orphanSubmenus);
    // }
    return principal
}

export const plainStructureMenu = (data) => {
    const plainedMenu = []
    for (let menu of data) {
        const copyMenu = { ...menu }
        delete copyMenu.submenus
        plainedMenu.push(copyMenu)
        for (let submenu of menu.submenus) {
            plainedMenu.push(submenu)
        }
    }
    const plainedMenuWithUniqueIds = verifyUniqueIds(plainedMenu)
    return plainedMenuWithUniqueIds
}

const verifyUniqueIds = (data) => {
    const existingIds = new Set();
    const generateId = () => {
        let id;
        do {
            id = generateUniqueId()
        } while (existingIds.has(id));
        return id;
    };

    return data.map(item => {
        if (!item.id || existingIds.has(item.id)) {
            const newId = generateId();
            existingIds.add(newId);
            return { ...item, id: newId, pathmenu: `/${newId}` };
        }
        existingIds.add(item.id);
        return item;
    });
};

export const generateUniqueId = () => {
    return Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2, 9);
}