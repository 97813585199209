import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@mui/material'
/**
 * 
 * @param {Array} action  
 * @returns 
 */
const ActionBubbles = ({ action }) => {
    const actionEquivalence = {
        start: 'login',
        pause: 'pause',
        continue: 'play_arrow',
        stop: 'logout',
    }
    return (
        <Icon >{actionEquivalence[action]}</Icon>
    )
}

export default ActionBubbles

ActionBubbles.propTypes = {
    action: PropTypes.string.isRequired
}