import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/modules/contexts/AppContextProvider';

const Logout = () => {
  const navigate = useNavigate();
  const { logout,setControlUserActivity } = useAppContext()

  useEffect(() => {
    // Esperar un breve tiempo antes de redirigir para dar la sensación de transición suave
    const timer = setTimeout(() => {
      setControlUserActivity("false")
      sessionStorage.clear();
      logout()
      navigate("/login", { replace: true });
    }, 300);

    return () => clearTimeout(timer);
  }, [navigate, logout]);

  return null;
};

export default Logout;