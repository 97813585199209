import { Icon } from '@mui/material';
import React, { memo } from 'react';
import PropTypes from 'prop-types'; // Añadido para la validación de props
import { Handle, Position } from '@xyflow/react';

// Definición del componente con nombre para evitar errores de eslint
const PlannerNode = ({ data, isConnectable }) => {
  const iconByType = {
    createdoc: 'edit_document',
    document: "description",
    action: "precision_manufacturing",
    event: "calendar_month",
    variable: "code",
    ai: "psychology",
  };

  return (
    <>
      <div className='node-label'>
        <div className='node-icon'>
          <Icon>{iconByType[data.type]}</Icon>
        </div>
        <p className='node-label-text'>
          {data.label}
        </p>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        id='left'
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        id='right'
      />
    </>
  );
};

// Añadido para la validación de props
PlannerNode.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf(["createdoc",'document', 'action', 'event', 'variable', 'ai']).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
};

export default memo(PlannerNode);
