//Logica
import userActivityActionTypes from "../actionTypes/userActivityActionTypes";
import { Activity as ActivityAPI, User as UserAPI } from 'src/lib/api';


export function milisecondsToHHMM(ms) {
    if (ms === 0) return "00:00"
    const hours = Math.floor(ms / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    return `${hours}:${minutes}`
}
export function milisecondsToHHHMMM(ms) {
    if (ms === 0) return "00h 00min"
    const hours = Math.floor(ms / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    return `${hours}h ${minutes}min`
}

function calculateWorkingTime(activities) {
    //Ordeno de menor a mayor
    const activitiesOrdenredLast = [...activities].sort(
        (a, b) => new Date(a.time) - new Date(b.time)
    );
    let firstTimeOfDay = null;
    let actualDay = null;
    let workingTime = 0;
    for (let x = 0; x < activitiesOrdenredLast.length; x++) {
        const activity = activitiesOrdenredLast[x]
        let lastActivity = null
        const activityTime = new Date(activity.time).getTime() / 1000; //seconds
        const activityDay = activity.date;
        if (firstTimeOfDay === null && actualDay === null) {
            firstTimeOfDay = activityTime;
            actualDay = activityDay;
            activity['workingTime'] = 0;
            continue;
        }
        lastActivity = activitiesOrdenredLast[x - 1]
        if (actualDay === activityDay) {
            if (
                activity.action === 'pause' ||
                (activity.action === 'stop' && lastActivity.action !== 'pause')
            ) {
                workingTime += (activityTime - (new Date(lastActivity.time).getTime() / 1000))
            }
            activity['workingTime'] = workingTime;
        }
    }
    const activitiesOrdenredRecent = [...activitiesOrdenredLast].sort(
        (a, b) => new Date(b.time) - new Date(a.time)
    );
    return activitiesOrdenredRecent;
}

export const setCurrentTime = (dispatch) => {
    const today = new Date()
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    const currentTime = hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
    dispatch({
        type: userActivityActionTypes.SET_CURRENT_TIME,
        payload: currentTime
    })

}
export const fetchActivity = async (dispatch) => {
    const result = await ActivityAPI.read()
    let activities = result.actions
    const lastActionObject = result.actions?.at(-1)
    if (!lastActionObject) return
    const lastAction = lastActionObject.action
    const duration = result.duration //seconds

    //Order by time most recent
    activities.sort((a, b) => new Date(b.time) - new Date(a.time))
    dispatch({
        type: userActivityActionTypes.FETCH_LAST_ACTION,
        payload: lastAction
    })
    //TODO:The duration must be updated as soon as any button is clicked 
    dispatch({
        type: userActivityActionTypes.SET_DURATION,
        payload: duration * 1000 //miliseconds
    })
    activities.sort((a, b) => new Date(b) - new Date(a))
    activities.forEach((activity) => {
        activity.date = activity.time.split("T")[0]
    })
    activities = calculateWorkingTime(activities)
    dispatch({
        type: userActivityActionTypes.SET_ACTIVITIES,
        payload: activities
    })
}
export const addMilisecondsToDuration = (dispatch, durationMiliseconds, miliseconds) => {
    const newDuration = durationMiliseconds + miliseconds
    dispatch({
        type: userActivityActionTypes.SET_DURATION,
        payload: newDuration
    })
}
export const fetchAction = async (action, dispatch) => {
    try {
        await ActivityAPI.action(action)
        dispatch({
            type: userActivityActionTypes.FETCH_LAST_ACTION,
            payload: action
        })
    } catch (error) {
        dispatch({
            type: userActivityActionTypes.FETCH_ACTION_ERROR,
            payload: error
        })
    }

}
export const fetchAnalysis = async (dispatch, email) => {
    try {
        const resultAnalysis = await UserAPI.analysis(email)
        const { nerrors, nflows, ndossiers } = resultAnalysis

        dispatch({
            type: userActivityActionTypes.SET_ANALYSIS,
            payload: { nerrors, nflows, ndossiers }
        })
    } catch (error) {
        dispatch({
            type: userActivityActionTypes.FETCH_ANALYSIS_ERROR,
            payload: error
        })
    }
}