// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-container__status-bubbles {
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
  grid-area: time-container__status-bubbles;
}

.status-bubble {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.status-bubble--blue {
  background-color: #cce5ff;
}

.status-bubble--yellow {
  background-color: #fff3cd;
}

.status-bubble--orange {
  background-color: #ffeeba;
}

.status-bubble--red {
  background-color: #f8d7da;
}
`, "",{"version":3,"sources":["webpack://./src/routes/UserActivity/StatusBubbles/StatusBubbles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,QAAQ;EACR,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".time-container__status-bubbles {\r\n  display: flex;\r\n  justify-content: space-evenly;\r\n  gap: 8px;\r\n  grid-area: time-container__status-bubbles;\r\n}\r\n\r\n.status-bubble {\r\n  width: 60px;\r\n  height: 60px;\r\n  border-radius: 50%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-weight: bold;\r\n}\r\n\r\n.status-bubble--blue {\r\n  background-color: #cce5ff;\r\n}\r\n\r\n.status-bubble--yellow {\r\n  background-color: #fff3cd;\r\n}\r\n\r\n.status-bubble--orange {\r\n  background-color: #ffeeba;\r\n}\r\n\r\n.status-bubble--red {\r\n  background-color: #f8d7da;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
