import React, { useEffect, useState } from "react";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Test from "src/routes/Checks/Test/Test";
import { Cartridge, Check as CheckAPI, Filetype, Dossier } from "src/lib/api";
import Title from "src/modules/components/common/Title/Title";
import PropTypes from "prop-types";
import "../Checks.css";
import useFormatters from "src/modules/hooks/useFormatters";
import { Editor } from "@monaco-editor/react";
import Select from "src/modules/components/common/Select/Select";
import { useLocation } from "react-router";

const TestCheck = ({ check }) => {
  const inputsHeight = "35px";

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [filetype, setFiletype] = useState(queryParams.get("filetype"))
  const [datacode, setDatacode] = useState(queryParams.get("datacode"))

  const [cartridge, setCartridge] = useState({test: {}});
  const [result, setResult] = useState({});
  const [passedTests, setPassedTests] = useState([]);
  const [notPassedTests, setNotPassedTests] = useState([]);
  const [usedVariables, setUsedVariables] = useState([]);
  const [testLogic, setTestLogic] = useState(check.logic);
  const [filetypeOptions, setFiletypeOptions] = useState([]);
  const [structure, setStructure] = useState();

  const formatter = useFormatters();
  const testCheck = async () => {
    if (!datacode) return alert("Introduzca un scopeCode");
    saveCartridge();
    const testCheck = { ...check, logic: testLogic };
    const responseData = await CheckAPI.test(datacode, testCheck);
    setResult(responseData.result);
    // Actualizar la segunda columna con los datos recibidos
    setPassedTests(
      responseData.result.tests.filter((test) => test.result === true)
    );
    setNotPassedTests(
      responseData.result.tests.filter((test) => {
        if (test.result === false || test.error) {
          return test;
        }
        return null;
      })
    );
    setUsedVariables(responseData.result.vars.map((variable) => variable.name));
  };

  useEffect(() => {
    const fetch = async () => {
      const result = await Filetype.list();
      const filetypes = result.map((filetype) => ({
        value: filetype.header.name,
        label: filetype.header.name,
      }));
      setFiletypeOptions(filetypes);
      fetchStructure()
    };
    setResult({});
    setPassedTests([]);
    setNotPassedTests([]);
    setUsedVariables([]);
    setStructure([]);
    fetch();
  }, []);

  useEffect(() => {
    const fetchCartridge = async () => {
      const cartridgecode = check.cartridgecode;
      if (!cartridgecode) {
        return;
      }
      const cartridge = await Cartridge.read(cartridgecode);
      setCartridge(cartridge);
      if(!datacode) setDatacode(cartridge.test?.datacode);
      if(!filetype) setFiletype(cartridge.test?.filetype)
    };
    fetchCartridge();
  }, [check.cartridgecode]);

  const fetchStructure = async () => {
    if (cartridge.test?.datacode === datacode || !datacode) return;
    Dossier.structure(datacode)
      .then((structure) => {
        if(structure.error){ 
          setStructure([]);
          return
        }
        const structureOptions = Object.entries(structure).map(
          (structure) => {
            return {
              value: `{{${structure[0]}}}`,
              label: `{{${structure[0]}}}: ${structure[1]}`,
            };
          }
        );
        setStructure(structureOptions);
      }).catch((err) => console.error(err));
  };

  const saveCartridge = async () => {
    if (cartridge.test?.datacode === datacode && cartridge.test?.filetype === filetype) return;
    const cartridgeToUpload = {...cartridge,  test: {filetype, datacode}}
    await Cartridge.crupdate(cartridgeToUpload);
    setCartridge(cartridgeToUpload)
  };
  const onChangeMonaco = (value) => {
    setTestLogic(value);
  };

  const updateCartridgeTest = (name, data) => {
    if(name === 'filetype') setFiletype(data);
    else setDatacode(data);
  };

  useEffect(() => {
    setTestLogic(check.logic);
  }, [check]);

  return (
    <div className="group-container">
      <div>
          <Title text="Test" />
        <div style={{background: 'rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '10px'}}>
          <div
            className="actions-buttons"
            style={{ marginBottom: "5px", marginTop: 0 }}>
            <h4 style={{ margin: 0 }}>Filetype</h4>
            <Select
              isSearchable={true}
              isClearable={true}
              name="selectActionType"
              options={filetypeOptions}
              placeholder="expedientes"
              onChange={(data) =>
                updateCartridgeTest("filetype", data ? data.value : null)
              }
              value={filetypeOptions.find(
                (opt) => opt.value === filetype
              )}
              height={inputsHeight}
            />
            <h4 style={{ margin: 0 }}>Código</h4>

            <Input
              name="scopecode"
              type="text"
              placeholder="ScopeCode"
              value={datacode}
              onChange={(e) => updateCartridgeTest("datacode", e.target.value)}
              onBlur={fetchStructure}
              activeLabel={true}
              style={{ height: "31px" }}
            />
          </div>
          <div
            className="actions-buttons"
            style={{ marginBottom: "5px", marginTop: 0 }}>
            <h4 style={{ margin: 0 }}>Variables</h4>
            <Select
              isSearchable={true}
              isClearable={true}
              isMulti={true}
              name="structure"
              options={structure}
              placeholder="{{dossier.file.name}}"
              onChange={(data) => {
                if (data.length === 0) return;
                setTestLogic((prev) => {
                  return `${prev} ${data[data.length - 1].value}`.trim();
                });
              }}
              height={inputsHeight}
            />
          </div>

            <Editor
              defaultLanguage="javascript"
              value={testLogic}
              onChange={onChangeMonaco}
              height={150}
              theme="vs-dark"
            />
        </div>
        <br/>
          <Button
            onClick={testCheck}
            icon="science"
            content='Ejecutar'
            disabled={!cartridge.test?.datacode && !datacode}
          />
        {result.name && (
          <div style={{ paddingTop: "20px" }}>
            <Title text="Resultados:" />
            <div>
             <strong>{result.tests.length} {result.tests.length === 1 ?'test ejecutado' : 'tests ejecutados'}</strong> en <strong>{formatter.roundToDecimals(result.delay, 3)}ms</strong>
            </div>
            <ul
              style={{
                listStyle: "inside",
                margin: "0",
                padding: "0",
                color: "white",
              }}>
              <span>
                <strong>Variables usadas:</strong>
              </span>{" "}
              {usedVariables.map((variable, index) => (
                <li
                  style={{ marginLeft: "20px" }}
                  key={index}>
                  {variable}
                </li>
              ))}
            </ul>
            <div className="test-section actions-buttons-container">
              <div className="columnCheck">
                <h3 style={{ color: "white" }}>
                  Cumplen la comprobación: {passedTests.length}
                </h3>
                {passedTests.length > 0 && (
                  <>

                    {passedTests.map((test, index) => (
                      <Test
                        element={test}
                        key={index}
                      />
                    ))}
                  </>
                )}
              </div>
              <div className="columnCheck">
                <h3 style={{ color: "white" }}>
                  No cumplen la comprobación: {notPassedTests.length}
                </h3>
                {notPassedTests.length > 0 && (
                  <>
                    {notPassedTests.map((test, index) => (
                      <Test
                        element={test}
                        key={index}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TestCheck.propTypes = {
  check: PropTypes.shape({
    name: PropTypes.string,
    logic: PropTypes.string,
    cartridgecode: PropTypes.string,
  }),
};

export default TestCheck;
