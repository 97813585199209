// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actions-text {
  margin-right: 10px;
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.checks-container {
  display: flex;
  flex-direction: row;
  row-gap: 1rem;
  width: 100%;
  justify-content: center;
}

.group-container {
  border-radius: 8px;
  flex-direction: column;
  padding: 10PX;
  row-gap: 5px;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.actions-buttons{
  margin-top: 2rem;
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 750px) {
  .actions-buttons-container {
    flex-direction: column;
    row-gap: 10px;
  }

  .group-container{
    display: flex;
    padding: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/Checks/Checks.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,aAAa;EACf;AACF","sourcesContent":[".actions-text {\r\n  margin-right: 10px;\r\n  margin-bottom: 10px;\r\n  color: #ffffff;\r\n  font-size: 20px;\r\n  display: flex;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  column-gap: 10px;\r\n}\r\n\r\n.checks-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  row-gap: 1rem;\r\n  width: 100%;\r\n  justify-content: center;\r\n}\r\n\r\n.group-container {\r\n  border-radius: 8px;\r\n  flex-direction: column;\r\n  padding: 10PX;\r\n  row-gap: 5px;\r\n  display: flex;\r\n  margin-left: 10px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.actions-buttons{\r\n  margin-top: 2rem;\r\n  display: flex;\r\n  column-gap: 10px;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .actions-buttons-container {\r\n    flex-direction: column;\r\n    row-gap: 10px;\r\n  }\r\n\r\n  .group-container{\r\n    display: flex;\r\n    padding: 24px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
