import { Icon } from '@mui/material';
import React from 'react'
import Input from "src/modules/components/common/Input/Input";

// Value es el nombre del icono, label es el propio icono
const IconsEnum = ({option, onChange}) => {
  return (
    <>
      <Input
        name="value"
        label="Valor:"
        value={option.value}
        onChange={onChange}
      />
      <Input
        name="label"
        label="Icono:"
        value={option.label}
        onChange={onChange}
      />
      <Icon className='option-icon___EnumEditor'>{option.label}</Icon>
    </>
  )
}

export default IconsEnum
