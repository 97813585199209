import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Agent } from "src/lib/api";
import constants from "src/definitions/constants";
import Chat from "src/modules/components/common/Chat/Chat";

/**
 * ChatAgente acts as a container for the Chat component. It adapts the legacy logic
 * for sending messages via the Agent API and maintains settingsData (e.g., thread ID)
 * so that the chat behaves as before.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.agente - Agent configuration.
 * @param {string} props.agente.id - Unique identifier of the agent.
 * @param {string} props.agente.type - Type of the agent.
 * @param {string} props.agente.name - Display name of the agent.
 * @param {string} [props.agente.systemprompt] - (Optional) System prompt for the agent.
 * @param {string} [props.agente.model] - (Optional) Model for the agent (openllm).
 * @param {string} [props.agente.url] - (Optional) URL for the agent (openllm).
 * @param {function} props.onClose - Callback function invoked to close the chat.
 * @returns {JSX.Element} The rendered ChatAgente component.
 */
const ChatAgente = ({ agente, onClose }) => {
  // settingsData holds configuration data needed for API calls.
  const [settingsData, setSettingsData] = useState({
    type: agente.type,
  });

  // State to hold the conversation messages.
  const [messages, setMessages] = useState([]);
  // State to manage the loading state of the agent reply.
  const [loadingAgentMessage, setLoadingAgentMessage] = useState(false);

  // On agent change, update the settingsData accordingly.
  useEffect(() => {
    if (agente.type === constants.tiposAgentes.openai.value) {
      setSettingsData((prev) => ({
        ...prev,
        agentid: agente.id,
        systemprompt: agente.systemprompt,
      }));
    } else if (agente.type === constants.tiposAgentes.openllm.value) {
      setSettingsData((prev) => ({
        ...prev,
        agentid: agente.id,
        model: agente.model,
        url: agente.url,
        systemprompt: agente.systemprompt,
      }));
    } else if (agente.type === constants.tiposAgentes.n8n.value) {
      setSettingsData((prev) => ({
        ...prev,
        agentid: agente.id,
        url: agente.url,
      }));
    } else {
      setSettingsData((prev) => ({
        ...prev,
        agentid: agente.id,
        url: agente.url,
      }));
    }
  }, [agente]);

  /**
   * Adapter function to send a message using the legacy API calls.
   * It replicates the logic previously used in ChatWindow.
   *
   * @async
   * @param {Array.<{text: string, role: ("agent"|"user")}>} conversation - The current conversation (including the new userMessage).
   * @returns {Promise<{text: string, role: "agent"}>} The agent's reply message.
   */
  const sendMessageAdapter = async (conversation) => {
    let response;
    let messageReply;
    try {
      if (settingsData.type === constants.tiposAgentes.openai.value) {
        response = await Agent.question(
          settingsData.agentid,
          conversation,
          settingsData.threadid
        );
        if (response.message) {
          // Si la API devuelve un error en response.message
          return { text: response.message, role: "agent" };
        }
        // Actualizamos el threadid para las siguientes llamadas.
        setSettingsData((prev) => ({
          ...prev,
          threadid: response.agent.agent.thread,
        }));

        messageReply = response.agent.agent.message;
      } else if (settingsData.type === constants.tiposAgentes.openllm.value) {
        response = await Agent.question(settingsData.agentid, conversation);
        messageReply = response?.agent.agent.message;
      } else if (settingsData.type === constants.tiposAgentes.n8n.value) {
        response = await Agent.question(settingsData.agentid, conversation);
        messageReply = response?.agent.agent.message;
      }
      return { text: messageReply, role: "agent" };
    } catch (error) {
      console.error("Error fetching data from the API:", error);
      return {
        text: `Error fetching data from the API ${error}`,
        role: "agent",
      };
    }
  };

  /**
   * Handler invoked when el usuario envía un mensaje.
   *
   * @param {string} userMessage - Mensaje enviado por el usuario.
   */
  const handleSubmitMessage = async (userMessage) => {
    // Agregamos el mensaje del usuario a la conversación.
    const newUserMessage = { text: userMessage, role: "user" };
    setMessages((prevMessages) => [...prevMessages, newUserMessage]);

    // Preparamos la conversación para enviarla al API.
    const conversation = [...messages, newUserMessage];

    // Indicamos que se está esperando la respuesta del agente.
    setLoadingAgentMessage(true);
    const agentResponse = await sendMessageAdapter(conversation);
    // Agregamos la respuesta del agente a la conversación.
    setMessages((prevMessages) => [...prevMessages, agentResponse]);
    setLoadingAgentMessage(false);
  };

  // Handler para limpiar el chat (opcional).
  const handleClearChat = () => {
    setMessages([]);
  };

  return (
    <Chat
      chatTitle={agente.name}
      messages={messages}
      onSubmitMessage={handleSubmitMessage}
      loadingAgentMessage={loadingAgentMessage}
      onClose={onClose}
      onClearChat={handleClearChat}
    />
  );
};

ChatAgente.propTypes = {
  agente: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    systemprompt: PropTypes.string,
    model: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChatAgente;
