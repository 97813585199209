import React from "react";
import MenuCard from "../MenuCard/MenuCard";
import PropTypes from "prop-types";
import Button from "src/modules/components/common/Button/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const MenuList = ({ data, setupDragAndDropCards, setupDragAndDropLists, indexMenuList, deleteMenuList, editMenuList, addMenuCard, handleEditMenuCard, handleDeleteMenuCard }) => {
  return (
    <div className={`menu-list__container ${data.id}`} data-droppable>
      <div className="menu-list-header"
        draggable
        data-dragdrop-index={indexMenuList} // Añadimos el índice para identificafFr la tarjeta
        data-dragdrop-object={`${data.id}`} // Identificamos la tarjeta
        onDragStart={() => setupDragAndDropLists()}>

        <div className="menu-list-header__title">
          {data.title || "Nuevo Menú"}
        </div>
        <div className="menu-list-header__actions">
          <Button
            content={<EditIcon />}
            style={{ minWidth: "0px", fontSize: "0.7rem !important" }}
            onClick={() => editMenuList(data)}

          />
          <Button
            content={<DeleteIcon />}
            style={{ minWidth: "0px", fontSize: "0.7rem !important" }}
            onClick={() => deleteMenuList(data)}
          />
        </div>
      </div>
      <div className="menu-list-cards">
        {data.submenus.map((submenu, index) => (
          <MenuCard
            key={submenu.id + index}
            data={submenu}
            setupDragAndDropCards={setupDragAndDropCards}
            index={index}
            listId={data.id}
            handleDeleteMenuCard={handleDeleteMenuCard}
            handleEditMenuCard={handleEditMenuCard}
          />
        ))}
        <div className="menu-card__container menu__add-button"
          onClick={() => addMenuCard(data.id)}
        >+</div>

      </div>
    </div>
  );
};

export default MenuList;

MenuList.propTypes = {
  data: PropTypes.object.isRequired,
  indexMenuList: PropTypes.number,
  setupDragAndDropCards: PropTypes.func.isRequired,
  setupDragAndDropLists: PropTypes.func.isRequired,
  deleteMenuList: PropTypes.func.isRequired,
  editMenuList: PropTypes.func.isRequired,
  addMenuCard: PropTypes.func.isRequired,
  handleEditMenuCard: PropTypes.func.isRequired,
  handleDeleteMenuCard: PropTypes.func.isRequired

};
