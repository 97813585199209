import React, { useEffect, useState } from "react";
import "./Login.css";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import { useNavigate } from "react-router";
import { User } from "src/lib/api";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import { Auth as AuthAPI } from "src/lib/api";

const Login = () => {
  const {
    publicSetup,
    setName,
    setEmail,
    setControlUserActivity,
    userSession,
    setUserSession,
  } = useAppContext();
  const navigate = useNavigate();
  const [emailLogin, setEmailLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loginServices, setLoginServices] = useState([]);

  useEffect(() => {
    setLoginServices(publicSetup.login);
  }, [publicSetup]);

  const buttonStyle = {
    minHeight: "44px",
    minWidth: "144px",
  };

  useEffect(() => {
    setEmailLogin("");
    setPassword("");
  }, []);

  function handleChange(name, value) {
    if (name === "email") {
      setEmailLogin(value);
    } else {
      setPassword(value);
    }
  }

  const login = async (event) => {
    event.preventDefault();
    if (!emailLogin || !password) {
      return alert("Por favor, rellene todos los campos");
    }

    const response = await AuthAPI.login(emailLogin, password);

    if (!response.token) return alert("Usuario o contraseña incorrectos");

    setUserSession({
      ...userSession,
      token: response.token,
      role: "guest",
    });

    const userData = await User.get();

    setUserSession({
      ...userSession,
      token: response.token,
      role: userData.role || "guest",
    });

    setName(userData.name || "");
    setEmail(userData.email || "");
    if (userData.activity === true) {
      setControlUserActivity(userData.activity.toString());
    }

    navigate("/");
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      login(event);
    }
  };

  return (
    <div>
      <div className="content-container" onKeyDown={onKeyDown}>
        <FormContainer style={{ width: "40%", marginTop: "50px" }}>
          <div className="css-175oi2r">
            <Input
              name="email"
              type="text"
              placeholder="Email"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={emailLogin}
            />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={password}
            />
          </div>
          <div className="css-175oi2c" style={{ alignItems: "center" }}>
            <Button
              content="Iniciar Sesión"
              disabled={!emailLogin || !password}
              buttonStyle={buttonStyle}
              onClick={login}
              id="submit-login"
            />
            <p>¿Has olvidado tu contraseña?</p>
          </div>
          <div className="css-175oi2r line-container">
            <div className="line" />
            <div className="separator">O</div>
            <div className="line" />
          </div>
          <div className="content-container-login-buttons">
            {loginServices.map((el, index) => (
              el && (
                <Button
                  key={`button-${index}`}
                  content={
                    <img
                      src={el.iconurl}
                      alt={el.provider}
                      className="resize_fit_center"
                      style={{ gridColumn: 1, gridRow: 1 }}
                    />
                  }
                  disabled={false}
                  onClick={(ev) => {
                    ev.preventDefault();
                    window.location.href = el.linkurl;
                  }}
                  buttonStyle={buttonStyle}
                />
              )
            ))}
          </div>
        </FormContainer>
        <br />
        <p>
          <a href="https://valeria.moodrobotics.com/docs/app/login">
            ¿Necesitas ayuda para iniciar sesión?
          </a>
        </p>
        <br />
      </div>
    </div>
  );
};

export default Login;
