import React, { useEffect, useState } from "react";
import "./Archives.css";
import { Archive } from "src/lib/api/Archive";
import {
  Home,
  ArrowBack,
  Public,
  Work,
  AddToDriveTwoTone,
  Archive as ArchiveIcon,
} from "@mui/icons-material";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import useFormatters from "src/modules/hooks/useFormatters";
import Input from "src/modules/components/common/Input/Input";
import { Structure as StructureApi } from "src/lib/api";
import LoadingSpinner from "src/modules/components/common/LoadingSpinner/LoadingSpinner";
import Button from "src/modules/components/common/Button/Button";
import { Icon } from "@mui/material";
import DragFileBox from "src/modules/components/common/DragFileBox/DragFileBox";

function Archives() {
  const { formatDate } = useFormatters();
  const [archives, setArchives] = useState([]);
  const [origin, setOrigin] = useState("public");
  const [directory, setDirectory] = useState("%2F");
  const [currentDirectory, setCurrentDirectory] = useState(` /`);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOrigins, setselectedOrigins] = useState([]);
  const [openedModal, setOpenedModal] = useState(false);
  const [openedAddModal, setOpenedAddModal] = useState(false);
  const [addType, setAddType] = useState("");
  const [folderName, setFolderName] = useState("");
  const maxIndex = 0;

  const loadArchives = async (maxIndex, origin, directory) => {
    try {
      const response = await Archive.scan(maxIndex, origin, directory);
      return response;
    } catch (error) {
      console.error("Error loading archives:", error);
      return { archives: [] };
    }
  };

  useEffect(() => {
    const loadRemotes = async () => {
      const fetchedData = await StructureApi.remotes();
      if (fetchedData) {
        const newSelectedOrigins = fetchedData.map((origin) => ({
          value: origin.name,
          label: origin.name,
          description: origin.description,
          icon: origin.icon,
          extlabel:
            origin.name +
            (origin.description !== undefined
              ? ` - ${origin.description}`
              : ""),
        }));
        setselectedOrigins(newSelectedOrigins);
      } else {
        setselectedOrigins([]);
      }
    };
    loadRemotes();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await loadArchives(maxIndex, origin, directory);
      setArchives(
        response.error || response.archives.error ? [] : response.archives
      );
      setIsLoading(false);
    };

    fetchData();
  }, [maxIndex, origin, directory, selectedOrigins]);

  const navigateToHome = async () => {
    try {
      setDirectory("%2F");
      setCurrentDirectory(" /");
      setSearchQuery("");
    } catch (error) {
      console.error("Error navigating to home directory:", error);
      setIsLoading(false);
    }
  };

  const navigateToNextDirectory = async (archivename) => {
    try {
      const newDirectory = `${directory}${archivename}%2F`;
      setDirectory(newDirectory);
      setCurrentDirectory(`${currentDirectory} ${archivename} /`);
      setSearchQuery("");
    } catch (error) {
      console.error("Error navigating to next directory:", error);
      setIsLoading(false);
    }
  };

  const navigateToPreviousDirectory = async () => {
    const indices = [];
    let position = directory.indexOf("%2F");

    while (position !== -1) {
      indices.push(position);
      position = directory.indexOf("%2F", position + 1);
    }

    if (indices.length < 2) {
      return;
    }

    const secondLastIndex = indices[indices.length - 2];
    const newDirectory = directory.substring(0, secondLastIndex + 3);

    try {
      setDirectory(newDirectory);
      const newCurrentDirectory =
        currentDirectory.split(" / ").slice(0, -1).join(" / ") + " /";
      setCurrentDirectory(newCurrentDirectory);
      setSearchQuery("");
    } catch (error) {
      console.error("Error navigating to previous directory:", error);
      setIsLoading(false);
    }
  };

  const handleOriginChange = async (newOrigin) => {
    try {
      setOrigin(newOrigin);
      setDirectory("%2F");
      setCurrentDirectory(" /");
      setSearchQuery("");
    } catch (error) {
      console.error("Error changing origin:", error);
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData =
    archives.length > 0
      ? archives.filter((archive) =>
          archive.archivename.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : [];

  const columns = [
    { key: "icon", label: "", align: "center" },
    { key: "archivename", label: "Nombre", align: "left" },
    { key: "size", label: "Tamaño en bytes", align: "right" },
    { key: "creationtime", label: "Fecha de creación", align: "right" },
    { key: "modtime", label: "Última modificación", align: "right" },
    { key: "readtime", label: "Última apertura", align: "right" },
  ];

  const data = filteredData.map((archive) => ({
    ...archive,
    creationtime: archive.creationtime ? formatDate(archive.creationtime) : "-",
    modtime: archive.modtime ? formatDate(archive.modtime) : "-",
    readtime: archive.readtime ? formatDate(archive.readtime) : "-",
    size: `${archive.size} B`,
  }));

  const handleRowClick = (archive) => {
    if (archive.type === "folder") {
      navigateToNextDirectory(archive.archivename);
    } else {
      console.log("file: ", archive.directory, archive.archivename);
      window.open(archive.link, "_blank");
    }
  };

  const addFolder = () => {
    setOpenedModal(false);
    setOpenedAddModal(true);
    setAddType("folder");
  };

  const addFile = () => {
    setOpenedModal(false);
    setOpenedAddModal(true);
    setAddType("file");
  };

  const createFolder = async (e) => {
    const response = await Archive.mkdir(
      origin,
      `${decodeURIComponent(directory)}${folderName}`
    );
    setOpenedAddModal(false);
    if (response.error) return;
    const { archives } = await loadArchives(maxIndex, origin, directory);
    setArchives(archives);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const createFile = async () => {
    const response = await Archive.upload(
      origin,
      decodeURIComponent(directory),
      selectedFile
    );
    console.log(response);
    setOpenedAddModal(false);
    if (response.error) return;
    const { archives } = await loadArchives(maxIndex, origin, directory);
    setArchives(archives);
    setSelectedFile(null);
  };
  const handleFileChange = (event) => {
    event.preventDefault();
    if (
      event.target?.files?.length > 0 ||
      event.dataTransfer?.files.length > 0
    ) {
      const file =
        event.dataTransfer?.files.length > 0
          ? event.dataTransfer?.files[0]
          : event.target?.files[0];
      setSelectedFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div className="archives">
      <div className="archives__navbar">
        <div className="archives__add-button">
          <Button icon="add" onClick={() => setOpenedModal((prev) => !prev)} />
        </div>
        {openedModal && (
          <div className="archives__modal">
            <ul>
              <li className="archives__list" onClick={() => addFolder()}>
                <Icon>folder</Icon>Carpeta
              </li>
              <li className="archives__list" onClick={() => addFile()}>
                <Icon>description</Icon>Archivo
              </li>
            </ul>
          </div>
        )}
        {openedAddModal &&
          (addType === "folder" ? (
            <div className="archives__add-modal">
              <div className="archives__add-folder">
                <h1>Nueva Carpeta</h1>
                <Input
                  name="folder-name"
                  defaultValue=""
                  onBlur={(e) => setFolderName(e.target.value)}
                  placeholder="Carpeta sin título"
                />
                <div className="archives__add-folder-buttons-container">
                  <Button
                    content="Cancelar"
                    onClick={() => setOpenedAddModal(false)}
                  />
                  <Button content="Crear" onClick={createFolder} />
                </div>
              </div>
            </div>
          ) : (
            <div className="archives__add-modal">
              <div className="archives__add-folder">
                <h1>Nuevo Archivo</h1>
                <DragFileBox
                  style={{ minWidth: "50%", margin: "0 auto" }}
                  handleFileChange={handleFileChange}
                  handleDrop={handleFileChange}
                  handleDragOver={handleDragOver}
                  selectedFile={selectedFile}
                />
                <div className="archives__add-folder-buttons-container">
                  <Button
                    content="Cancelar"
                    onClick={() => setOpenedAddModal(false)}
                  />
                  <Button content="Crear" onClick={createFile} />
                </div>
              </div>
            </div>
          ))}
        <div className="archives__actions">
          <Tooltip title="Public">
            <SvgIcon
              component={Public}
              onClick={() => handleOriginChange("public")}
              className="archives-nav__icon"
            />
          </Tooltip>
          <Tooltip title="Local">
            <SvgIcon
              component={Work}
              onClick={() => handleOriginChange("local")}
              className="archives-nav__icon"
            />
          </Tooltip>
          <Tooltip title="Internal">
            <SvgIcon
              component={ArchiveIcon}
              onClick={() => handleOriginChange("internal")}
              className="archives-nav__icon"
            />
          </Tooltip>
          {selectedOrigins.map((origin, index) => (
            <Tooltip title={origin.extlabel} key={`tooltip-${index}`}>
              <SvgIcon
                component={AddToDriveTwoTone}
                onClick={() => handleOriginChange(origin.value)}
                className="archives-nav__icon"
              />
            </Tooltip>
          ))}
        </div>
        <div className="archives__current-directory">
          <Tooltip title="Directorio raíz">
            <SvgIcon
              component={Home}
              onClick={navigateToHome}
              className="archives-nav__icon"
            />
          </Tooltip>{" "}
          <Tooltip title="Carpeta anterior">
            <SvgIcon
              component={ArrowBack}
              className="archives-nav__icon"
              onClick={navigateToPreviousDirectory}
            />
          </Tooltip>
          / {origin}
          {currentDirectory}
        </div>
        <div className="archives__search-container">
          <Input
            type="search"
            placeholder="Buscar archivos..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="archives__loading">
          <p>Cargando </p>
          <LoadingSpinner />
        </div>
      ) : archives.length <= 0 ? (
        <div className="archives__empty">Esta carpeta está vacía.</div>
      ) : (
        <div className="archives__table-container">
          <table className="archives__table">
            <thead className="archives__table-header">
              <tr>
                {columns.map((column) => (
                  <th
                    className="archives__table-header-cell"
                    key={column.key}
                    style={{ textAlign: column.align }}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="archives__table-body">
              {data.map((archive, index) => (
                <tr
                  className="archives__table-row"
                  key={index}
                  onClick={() => handleRowClick(archive)}
                >
                  {columns.map((column) => (
                    <td
                      className="archives__table-cell"
                      key={`${index}-${column.key}`}
                      style={{ textAlign: column.align }}
                    >
                      {column.key === "icon" ? (
                        <img
                          src={archive[column.key]}
                          alt={archive.archivename}
                          className="archives__file-list-item-img"
                        />
                      ) : (
                        archive[column.key]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Archives;
