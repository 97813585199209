const roleSuperadmin = () => {
    return {
        id: crypto.randomUUID(),
        name: "superadmin",
        description: "Tiene acceso completo a todas las funcionalidades y recursos del sistema sin restricciones.",
        assistants: [],
        policies: ["*"],
    };
}

const roleAdmin = () => {
    return {
        id: crypto.randomUUID(),
        name: "admin",
        description: "Responsable de la gestión del sistema, con acceso a la mayoría de los recursos y funcionalidades, pero con algunas restricciones en las operaciones críticas del sistema.",
        assistants: [],
        policies: ["*"], // Acceso a todas las políticas, similar al superadmin pero puede tener limitaciones específicas según el contexto
    };
}

export const roleDeveloper = () => {
    return {
        id: crypto.randomUUID(),
        name: "developer",
        description: "Encargado de desarrollar y mantener el sistema. Tiene permisos para gestionar entornos de desarrollo, pruebas y configuración técnica. De momento tiene todas las politicas",
        assistants: [],
        policies: [
            "*"
        ], // Políticas específicas para gestión técnica
    };
}

const roleUser = () => {
    return {
        id: crypto.randomUUID(),
        name: "user",
        description: "Usuario estándar del sistema, con permisos básicos para acceder a sus propios datos y realizar tareas comunes.",
        assistants: [],
        policies: ["readdossier", "writedossier", "deletedossier", "readagent", "talkagent", "dossierarchive", "scanarchive", "readarchive", "readassistant", "sendemail", "reademail", "classifyemail", "bank", "readcartridge", "readcheck", "readcomm", "writecomm", "readcomment", "writecomment", "conductoraction", "readdoc", "writedoc", "readenum", "writeenum", "readerror", "writeerror", "readevent", "writeevent", "writefiletype", "readflow", "writeflow", "importfile", "uploadarchive", "readinfo", "listlog", "readmeasure", "writemeasure", "readmovement", "writemovement", "exportmovement", "readquestion", "writequestion", "exportquestion", "readresume", "writeresume", "exportresume", "readsetup", "readtemplate", "readuser", "readview",],
    };
}

const roleWorker = () => {
    return {
        id: crypto.randomUUID(),
        name: "worker",
        description: "Trabajador estándar del sistema, con permisos básicos para acceder solamente a sus dossiers y realizar tareas comunes. Con seguimieno de trabajo",
        assistants: [],
        policies:["readdossier", "writedossier", "dossierarchive", "scanarchive", "readarchive", "readassistant", "sendemail", "reademail", "classifyemail", "readcartridge", "writecartridge", "testcartridge", "readcheck", "writecheck", "testcheck", "readcomm", "writecomm", "readcomment", "writecomment", "readdoc", "writedoc", "readenum", "writeenum", "readerror", "readevent", "writeevent", "readflow", "writeflow", "importfile", "uploadarchive", "readinfo", "readmeasure", "writemeasure", "readmovement", "writemovement", "readquestion", "writequestion", "readresume", "writeresume", "readtemplate", "writetemplate", "readuser", "readview", "writeview"]
      };
}

const roleSuperWorker = () => {
  return {
    id: crypto.randomUUID(),
    name: "superworker",
    description: "Trabajador superior del sistema, tiene acceso a datos de otros trabajadores",
    assistants: [], 
    policies: ["readdossier", "writedossier", "deletedossier", "exportdossier", "readagent", "talkagent", "dossierarchive", "scanarchive", "readarchive", "readassistant", "sendemail", "reademail", "classifyemail", "bank", "readcartridge", "writecartridge", "testcartridge", "deletecartridge", "readcheck", "writecheck", "testcheck", "deletecheck", "readerror", "resetcheck", "readcomm", "writecomm", "resetcommindex", "readcomment", "writecomment", "conductoraction", "readdoc", "writedoc", "readenum", "writeenum", "exportenum", "deleteenum", "readevent", "writeevent", "deleteevent", "readflow", "writeflow", "deleteflow", "resetflow", "importfile", "uploadarchive", "readinfo", "listlog", "readmeasure", "writemeasure", "deletemeasure", "readmovement", "writemovement", "exportmovement", "readquestion", "writequestion", "exportquestion", "readresume", "writeresume", "exportresume", "readtemplate", "writetemplate", "deletetemplate", "readuser", "writeuser", "deleteuser", "readview", "writeview", "deleteview"],
  }
}

export const defaultRoles = () => {
    return [
        roleSuperadmin(),
        roleAdmin(),
        roleUser(),
        roleDeveloper(),
        roleWorker(),
        roleSuperWorker()
    ]
}
