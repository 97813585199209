import React, { useEffect, useState } from "react";
import "./Agentes.css";
import Icon from "@mui/material/Icon";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import ChatAgente from "./ChatAgente/ChatAgente";
import ModalAgente from "./ModalAgente/ModalAgente";
import constants from "src/definitions/constants";

const Agentes = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  const [agentes, setAgentes] = useState(setupInfo?.agents || []);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [editedAgent, setEditedAgent] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [showChat, setShowChat] = useState(false);

  // Actualiza la data en el contexto global
  useEffect(() => {
    setSetupInfo((prev) => ({ ...prev, agents: agentes }));
  }, [agentes, setSetupInfo]);

  const isNewAgent = modalType === "ADD_AGENT";

  // Función para resetear los estados relacionados con el modal
  const resetModal = () => {
    setOpenBox(false);
    setEditedAgent(null);
    setCurrentAgent(null);
    setModalType(null);
  };

  const onCloseChat = () => {
    setShowChat(false);
    setCurrentAgent(null);
  };

  // Al hacer click en un agente se abre el modal en modo edición
  const handleAgenteClick = (index) => {
    const agenteSeleccionado = agentes[index];
    setCurrentAgent(agenteSeleccionado);
    setEditedAgent({ ...agenteSeleccionado });
    setModalType("EDIT_AGENT");
    setOpenBox(true);
  };

  // Actualiza los valores de los inputs del modal
  const handleEditAgente = (e) => {
    setEditedAgent((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const getDefaultAgentData = (agentType, agentData) => {
    const baseValues = {
      id: agentData.id || "",
      name: agentData.name || "",
      description: agentData.description || "",
      type: agentData.type || "",
    };
    switch (agentType) {
      case constants.tiposAgentes.openai.value:
        return {
          ...baseValues,
          apikey: "",
          agentid: "",
          systemprompt: "",
          type: constants.tiposAgentes.openai.value,
        };
      case constants.tiposAgentes.gemini.value:
        return {
          ...baseValues,
          apikey: "",
          agentid: "",
          systemprompt: "",
          type: constants.tiposAgentes.gemini.value,
        };
      case constants.tiposAgentes.openllm.value:
        return {
          ...baseValues,
          url: "",
          model: "",
          systemprompt: "",
          type: constants.tiposAgentes.openllm.value,
        };
      case constants.tiposAgentes.n8n.value:
        return {
          ...baseValues,
          url: "",
          type: constants.tiposAgentes.n8n.value,
        };
      default:
        return {
          ...baseValues,
          apikey: "",
          agentid: "",
          systemprompt: "",
          url: "",
          model: "",
        };
    }
  };
  // Agregar un nuevo agente
  const agregarElemento = () => {
    const newItem = {
      id: crypto.randomUUID(),
      name: "",
      description: "",
      type: "",
      // apikey: "",
      // agentid: "",
      // systemprompt: "",
      // url: "",
      // model: "",
    };
    setCurrentAgent(newItem);
    setEditedAgent(newItem);
    setModalType("ADD_AGENT");
    setOpenBox(true);
  };

  // Guarda el agente ya sea en modo edición o agregación
  const handleSaveAgente = () => {
    if (isNewAgent) {
      setAgentes((prev) => [...prev, editedAgent]);
    } else {
      const updatedAgentes = agentes.map((a) =>
        a === currentAgent ? editedAgent : a
      );
      setAgentes(updatedAgentes);
    }
    resetModal();
  };

  // Elimina el agente seleccionado
  const handleDeleteAgente = () => {
    setAgentes(agentes.filter((a) => a !== currentAgent));
    resetModal();
  };

  // Actualiza el valor de un select (en este caso, el tipo de agente)
  const onSelectChange = (event, property) => {
    if (property === "type") {
      const newType = event?.value;
      const defaultData = getDefaultAgentData(newType, editedAgent);
      setEditedAgent((prev) => ({
        ...prev,
        ...defaultData,
      }));
    } else {
      setEditedAgent((prev) => ({ ...prev, [property]: event?.value }));
    }
  };

  const onClickExit = () => resetModal();

  return (
    <div className="agentes-container">
      {/* Renderiza el modal cuando openBox es true */}
      {openBox && (
        <ModalAgente
          modalType={modalType}
          editedAgent={editedAgent}
          handleEditAgente={handleEditAgente}
          onSelectChange={onSelectChange}
          handleSaveAgente={handleSaveAgente}
          handleDeleteAgente={handleDeleteAgente}
          onClose={onClickExit}
        />
      )}

      <div className="display-agentes">
        {agentes?.map((agente, i) => (
          <div
            key={agente.id || i}
            className="agente-item"
            onClick={() => handleAgenteClick(i)}
          >
            <div className="agente-item-title">{agente.name}</div>
            <div className="agente-item-description">{agente.description}</div>
            <div className="agente-item-iconsInLine">
              <div
                className="agente-item-chatIcon"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentAgent(agente);
                  setShowChat(true);
                }}
              >
                <Icon>chaticon</Icon>
              </div>
            </div>
          </div>
        ))}
        <div
          className="agente-item agente-item-add-last"
          onClick={agregarElemento}
        >
          <p>Agregar más</p>
        </div>
      </div>

      {/* Chat del agente */}
      {showChat && currentAgent && (
        <div className="agente-chat-box">
          <ChatAgente agente={currentAgent} onClose={onCloseChat} />
        </div>
      )}
    </div>
  );
};

export default Agentes;
