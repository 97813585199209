import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppContextProvider } from './modules/contexts/AppContextProvider';
import { UserActivityContextProvider } from "./modules/contexts/UserActivityContextProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppContextProvider>
    <UserActivityContextProvider>
      <App />
    </UserActivityContextProvider>
  </AppContextProvider>
);
