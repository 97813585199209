import { Icon } from "@mui/material";
import React from "react";

const nodeByType = {
  plannerNode: "plannerNode",
  stickyNote: "stickyNote",
};

const scopes = {
  dossier: { value: "dossier", label: "Dossier" },
  filetype: { value: "filetype", label: "Filetype" },
  filetypes: { value: "filetypes", label: "Filetypes" },
  global: { value: "global", label: "Global" },
  templates: { value: "templates", label: "Templates" },
  template: { value: "template", label: "Template" },
  check: { value: "check", label: "Check" },
  checkdocs: { value: "checkdocs", label: "Check Docs" },
  flow: { value: "flow", label: "Flow" },
  flows: { value: "flows", label: "Flujos" },
  user: { value: "user", label: "User" },
  error: { value: "error", label: "Error" },
  event: { value: "event", label: "Evento" },
  events: { value: "events", label: "Eventos" },
  resume: { value: "resume", label: "Resume" },
  measure: { value: "measure", label: "Measure" },
  doc: { value: "doc", label: "Doc" },
  cartridges: { value: "cartridges", label: "Cartridges" },
  cartridge: { value: "cartridge", label: "Cartridge" },
  movement: { value: "movement", label: "Movement" },
  task: { value: "task", label: "Task" },
  view: { value: "view", label: "View" },
  executive: { value: "executive", label: "Executive" },
};

const contents = {
  empty: { value: "", label: "Empty" },
  full: { value: "full", label: "Full" },
  error: { value: "error", label: "Error" },
  doc: { value: "doc", label: "Doc" },
  movement: { value: "movement", label: "Movement" },
  analysis: { value: "analysis", label: "Análisis" },
};

const view = {
  public: { value: "public", label: "Public" },
  private: { value: "private", label: "Private" },
  local: { value: "local", label: "Local" },
};
const roles = {
  superadmin: { value: "superadmin", label: "Superadmin" },
  admin: { value: "admin", label: "Admin" },
  robot: { value: "robot", label: "Robot" },
  hyperuser: { value: "hyperuser", label: "Hyperuser" },
  developer: { value: "developer", label: "Developer" },
  guest: { value: "guest", label: "Guest" },
  user: { value: "user", label: "User" },
  gestor: { value: "gestor", label: "Gestor" },
  supergestor: { value: "supergestor", label: "Supergestor" },
};
const policies = {
  all: { value: "*", label: "All" },
  readcartridge: { value: "readcartridge", label: "Read Cartridge" },
  writecartridge: { value: "writecartridge", label: "Write Cartridge" },
  deletecartridge: { value: "deletecartridge", label: "Delete Cartridge" },
  testcheck: { value: "testcheck", label: "Test Check" },
  readcheck: { value: "readcheck", label: "Read Check" },
  writecheck: { value: "writecheck", label: "Write Check" },
  deletecheck: { value: "deletecheck", label: "Delete Check" },
  resetcheck: { value: "resetcheck", label: "Reset Check" },
  readcheckdock: { value: "readcheckdock", label: "Read Check Dock" },
  writecheckdock: { value: "writecheckdock", label: "Write Check Dock" },
  deletecheckdock: { value: "deletecheckdock", label: "Delete Check Dock" },
  iniidev: { value: "initdev", label: "Init Dev" },
  readdoc: { value: "readdoc", label: "Read Doc" },
  writedoc: { value: "writedoc", label: "Write Doc" },
  deletedoc: { value: "deletedoc", label: "Delete Doc" },
  readdossier: { value: "readdossier", label: "Read Dossier" },
  writedossier: { value: "writedossier", label: "Write Dossier" },
  deletedossier: { value: "deletedossier", label: "Delete Dossier" },
  readerror: { value: "readerror", label: "Read Error" },
  writeerror: { value: "writeerror", label: "Write Error" },
  deleteerror: { value: "deleteerror", label: "Delete Error" },
  readflow: { value: "readflow", label: "Read Flow" },
  writeflow: { value: "writeflow", label: "Write Flow" },
  deleteflow: { value: "deleteflow", label: "Delete Flow" },
  resetflow: { value: "resetflow", label: "Reset Flow" },
  readinfo: { value: "readinfo", label: "Read Info" },
  readmesure: { value: "readmesure", label: "Read Mesure" },
  writemesure: { value: "writemesure", label: "Write Mesure" },
  deletemesure: { value: "deletemesure", label: "Delete Mesure" },
  writemovement: { value: "writemovement", label: "Write Movement" },
  writeresume: { value: "writeresume", label: "Write Resume" },
  deleteresume: { value: "deleteresume", label: "Delete Resume" },
  readresume: { value: "readresume", label: "Read Resume" },
  readsetup: { value: "readsetup", label: "Read Setup" },
  writesetup: { value: "writesetup", label: "Write Setup" },
  deletesetup: { value: "deletesetup", label: "Delete Setup" },
  readtemplate: { value: "readtemplate", label: "Read Template" },
  writetemplate: { value: "writetemplate", label: "Write Template" },
  deletetemplate: { value: "deletetemplate", label: "Delete Template" },
  readuser: { value: "readuser", label: "Read User" },
  writeuser: { value: "writeuser", label: "Write User" },
  deleteuser: { value: "deleteuser", label: "Delete User" },
  readview: { value: "readview", label: "Read View" },
  writeview: { value: "writeview", label: "Write View" },
  deleteview: { value: "deleteview", label: "Delete View" },
};
const boolean = {
  true: { value: true, label: "True" },
  false: { value: false, label: "False" },
};

const frameTypes = {
  list: { value: "list", label: "List" },
  show: { value: "show", label: "Show" },
  template: { value: "template", label: "Template" },
  graph: { value: "graph", label: "Graph" },
  canvas: { value: "canvas", label: "Canvas" },
};
const fonts = {
  arial: { value: "Arial", label: "Arial" },
  verdana: { value: "Verdana", label: "Verdana" },
  tahoma: { value: "Tahoma", label: "Tahoma" },
  trebuchet: { value: "Trebuchet MS", label: "Trebuchet MS" },
  times: { value: "Times New Roman", label: "Times New Roman" },
  georgia: { value: "Georgia", label: "Georgia" },
  garamond: { value: "Garamond", label: "Garamond" },
  courier: { value: "Courier New", label: "Courier New" },
  brush: { value: "Brush Script MT", label: "Brush Script MT" },
  pingLcg: { value: "Ping LCG", label: "Ping LCG" },
};
const templateMode = {
  list: { value: "list", label: "List" },
  form: { value: "form", label: "Form" },
};

const nodeTypes = {
  createdoc: {
    name: "Generar Documento",
    type: nodeByType.plannerNode,
    value: "createdoc",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_document</Icon> Generar Documento
      </div>
    ),
  },
  document: {
    name: "Solicitar Documento",
    type: nodeByType.plannerNode,
    value: "document",
    color: "rgb(0 49 161 / 65%)",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>description</Icon> Solicitar Documento
      </div>
    ),
  },
  event: {
    name: "Evento",
    type: nodeByType.plannerNode,
    value: "event",
    color: "rgb(0 109 7 / 65%)",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>calendar_month</Icon> Evento
      </div>
    ),
  },
  action: {
    name: "Acción",
    type: nodeByType.plannerNode,
    value: "action",
    color: "rgb(92 26 121 / 65%)",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>precision_manufacturing</Icon> Acción
      </div>
    ),
  },
  variable: {
    name: "Variable",
    type: nodeByType.plannerNode,
    value: "variable",
    color: "rgb(142 115 0 / 65%)",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>code</Icon> Variable
      </div>
    ),
  },
  ai: {
    name: "IA",
    type: nodeByType.plannerNode,
    value: "ai",
    color: "rgb(142 0 0 / 65%)",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>psychology</Icon> IA
      </div>
    ),
  },
  note: {
    name: "Nota",
    type: nodeByType.stickyNote,
    value: "note",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>summarize</Icon> Nota
      </div>
    ),
  },
};

const periodicity = {
  none: { value: 0, label: "Ninguno" },
  hourly: { value: 3600000, label: "Cada hora" },
  twohourly: { value: 7200000, label: "Cada dos horas" },
  fourhourly: { value: 14400000, label: "Cada cuatro horas" },
  sixhourly: { value: 21600000, label: "Cada seis horas" },
  twelvehourly: { value: 43200000, label: "Cada doce horas" },
  daily: { value: 86400000, label: "Cada dia" },
  weekly: { value: 604800000, label: "Cada semana" },
  monthly: { value: 2592000000, label: "Cada 30 dias" },
};

const tiposAgentes = {
  openai: {
    label: "Chat GPT",
    value: "openai",
  },
  gemini: {
    label: "Gemini",
    value: "gemini",
  },
  openllm: {
    label: "Open LLM",
    value: "openllm",
  },
  n8n: {
    label: "n8n",
    value: "n8n",
  },
};
const loginProviders = {
  google: {
    label: "Google",
    value: "google",
  },
  facebook: {
    label: "Facebook",
    value: "facebook",
  },
  twitter: {
    label: "Twitter",
    value: "twitter",
  },
  github: {
    label: "Github",
    value: "github",
  },
  linkedin: {
    label: "Linkedin",
    value: "linkedin",
  },
  microsoft: {
    label: "Microsoft",
    value: "microsoft",
  },
  apple: {
    label: "Apple",
    value: "apple",
  },
};

const dataTypeOptions = [
  { label: "Texto", value: "string" },
  { label: "Número", value: "number" },
  { label: "Fecha", value: "date" },
  { label: "Hora", value: "time" },
  { label: "Fecha y hora", value: "datetime-local" },
  { label: "Checkbox", value: "boolean" },
  { label: "Seleccionable", value: "enum" },
  { label: "Objeto", value: "object" },
  { label: "Lista", value: "array" },
  { label: "Botón", value: "button" },
  { label: "Mermaid", value: "mermaid" },
  { label: "Enlace", value: "url" },
];

const stickyNoteThemes = [
  { name: "Amarillo", id: "yellow", color: "#FFFF99" },
  { name: "Claro", id: "light", color: "white" },
  { name: "Oscuro", id: "dark", color: "black" },
  { name: "Verde", id: "green", color: "#99ff99" },
  { name: "Azul", id: "blue", color: "#99ceff" },
  { name: "Violeta", id: "purple", color: "#900db8" },
];

const constants = {
  scopes,
  contents,
  view,
  roles,
  policies,
  boolean,
  frameTypes,
  fonts,
  templateMode,
  nodeTypes,
  periodicity,
  tiposAgentes,
  loginProviders,
  dataTypeOptions,
  nodeByType,
  stickyNoteThemes,
};
export default constants;
