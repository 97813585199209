import React, {useMemo} from "react";
import PropTypes from "prop-types";
import Collapsible from "../../common/Collapsible/Collapsible";
import { EditElement } from "../EditElement2";
import ElementRenderer from "./ElementRenderer";
import Button from "src/modules/components/common/Button/Button";

function ObjectElement({
  element,
  data,
  path,
  selectedTask,
  scrollContainerRef,
  ...props
}) {
  const {
    mustache,
    setupDragAndDrop,
    view,
    frame,
    setView,
    dc,
    deleteItemInArray,
    buttonsStyle,
  } = props;

  const label = useMemo(() => {
    return element.label && element.label.includes("{")
      ? mustache.replaceMustache(element.label, data)
      : element.label;
  }, [element.label, data, mustache]);
  
  const link = useMemo(() => {
    return element.link && element.link.includes("{")
      ? mustache.replaceMustache(element.link, data)
      : element.link;
  }, [element.link, data, mustache]);

  const shouldBeOpen = useMemo(() => {
    if (path && selectedTask) {
      const normalizePath = (p) => p.replace(/\[(\d+)\]/g, ".$1");
      const normalizedPath = normalizePath(path);
      const normalizedSelectedTask = normalizePath(selectedTask);
      return normalizedSelectedTask.includes(normalizedPath) || element.isOpen;
    }
    return element.isOpen;
  }, [path, selectedTask, element.isOpen]);

  return (
    <div
      style={{ display: "flex", gap: "10px" }}
      draggable
      data-dragdrop-object="item"
      data-dragdrop-index={element.variable}
      onDragStart={setupDragAndDrop}
    >
      <EditElement
        element={element}
        view={view}
        frame={frame}
        setView={setView}
        dataCode={dc}
      />
      <Collapsible
        forceExpand={shouldBeOpen}
        key={`${path}-${shouldBeOpen}`}
        open={shouldBeOpen}
        headerStyle={{ padding: "2px 20px 2px 10px" }}
        textStyle={{ padding: "unset" }}
        header={
          <div className="array-header">
            <div className="row-element">
              {element.link ? (
                <a href={link} target="_blank" rel="noreferrer">
                  {label}
                </a>
              ) : (
                <span>{`${label}`}</span>
              )}
            </div>
            <Button
              icon="delete"
              onClick={() => deleteItemInArray(path)}
              name={path}
              buttonStyle={buttonsStyle}
            />
          </div>
        }
      >
        {element.elements?.map((el, elIndex) => {
          const elementVariable = el.variable.split(".").pop();
          const currentPath = path
            ? `${path}.${elementVariable}`
            : elementVariable;

          return (
            <ElementRenderer
              forceChildLoad={shouldBeOpen}
              key={`${currentPath}_${elIndex}`}
              element={el}
              selectedTask={selectedTask}
              data={data ? data[elementVariable] : null}
              path={currentPath}
              scrollContainerRef={scrollContainerRef}
              {...props}
            />
          );
        })}
      </Collapsible>
    </div>
  );
}

ObjectElement.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.any,
  path: PropTypes.string,
  mustache: PropTypes.object.isRequired,
  setupDragAndDrop: PropTypes.func.isRequired,
  view: PropTypes.object.isRequired,
  frame: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
  dc: PropTypes.string.isRequired,
  deleteItemInArray: PropTypes.func.isRequired,
  buttonsStyle: PropTypes.object.isRequired,
  selectedTask: PropTypes.string,
  scrollContainerRef: PropTypes.object,
};

export default React.memo(ObjectElement);
