import React from "react";
import PropTypes from "prop-types";
import "./Value.css";
import Graph from "../Graph/Graph";

const Value = ({ content, type = "auto" }) => {
  let style = {};
  let suffix = "";

  if (type === "auto") {
    if (!content) {
      type = "null";
    } else if (!isNaN(content)) {
      type = "number";
    } else {
      const fechaValida = Date.parse(content);
      if (
        !isNaN(fechaValida) &&
        (content.includes("/") || content.includes("-"))
      ) {
        type = "date";
      } else {
        type = "text"; // Default to text if no other type matches
      }
    }
  }

  switch (type) {
    case "null": {
      style = { float: "right" };
      content = "";
      suffix = "";
      break;
    }
    case "percent": {
      style = { float: "right" };
      suffix = "%";
      break;
    }
    case "currency": {
      style = { float: "right" };
      suffix = "";
      content = Number(content);
      content = content.toLocaleString("es-ES", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      break;
    }
    case "euro": {
      style = { float: "right" };
      suffix = "€";
      content = Number(content);
      content = content.toLocaleString("es-ES", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      break;
    }
    case "dollar": {
      style = { float: "right" };
      suffix = "$";
      content = Number(content);
      content = content.toLocaleString("es-ES", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      break;
    }
    case "number": {
      style = { float: "right" };
      suffix = "";
      content = Number(content);
      content = content.toLocaleString("es-ES");
      break;
    }
    case "date": {
      style = { float: "right" };
      const date = new Date(content);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      content = `${day}/${month}/${year}`;
      break;
    }
    case "smalldate": {
      style = { float: "right" };
      const sdate = new Date(content);
      const sday = sdate.getDate();
      const smonth = sdate.getMonth() + 1;
      const syear = sdate.getFullYear().toString().slice(-2);
      content = `${sday}/${smonth}/${syear}`;
      break;
    }
    case "fixdate": {
      style = { float: "right" };
      const fdate = new Date(content);
      const fday = ("0" + fdate.getDate()).slice(-2);
      const fmonth = ("0" + (fdate.getMonth() + 1)).slice(-2);
      const fyear = fdate.getFullYear();
      content = `${fday}/${fmonth}/${fyear}`;
      break;
    }
    case "fulldate": {
      style = { float: "right" };
      const fulldate = new Date(content);
      const fullfday = ("0" + fulldate.getDate()).slice(-2);
      const fullfmonth = ("0" + (fulldate.getMonth() + 1)).slice(-2);
      const fullfyear = fulldate.getFullYear();
      const fullfhour = ("0" + fulldate.getHours()).slice(-2);
      const fullfminutes = ("0" + fulldate.getMinutes()).slice(-2);
      const fullfseconds = ("0" + fulldate.getSeconds()).slice(-2);
      content = `${fullfday}/${fullfmonth}/${fullfyear} ${fullfhour}:${fullfminutes}:${fullfseconds}`;
      break;
    }
    case "time": {
      style = { float: "right" };
      const time = new Date(content);
      const hour = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      content = `${hour}:${minutes}:${seconds}`;
      break;
    }
    case "smalltime": {
      style = { float: "right" };
      const stime = new Date(content);
      const shour = stime.getHours();
      const sminutes = stime.getMinutes();
      content = `${shour}:${sminutes}`;
      break;
    }
    case "fixtime": {
      style = { float: "right" };
      const ftime = new Date(content);
      const fhour = ("0" + ftime.getHours()).slice(-2);
      const fminutes = ("0" + ftime.getMinutes()).slice(-2);
      const fseconds = ("0" + ftime.getSeconds()).slice(-2);
      content = `${fhour}:${fminutes}:${fseconds}`;
      break;
    }
    case "fulltime": {
      style = { float: "right" };
      const fulltime = new Date(content);
      const fullhour = ("0" + fulltime.getHours()).slice(-2);
      const fullminutes = ("0" + fulltime.getMinutes()).slice(-2);
      const fullseconds = ("0" + fulltime.getSeconds()).slice(-2);
      content = `${fullhour}:${fullminutes}:${fullseconds}`;
      break;
    }
    case "textdate": {
      style = { float: "left" };
      const fecha = new Date(content);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      content = fecha.toLocaleDateString("es-ES", options);
      break;
    }
    case "datetime-local": {
      style = { float: "left" };
      const datefecha = new Date(content);
      const dateoptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      content = datefecha.toLocaleDateString("es-ES", dateoptions);
      break;
    }
    case "mermaid": {
      content = <Graph
        chart={content}
      />
      break;
    }
    default: {
      style = {};
    }
  }

  return (
    <div style={style} title={`${content} ${suffix}`}>
      {content}
      {suffix}
    </div>
  );
};

Value.propTypes = {
  content: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default Value;
