import React from 'react'
import "./DashboardFloating.css"
const DashboardFloating = () => {
    return (
        <div className='demo-dashboard-floating'>
            <span className='demo-dashboard-floating-error'>1 Error  </span>
            <span className='demo-dashboard-floating-warn'>1 Tarea</span>
        </div>
    )
}

export default DashboardFloating