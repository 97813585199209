// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-menu {
  display: flex;
  margin: 5px 5px;
  justify-content: space-between;
  font-size: 14px;
  img {
    max-height: 20px;
  }
}

.demo-menu-navigation {
  display: flex;
  gap: 20px;
}

.demu-menu-navigation-dropdown {
  position: absolute;
  background-color: var(--demo-primary-color);
  padding: 5px;
  border-radius: 6px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 12px;
  margin-top: 8px;
  z-index: 2;
}

.demo-menu-link {
  position: relative;
  font-weight: bold;
}

.demo-menu-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--demo-detail-color);
  bottom: 0;
  left: 0;
  /* transition: width 0.3s ease; */
}

.demo-menu-link:hover::after {
  width: 70%;
}

.demo-menu-link.demo-menu-link-active::after {
  width: 70%;
}
.demo-menu-title {
  font-weight: bolder;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Aspecto/Demo/Menu/Menu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,eAAe;EACf;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,2CAA2C;EAC3C,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,oCAAoC;EACpC,SAAS;EACT,OAAO;EACP,iCAAiC;AACnC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".demo-menu {\r\n  display: flex;\r\n  margin: 5px 5px;\r\n  justify-content: space-between;\r\n  font-size: 14px;\r\n  img {\r\n    max-height: 20px;\r\n  }\r\n}\r\n\r\n.demo-menu-navigation {\r\n  display: flex;\r\n  gap: 20px;\r\n}\r\n\r\n.demu-menu-navigation-dropdown {\r\n  position: absolute;\r\n  background-color: var(--demo-primary-color);\r\n  padding: 5px;\r\n  border-radius: 6px;\r\n  gap: 5px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-end;\r\n  padding-left: 12px;\r\n  margin-top: 8px;\r\n  z-index: 2;\r\n}\r\n\r\n.demo-menu-link {\r\n  position: relative;\r\n  font-weight: bold;\r\n}\r\n\r\n.demo-menu-link::after {\r\n  content: \"\";\r\n  position: absolute;\r\n  width: 0;\r\n  height: 2px;\r\n  background: var(--demo-detail-color);\r\n  bottom: 0;\r\n  left: 0;\r\n  /* transition: width 0.3s ease; */\r\n}\r\n\r\n.demo-menu-link:hover::after {\r\n  width: 70%;\r\n}\r\n\r\n.demo-menu-link.demo-menu-link-active::after {\r\n  width: 70%;\r\n}\r\n.demo-menu-title {\r\n  font-weight: bolder;\r\n  font-size: 18px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
