// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cartridge-img-container{
    display: flex;
    gap: 20px;
  
    img {
      max-width: 120px;
      max-height: 44px;
      margin-top: 10px;
      margin: 10px 10px 0px 10px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/routes/Cartridges/Cartridge.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;;IAET;MACE,gBAAgB;MAChB,gBAAgB;MAChB,gBAAgB;MAChB,0BAA0B;IAC5B;AACJ","sourcesContent":[".cartridge-img-container{\r\n    display: flex;\r\n    gap: 20px;\r\n  \r\n    img {\r\n      max-width: 120px;\r\n      max-height: 44px;\r\n      margin-top: 10px;\r\n      margin: 10px 10px 0px 10px;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
