import React from "react";
import PropTypes from "prop-types"; // Añadido para la validación de props
import Button from "../Button/Button";
import Collapsible from "../Collapsible/Collapsible";
import Input from "../Input/Input";
import Select from "../Select/Select";
import constants from "src/definitions/constants";

const Variables = ({
  variables,
  setVariables,
  availableVariables,
  onCreateOption,
}) => {
  const addVariable = () => {
    const newVariable = {
      question: "",
      variable: "",
      default: "",
      datatype: "",
    };
    const newVars = [...variables, newVariable];
    setVariables(newVars);
  };

  const deleteVariable = (index) => {
    const newVars = variables.filter((variable, i) => i !== index);
    setVariables(newVars);
  };

  const createOption = (variable) => (event) => {
    onChangeData(variable)(event);
    onCreateOption(event);
  };

  const onChangeData = (inputName) => (event) => {
    const [name, index] = inputName.split("_");
    const value = event
      ? event.target
        ? event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
        : event.value
        ? event.value
        : event
      : "";
    const newVariables = [...variables];
    newVariables[index][name] = value;
    if (name === "variable") {
      newVariables[index].datatype = event ? event.type : "";
    }
    setVariables(newVariables);
  };

  return (
    <>
      <Button icon="add" onClick={addVariable} />
      {variables?.length > 0 &&
        variables.map((variable, index) => (
          <Variable
            key={index} // Añadido para la clave en el mapa
            variableData={variable}
            index={index}
            onCreateOption={(e) => createOption(e)}
            onChangeData={(e) => onChangeData(e)}
            deleteVariable={deleteVariable}
            availableVariables={availableVariables}
          />
        ))}
    </>
  );
};

const Variable = ({
  variableData,
  index,
  onChangeData,
  onCreateOption,
  deleteVariable,
  availableVariables,
}) => {

  const whoOptions = [
    { value: "owner", label: "Dueño" },
    { value: "gestor", label: "Gestor" },
    { value: "others", label: "Otros" },
  ];

  return (
    <div className="sidebar-variable-content">
      <Collapsible
        header={
          <div className="array-header">
            {variableData.question || "Nueva Variable"}
            <Button
              icon="delete"
              onClick={() => deleteVariable(index)}
            />
          </div>
        }
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Input
          type="text"
          name={`question_${index}`}
          value={variableData.question}
          label="Pregunta"
          onChange={onChangeData(`question_${index}`)}
          placeholder="Pregunta"
        />
        <Select
          creatable={true}
          onCreateOption={onCreateOption(`variable_${index}`)}
          options={availableVariables}
          name={`variable_${index}`}
          value={
            availableVariables.find(
              (data) => data.value === variableData.variable
            ) ||
            (variableData.variable && {
              label: variableData.variable,
              value: variableData.variable,
            })
          }
          placeholder="dossier.file.name"
          onChange={onChangeData(`variable_${index}`)}
          label="Variable"
        />
        <Input
          type="text"
          name={`default_${index}`}
          value={variableData.default}
          label="Valor por defecto"
          onChange={onChangeData(`default_${index}`)}
        />
        <Select
          options={constants.dataTypeOptions}
          name={`datatype_${index}`}
          value={constants.dataTypeOptions.find(
            (data) => data.value === variableData.datatype
          )}
          placeholder="Texto, Número..."
          onChange={onChangeData(`datatype_${index}`)}
          label="Tipo de dato"
        />
        <Select
          options={whoOptions}
          name={`who_${index}`}
          value={whoOptions.find((data) => data.value === variableData.who)}
          onChange={onChangeData(`who_${index}`)}
          label="Quién?"
        />
        <Input
          type="checkbox"
          name={`must_${index}`}
          checked={variableData.must} // Cambiado a 'checked' para checkbox
          label="Obligatorio"
          onChange={onChangeData(`must_${index}`)}
        />
      </Collapsible>
    </div>
  );
};

// Validaciones de props añadidas
Variables.propTypes = {
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      variable: PropTypes.string,
      default: PropTypes.string,
      datatype: PropTypes.string,
      who: PropTypes.string,
      must: PropTypes.bool,
    })
  ).isRequired,
  setVariables: PropTypes.func.isRequired,
  onCreateOption: PropTypes.func.isRequired,
  availableVariables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

Variable.propTypes = {
  variableData: PropTypes.shape({
    question: PropTypes.string,
    variable: PropTypes.string,
    default: PropTypes.string,
    datatype: PropTypes.string,
    who: PropTypes.string,
    must: PropTypes.bool,
  }).isRequired,
  onCreateOption: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onChangeData: PropTypes.func.isRequired,
  deleteVariable: PropTypes.func.isRequired,
  availableVariables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default Variables;
