import React, { useState } from "react";
import "./Input.css";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";
// type: text, search, password, email, tel, checkbox
// autocomplete : on,off (agregar new- para evitar que autcomplete new-password)
const Input = ({
  type = "text",
  placeholder = "text",
  onChange,
  value,
  disabled,
  label,
  styleInputContainer,
  unit,
  onBlur,
  defaultValue,
  regex,
  tooltip,
  autocomplete,
  ...props
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const handleToggle = () => {
    if (passwordShown) {
      setPasswordShown(false);
    } else {
      setPasswordShown(true);
    }
  };

  // Función para manejar el cambio en el input y validar el regex
  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   if (!regex || new RegExp(regex).test(value)) {
  //     onChange(e);
  //   }
  // };

  const openUrl = () => {
    window.open(value ?? defaultValue, "_blank");
  };

  // Se define qué caracteres se pueden escribir en un input según su tipo
  let checked = props.checked;
  if (checked === "true") checked = true;
  else if (checked === "false") checked = false;
  try {
    return (
      <>
        <div
          className="input-container"
          style={styleInputContainer}>
          {label && (
            <label className="input-label">
              {label}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <Icon
                    className="info-icon"
                    size={20}>
                    info
                  </Icon>
                </Tooltip>
              )}
            </label>
          )}
          {type === "checkbox" ? (
            <label className="custom-checkbox">
              <input
                name={props.name}
                type="checkbox"
                checked={checked || value}
                defaultChecked={defaultValue}
                onChange={onChange}
                disabled={disabled ? "disabled" : ""}
                onBlur={onBlur}
              />
            </label>
          ) : (
            <input
              name={props.name}
              type={
                type === "password"
                  ? passwordShown
                    ? "text"
                    : "password"
                  : type || "text"
              }
              autoComplete={autocomplete ? autocomplete : ""}
              placeholder={placeholder}
              onChange={onChange}
              className={type ? `input-${type}` : "input-text"}
              value={
                type !== "datetime-local" && type !== "date"
                  ? value
                  : value
                    ? new Date(value).toISOString("es-ES").replace(/Z/gi, "")
                    : undefined
              }
              defaultValue={
                type !== "datetime-local" && type !== "date"
                  ? defaultValue
                  : defaultValue
                    ? type === 'datetime-local' ? new Date(defaultValue)
                      .toISOString("es-ES")
                      .replace(/Z/gi, "")
                      : new Date(defaultValue)
                        .toISOString("es-ES").split('T')[0]
                    : undefined
              }
              style={props.style}
              checked={checked}
              disabled={disabled ? "disabled" : ""}
              onBlur={onBlur}
            />
          )}
          {type === "password" && (
            < >
              {passwordShown ? (
                <Icon
                  onClick={handleToggle}
                  className="eye"
                  size={25}>
                  visibility
                </Icon>
              ) : (
                <Icon
                  onClick={handleToggle}
                  className="eye"
                  size={25}>
                  visibility_off
                </Icon>
              )}
            </>
          )}
          {type === "search" && (
            <Icon
              className="search"
              size={25}>
              search
            </Icon>
          )}
          {type === "url" && (
            <Icon
              onClick={openUrl}
              className="eye"
              fontSize="small"
              size={12}>
              open_in_new
            </Icon>
          )}
          {unit && <span className="unit">{unit}</span>}
        </div>
      </>
    );
  } catch (error) {
    console.log(props.name, defaultValue, error);
  }
};

export default Input;
