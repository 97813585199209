// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policies-container {
}

.policies-container-checkboxed {
  gap: 8px;
  display: grid;
}

.policies-checkbox {
}

.policies-checkbox-description {
  display: flex;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Policies/Policies.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,QAAQ;EACR,aAAa;AACf;;AAEA;AACA;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".policies-container {\r\n}\r\n\r\n.policies-container-checkboxed {\r\n  gap: 8px;\r\n  display: grid;\r\n}\r\n\r\n.policies-checkbox {\r\n}\r\n\r\n.policies-checkbox-description {\r\n  display: flex;\r\n  text-align: right;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
