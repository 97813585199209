// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aspecto-container {
  display: flex;
}

.aspecto-container-demo {
  margin-left: 40px;
  width: 50%;
}
.tab-container-aspect .input-container {
  flex: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Aspecto/Aspecto.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".aspecto-container {\r\n  display: flex;\r\n}\r\n\r\n.aspecto-container-demo {\r\n  margin-left: 40px;\r\n  width: 50%;\r\n}\r\n.tab-container-aspect .input-container {\r\n  flex: none !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
