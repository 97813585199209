// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setup-filesync-monaco{
    height:70vh;
    width:50vw;
    margin:0 auto;
}

.setup-filesync-monco-buttons{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/FileSync/FileSync.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".setup-filesync-monaco{\r\n    height:70vh;\r\n    width:50vw;\r\n    margin:0 auto;\r\n}\r\n\r\n.setup-filesync-monco-buttons{\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    margin-bottom: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
