/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";
import { API_URL } from "../../config.js";


export class Archive {
  static async dossier(dossierCode) {
    const response = await HttpMethods.request_get(`/v1/archive/dossier/${dossierCode}`);
    return response;
  }
  static async scan(maxindex, origin, directory) {
    const response = await HttpMethods.request_get(`/v1/archive/scan/${maxindex}/${origin}/${directory}`);
    return response;
  }
  static readurl(origin, directory, file) {
    const path = encodeURIComponent(directory + file);
    const response = `/v1/archive/read/${origin}/${path}`;
    return response;
  }
  static async read(origin, fullfilename) {
    const response = await HttpMethods.request_get(`/v1/archive/read/${origin}/${fullfilename}`);
    return response;
  }
  static async remotes() {
    const response = await HttpMethods.request_get(`/v1/archive/remotes`);
    return response;
  }

  static async mkdir (origin, path) {
    const response = await HttpMethods.request_get(`/v1/archive/mkdir/${origin}/${encodeURIComponent(path)}`);
    if (!response) alert("Error al crear la carpeta");
    return response;
  }
  
  static async upload(origin, path, file) {
    const token = HttpMethods.getToken();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("origin", origin);
    formData.append("path", path);
    const headers = {
        Authorization: token,
    };

    let result = await fetch(`${API_URL}/v1/archive/upload`, {
        headers,
        method: "POST",
        body: formData,
    });
    return result.json();

  }
}
