const dragAndDropCanva = () => {
    return new Promise((resolve) => {
        let drag = null;
        let scrollInterval = null;
        const scrollMargin = 50; // Tamaño de la zona de autoscroll
        const scrollSpeed = 10;  // Velocidad de desplazamiento

        // Maneja el evento de inicio de arrastre.
        const handleDragStart = (pos) => {
            drag = pos;
        };

        // Maneja el evento de soltar.
        const handleDrop = (pos) => {
            if (drag !== null) {
                resolve({ drag, drop: pos });
                resetPositions();
            }
        };

        // Resetea la posición del elemento arrastrado y elimina listeners previos para evitar múltiples ejecuciones.
        const resetPositions = () => {
            drag = null;
            clearInterval(scrollInterval);
            document.removeEventListener('dragstart', dragStartListener);
            document.removeEventListener('drag', dragListener);
            document.removeEventListener('drop', dropListener);
            document.removeEventListener('dragover', dragOverListener);
            document.removeEventListener('drag', dragListenerForScroll);
        };

        // Función recursiva para encontrar el atributo data-index en el elemento o sus padres.
        const findDataIndex = (element) => {
            if (!element) return null;
            const index = element.getAttribute('data-dragdrop-index');
            const object = element.getAttribute('data-dragdrop-object');
            const list = element.closest('[data-droppable]')?.className || null;
            const droppable = element.parentElement?.getAttribute('data-droppable');
            if (index !== null || object !== null || droppable !== null) {
                return { index, object, list };
            }
            return findDataIndex(element.parentElement);
        };

        // Listener para el evento 'dragstart'.
        const dragStartListener = (event) => {
            if (event.target && event.target.getAttribute('draggable')) {
                const pos = findDataIndex(event.target);
                if (pos) {
                    handleDragStart(pos);
                }
            }
        };

        // Listener para el evento 'drag'.
        const dragListener = (event) => {
            const pos = findDataIndex(event.target);
            if (pos) {
                handleDragStart(pos);
            }
        };

        // Listener para el evento 'drop'.
        const dropListener = (event) => {
            event.preventDefault();
            const pos = findDataIndex(event.target);
            if (pos) {
                handleDrop(pos);
            }
        };

        // Listener para el evento 'dragover' que previene el comportamiento por defecto.
        const dragOverListener = (event) => {
            event.preventDefault();
        };

        // Listener para el evento 'drag' que maneja el autoscroll.
        const dragListenerForScroll = (event) => {
            const { clientY, clientX } = event;
            const { innerHeight, innerWidth } = window;

            clearInterval(scrollInterval);

            if (clientY < scrollMargin && clientX > scrollMargin && clientX < innerWidth - scrollMargin) {
                // Desplazamiento hacia arriba
                scrollInterval = setInterval(() => {
                    window.scrollBy(0, -scrollSpeed);
                }, 10);
            } else if (clientY > innerHeight - scrollMargin && clientX > scrollMargin && clientX < innerWidth - scrollMargin) {
                // Desplazamiento hacia abajo
                scrollInterval = setInterval(() => {
                    window.scrollBy(0, scrollSpeed);
                }, 10);
            }
        };

        // Agrega listeners para los eventos de arrastrar y soltar.
        document.addEventListener('dragstart', dragStartListener);
        document.addEventListener('drag', dragListener);
        document.addEventListener('drop', dropListener);
        document.addEventListener('dragover', dragOverListener);
        document.addEventListener('drag', dragListenerForScroll);

        // Devuelve true para indicar que responderá de manera asincrónica.
        return true;
    });
};

export default dragAndDropCanva;
