import React from 'react';
import './Vacations.css';
import PropTypes from "prop-types"

function Vacations({ vacations, onRequest }) {
    return (
        <div className="user-activity-dashboard__vacations--hidden">
            <div className="vacations__header">
                <h2 className="vacations__title">Vacaciones</h2>
                <button className="vacations__request-btn" onClick={onRequest}>Solicitar vacaciones</button>
            </div>
            <div className="vacations__items">
                {vacations.map((vacation, index) => (
                    <div key={index} className="vacation">
                        <span className="vacation__time">{vacation.time}</span>
                        <div className="vacation__circle"></div>
                        <span className="vacation__duration">{vacation.duration}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Vacations;

Vacations.propTypes = {
    vacations: PropTypes.array.isRequired,
    onRequest: PropTypes.func
}