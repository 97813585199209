import userActivityActionTypes from "../actionTypes/userActivityActionTypes";

export const userActivityReducer = (state, action) => {
    switch (action.type) {
        case userActivityActionTypes.SET_CURRENT_TIME:
            return {
                ...state,
                currentTime: action.payload,
                loading: true,
                error: null,
            };
        case userActivityActionTypes.SET_DURATION:
            return {
                ...state,
                duration: action.payload,
                lastDateUpdatedDuration: new Date(),
                loading: true,
                error: null,
            };
        case userActivityActionTypes.FETCH_LAST_ACTION:
            return {
                ...state,
                activity: action.payload,
                loading: true,
                error: null,
            };

        case userActivityActionTypes.SET_ACTIVITIES:
            return {
                ...state,
                activities: action.payload,
                loading: true,
                error: null,
            };
        case userActivityActionTypes.SET_ANALYSIS:
            return {
                ...state,
                analysis: action.payload,
                loading: true,
                error: null,
            };
        default:
            return state;
    }
};

