/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";
import { parseViewData } from "../parseDataApi.js";


export class View  {
    static async read(viewcode) {
        const response = await HttpMethods.request_get(`/v1/view/read/${viewcode}`);
        return response?.view;
    }

    static async list() {
        const response = await HttpMethods.request_get("/v1/view/list");
        if (!response) alert("Error al obtener los views");
        return response?.views;
    }
    static async listflat(first = 0, pagination = 100) {
        let response = await HttpMethods.request_get(`/v1/view/list?format=flat`);
        if (!response) {
            alert("Error al obtener los views");
            response = [];
        }
        return response;
    }
    static async create(data) {
        const parsedData = parseViewData(data);
        const response = await HttpMethods.request_post("/v1/view/create", parsedData);
        if (response.error)
            alert(
                "Error al crear el view." +
                `\nError: ${response.error}: ${response.message}`
            );
        // if (response.result) alert(`${response.result}`);
        return response?.view;
    }

    static async update(data) {
        const parsedData = parseViewData(data);
        const response = await HttpMethods.request_post("/v1/view/update", parsedData);
        if (response.error)
            alert(
                "Error al actualizar el view." +
                `\nError: ${response.error}: ${response.message}`
            );
        // if (response.result) alert(`${response.result}`)
        return response?.view;
    }

    static async crupdate(data) {
      const parsedData = parseViewData(data);
      const response = await HttpMethods.request_post("/v1/view/crupdate", parsedData);
      if (response.error)
          alert(
              "Error al actualizar el view." +
              `\nError: ${response.error}: ${response.message}`
          );
      // if (response.result) alert(`${response.result}`)
      return response?.view;
    }

    static async delete(viewcode) {
        const response = await HttpMethods.request_delete("/v1/view/delete", viewcode);
        if (response.result) alert(`${response.result}`);
        return response;
    }
}