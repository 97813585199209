import viewActionTypes from "../actionTypes/viewActionTypes";
import {
  Agent,
  View,
  Cartridge,
  Check,
  Error,
  Event,
  Executive,
  Filetype,
  Flow,
  Task,
  Movement,
  Template,
  Dossier,
  Doc,
  Resume,
  User,
  Archive,
  Comment,
  Log
} from "src/lib/api.js";

export const fetchView = async (vid, dispatch) => {
  dispatch({ type: viewActionTypes.FETCH_VIEW_REQUEST });

  try {
    const data = await View.read(vid);
    dispatch({ type: viewActionTypes.FETCH_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_VIEW_FAILURE, payload: error.message });
  }
};

export const fetchListData = async (frame, limit, dispatch, props = {}) => {
  dispatch({ type: viewActionTypes.FETCH_DATA_REQUEST });

  try {
    let data = []
    if (frame.scope === 'global') {
      if (frame.datacode === "analysis") {
        data = await Dossier.analysis();
      } else data = [];
    }
    else if(frame.scope === 'error' && frame.filter.includes('email')) {
      data = await Error.listByUser('@me@', 0, limit)
    }
    else {
      const api = getApiByScope(frame.scope);
      data = await api.list(frame._id, 0, limit, props)
    }
    dispatch({ type: viewActionTypes.FETCH_DATA_SUCCESS, payload: { frameId: frame._id, data } });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_DATA_FAILURE, payload: error.message });
  }
}

export const fetchListFlatData = async (frame, limit, dispatch) => {
  dispatch({ type: viewActionTypes.FETCH_DATA_REQUEST });

  try {
    const api = getApiByScope(frame.scope);
    const data = await api.listflat(frame._id, 0, limit)
    dispatch({ type: viewActionTypes.FETCH_DATA_SUCCESS, payload: { frameId: frame._id, data } });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_DATA_FAILURE, payload: error.message });
  }
}

export const fetchShow = async (scope, dataCode, dispatch) => {
  dispatch({ type: viewActionTypes.FETCH_SHOW_REQUEST });

  try {
    const api = getApiByScope(scope);
    const data = await api.read(dataCode);
    dispatch({ type: viewActionTypes.FETCH_SHOW_SUCCESS, payload: { frameId: dataCode, data } });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_SHOW_FAILURE, payload: error.message });
  }
};

export const editView = async (view, dispatch) => {
  dispatch({ type: viewActionTypes.UPDATE_VIEW_REQUEST });

  try {
    await View.update(view)
    dispatch({ type: viewActionTypes.UPDATE_VIEW_SUCCESS, payload: view });
  } catch (error) {
    dispatch({ type: viewActionTypes.UPDATE_VIEW_FAILURE, payload: error.message });
  }
}

export const patchDossier = async (scope, dataCode, action, dataToUpdate, dispatch) => {

  dispatch({ type: viewActionTypes.FETCH_PATCH_REQUEST });

  try {
    const api = getApiByScope(scope);

    const response = await api.modify(dataCode, action);

    if (response) {
      dispatch({ type: viewActionTypes.FETCH_PATCH_SUCCESS, payload: dataToUpdate });
    } else {
      dispatch({ type: viewActionTypes.FETCH_PATCH_FAILURE, payload: "Error: Respuesta inesperada del servidor." });
    }
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_PATCH_FAILURE, payload: error.message });
  }
};

export const crupdateData = async (scope, dataToUpdate, dispatch) => {

  dispatch({ type: viewActionTypes.FETCH_PATCH_REQUEST });

  try {
    const api = getApiByScope(scope);

    const response = await api.crupdate(dataToUpdate);

    if (response.result || response.status === 200) {
      dispatch({ type: viewActionTypes.FETCH_PATCH_SUCCESS, payload: dataToUpdate });
    } else {
      dispatch({ type: viewActionTypes.FETCH_PATCH_FAILURE, payload: "Error: Respuesta inesperada del servidor." });
    }
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_PATCH_FAILURE, payload: error.message });
  }
};

export const fetchListDataCanva = async (frame, limit, dispatch, props = {}) => {
  dispatch({ type: viewActionTypes.FETCH_DATA_REQUEST });

  try {
    let data = []
    if (frame.scope === 'global') {
      if (frame.datacode === "analysis") {
        data = await Dossier.analysis();
      } else data = [];
    }
    else {
      const api = getApiByScope(frame.scope);
      data = await api.listWithoutFlat(frame._id, 0, limit, props)
    }
    dispatch({ type: viewActionTypes.FETCH_DATA_SUCCESS, payload: { frameId: frame._id, data } });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_DATA_FAILURE, payload: error.message });
  }
}

//METAWINDOW

export const loadArchives = async (dossierCode, dispatch, scope) => {

  try {
    const api = getApiByScope(scope);
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: true },
    });
    const response = await api.dossier(dossierCode);

    dispatch({ type: viewActionTypes.SET_META_ARCHIVES, payload: { id: dossierCode, archives: response.archives } });
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: false },
    });
  } catch (error) {
    console.error("Error loading archives:", error);
  }
}

export const loadUsers = async (dispatch) => {
  try {
    const result = await User.list();

    const newUsers = result.map((user) => ({
      label: user.name,
      value: user.email,
    }));

    dispatch({ type: viewActionTypes.SET_META_USERS, payload: newUsers });

  } catch (error) {
    console.error("Error loading archives:", error);
  }
}

export const loadComments = async (dossierCode, dispatch, scope, users = false) => {

  try {
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: true },
    });

    if (users) {
      await loadUsers(dispatch)
    }

    const comments = await Comment.list(scope, dossierCode, "-", "0");

    const sortedComments = comments.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );

    dispatch({ type: viewActionTypes.SET_META_COMMENTS, payload: { id: dossierCode, comments: sortedComments } });
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: false },
    });
  } catch (error) {
    console.error("Error loading comments:", error);
  }
}

export const addComment = async (data, dossierCode, dispatch, scope) => {
  try {
    const response = await Comment.create(data);
    if (response) {
      loadComments(dossierCode, dispatch, scope)// Cuando Pablo devuelva el comentario en lugar de { result: "comment created ok"} añadir el comentario al inicio del array de comentarios
    }
  } catch (error) {
    console.error("Error al agregar el comentario.");
  }
}

export const addReply = async (commentCode, data, dossierCode, dispatch, scope) => {
  try {
    const response = await Comment.reply(data, commentCode);
    if (response) {
      loadComments(dossierCode, dispatch, scope)// Cuando Pablo devuelva el comentario en lugar de { message: "comment updated ok"} añadir la replica al array de replicas del comentario correspondiente
    }
  } catch (error) {
    console.error("Error al responder el comentario.");
  }
}

export const setNewDossierData = (dossierCode, dispatch) => {

  dispatch({ type: viewActionTypes.SET_META_DOSSIER, payload: dossierCode });

}

export const loadDocuments = async (dossierCode, dispatch) => {

  try {
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: true },
    });

    const documents = await Doc.dossier(dossierCode);

    const graphData = await Template.render("doc_timming", dossierCode);

    dispatch({ type: viewActionTypes.SET_META_DOCS, payload: { id: dossierCode, data: { documents, graphData } } });

    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: false },
    });
  } catch (error) {
    console.error("Error loading documents:", error);
  }
}

export const loadErrors = async (dossierCode, dispatch) => {
  try {
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: true },
    });

    const response = await Error.dossier(dossierCode);

    dispatch({ type: viewActionTypes.SET_META_ERRORS, payload: { id: dossierCode, errors: [...response.errors] } });

    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: false },
    });
  } catch (error) {
    console.error("Error loading errors:", error);
  }
}

export const loadFlows = async (dossierCode, dispatch) => {

  try {
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: true },
    });

    const response = await Flow.dossier(dossierCode);

    dispatch({ type: viewActionTypes.SET_META_FLOWS, payload: { id: dossierCode, flows: [...response.flows] } });

    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: false },
    });
  } catch (error) {
    console.error("Error loading flows:", error);
  }
}

export const loadLogs = async (dossierCode, dispatch) => {
  try {
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: true },
    });

    const response = await Log.dossier(dossierCode);

    dispatch({ type: viewActionTypes.SET_META_LOGS, payload: { id: dossierCode, logs: [...response] } });

    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: false },
    });

  } catch (error) {
    console.error("Error loading logs:", error);
  }
}

export const loadTransactions = async (dossierCode, dispatch) => {
  try {
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: true },
    });

    const response = await Movement.list("-", dossierCode);

    dispatch({ type: viewActionTypes.SET_META_TRANSACTIONS, payload: { id: dossierCode, transactions: [...response] } });

    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: false },
    });

  } catch (error) {
    console.error("Error loading transactions:", error);
  }
}

export const loadTaskDocuments = async (dossierCode, taskCode, dispatch) => {
  try {
    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: true },
    });

    const response = await Doc.read(taskCode);

    dispatch({ type: viewActionTypes.SET_META_TASKDOCS, payload: { id: dossierCode, docDetail: response.doc } });

    dispatch({
      type: viewActionTypes.SET_META_LOADING,
      payload: { id: dossierCode, isLoading: false },
    });

  } catch (error) {
    console.error("Error loading docs:", error);
  }
}

export const setSelectedTask = async (pointer, dispatch) => {

  dispatch({ type: viewActionTypes.SET_SELECTED_TASK, payload: pointer });

};

export const createDossier = async (frame, dispatch) => {
  const data = {
    header: {
      filetype: frame.filetype
    },
    file: {}
  } 
  const api = getApiByScope(frame.scope);

  const response = await api.crupdate(data);
  const dossier = response.dossier
  if(dossier) {
    dispatch({ type: viewActionTypes.FETCH_PATCH_SUCCESS, payload: dossier });
    // dispatch({ type: viewActionTypes.FETCH_SHOW_SUCCESS, payload: { frameId: dossier.header.code, dossier } });
    return response.dossier;
  }

  
}

// Selector de repo
const getApiByScope = (scope) => {
  switch (scope) {
    case 'agent': return Agent;
    case 'archives': return Archive;
    case 'comment': return Comment;
    case 'dossier': return Dossier;
    case 'filetype': return Filetype;
    case 'cartridge': return Cartridge;
    case 'check': return Check;
    case 'error': return Error;
    case 'event': return Event;
    case 'executive': return Executive;
    case 'flow': return Flow;
    case 'task': return Task;
    case 'movement': return Movement;
    case 'template': return Template;
    case 'doc': return Doc;
    case 'resume': return Resume;
    case 'user': return User;
    case "view": return View;
    case "executive": return Executive;
    default: return true;
  }
}
