import React from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Input from "src/modules/components/common/Input/Input";
import { saveStructureData } from "src/modules/actions/structureActions";

const StructureMenuDocs = ({ editedItems, dispatch }) => {
  const handleCheckBoxChange = (key, checked) => {
    const payload = {
      filetype: {
        header: {
          ...editedItems.filetype.header,
          [key]: checked,
        },
        structure: [
          ...editedItems.filetype.structure,
        ],
      },
    };
    saveStructureData(payload, dispatch);
  };

  return (
    <div className="dossier-dashboard__section-container">
      <h3>Documentos</h3>
      <p>Indica si el filetype se relaciona con los documentos.</p>
      <div className="structure-header__value">
        <span className="structure-header-key">Activar:</span>
        <Input
          type="checkbox"
          value={editedItems.filetype.header["docs"] || false}
          onChange={(e) => handleCheckBoxChange("docs", e.target.checked)}
          autoFocus
        />
      </div>
    </div>
  );
};

// Validación de props
StructureMenuDocs.propTypes = {
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      header: PropTypes.shape({
        docs: PropTypes.bool,
      }),
      structure: PropTypes.object
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default StructureMenuDocs;
