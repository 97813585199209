import React from 'react'
import "./Demo.css"
import Menu from './Menu/Menu'
import FakeTable from './FakeTable/FakeTable'
import DashboardFloating from './DashboardFloating/DashboardFloating'

const Demo = () => {
    const fakeHeaderData = [
        {
            label: "FileType",
            id: "fileType"
        },
        {
            label: "Descripción",
            id: "descripcion"
        },
        {
            label: "Dossiers",
            id: "dossiers"
        },
        {
            label: "Flujos",
            id: "flujos"
        },
        {
            label: "Cartuchos",
            id: "cartuchos"
        }

    ]
    const fakeBodyData = [
        {
            fileType: "backup_global",
            descripcion: "Global system backup",
            dossiers: "9593 dossiers",
            propietarios: "37 owners",
            flujos: "0/0 defined",
            cartuchos: "/ defined"
        },
        {
            fileType: "report_monthly",
            descripcion: "Monthly financial report",
            dossiers: "1242 dossiers",
            propietarios: "12 owners",
            flujos: "3/4 defined",
            cartuchos: "1/3 defined"
        },
        {
            fileType: "client_data",
            descripcion: "Client data archive",
            dossiers: "4581 dossiers",
            propietarios: "22 owners",
            flujos: "2/2 defined",
            cartuchos: "/ defined"
        },
        {
            fileType: "employee_records",
            descripcion: "Employee records management",
            dossiers: "6712 dossiers",
            propietarios: "15 owners",
            flujos: "1/1 defined",
            cartuchos: "3/5 defined"
        },
        {
            fileType: "project_files",
            descripcion: "Archived project files",
            dossiers: "2378 dossiers",
            propietarios: "7 owners",
            flujos: "5/6 defined",
            cartuchos: "2/2 defined"
        },
        {
            fileType: "logistics_data",
            descripcion: "Logistics and transport data",
            dossiers: "892 dossiers",
            propietarios: "4 owners",
            flujos: "0/1 defined",
            cartuchos: "/ defined"
        },
        {
            fileType: "sales_report",
            descripcion: "Quarterly sales report",
            dossiers: "348 dossiers",
            propietarios: "9 owners",
            flujos: "4/4 defined",
            cartuchos: "2/3 defined"
        }
    ];
    return (
        <div className='demo'>
            <Menu />
            <FakeTable head={fakeHeaderData} body={fakeBodyData} />
            <DashboardFloating />
        </div>
    )
}

export default Demo

