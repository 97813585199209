import React from "react";
import Button from "src/modules/components/common/Button/Button";
import PropTypes from "prop-types";
import "./ExecutiveCard.css";

const getPropertyKey = (variable) => {
  const regex = /\{\{\s*executive\.(\w+)\s*\}\}/;
  const match = variable.match(regex);
  return match ? match[1] : null;
};

export const resolveLink = (link, data) => {
  return link.replace(
    /\{\{\s*executive\.(\w+)\s*\}\}/g,
    (_, key) => data[key] || ""
  );
};

const ExecutiveCard = ({ cardData, frameElements, handleClick }) => {
  const handleNavigation = (url) => {
    handleClick(url);
  };

  const imageElement = frameElements.find(
    (element) => getPropertyKey(element.variable) === "imgurl"
  );

  const otherElements = frameElements.filter(
    (element) =>
      getPropertyKey(element.variable) !== "imgurl" && element.type !== "button"
  );

  const buttonElements = frameElements.filter(
    (element) => element.type === "button"
  );

  return (
    <div className="executive_card">
      {/* Botones de acción (arriba a la derecha) */}
      <div className="executive_card-actions">
        {buttonElements.map((element) => (
          <Button
            key={element._id}
            icon={element.icon}
            onClick={() =>
              handleNavigation(resolveLink(element.link, cardData))
            }
          />
        ))}
      </div>

      {/* Botón de arrastre (arriba a la izquierda) */}
      <div className="executive_card-drag-handle">
        <Button icon="open_with_icon" onClick={() => {}} />
      </div>

      {/* Contenido de la tarjeta */}
      <div className="executive_card-content">
        {/* Mostrar la imagen si existe */}
        {imageElement && (
          <div className="executive_card-image-container">
            {cardData[getPropertyKey(imageElement.variable)] ? (
              <img
                src={cardData[getPropertyKey(imageElement.variable)]}
                alt={cardData.name || "Image"}
                className="executive_card-image"
              />
            ) : (
              <div className="executive_card-placeholder">No Image</div>
            )}
          </div>
        )}

        {/* Mostrar los demás elementos */}
        {otherElements.map((element) => {
          const key = getPropertyKey(element.variable);
          const value = cardData[key];
          if (value === undefined || value === null) return null;
          return (
            <div key={element._id} className="executive_card-element">
              {element.label && <strong>{element.label}</strong>}
              <span>{value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ExecutiveCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  frameElements: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      variable: PropTypes.string.isRequired,
      label: PropTypes.string,
      link: PropTypes.string,
      type: PropTypes.string,
      icon: PropTypes.string,
      align: PropTypes.string,
      format: PropTypes.string,
      options: PropTypes.string,
      elements: PropTypes.array,
      _id: PropTypes.string,
      enum: PropTypes.array,
    })
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ExecutiveCard;
