const userActivityActionTypes = {
    SET_CURRENT_TIME: "SET_CURRENT_TIME",
    SET_ACTIVITIES: "SET_ACTIVITIES",
    FETCH_LAST_ACTION: "FETCH_LAST_ACTION",
    FETCH_ACTION_ERROR: "FETCH_ACTION_ERROR",
    SET_DURATION: "SET_DURATION",
    SET_ANALYSIS: "SET_ANALYSIS",
    FETCH_ANALYSIS_ERROR: "FETCH_ANALYSIS_ERROR",
};

export default userActivityActionTypes