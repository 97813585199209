import React from "react";
import PropTypes from "prop-types";
import Button from "src/modules/components/common/Button/Button";
const MenuCard = ({ data, setupDragAndDropCards, index, listId, handleDeleteMenuCard, handleEditMenuCard }) => (
  <div className="menu-card__container"
    draggable
    data-dragdrop-index={index} // Añadimos el índice para identificafFr la tarjeta
    data-dragdrop-object={`${listId}-${data.id}`} // Identificamos la tarjeta
    onDragStart={() => setupDragAndDropCards()}>
    <div className="menu-card__container-title">
      {data.title}
    </div>
    <div className="menu-card__container-buttons">
      <Button
        onClick={() => { handleEditMenuCard(listId, index) }}
        icon="edit"
        style={{ minWidth: "0px" }}
      />
      <Button
        onClick={() => handleDeleteMenuCard(listId, index)}
        icon="delete"
        style={{ minWidth: "0px" }}
      />
    </div>
  </div>
);

export default MenuCard;

MenuCard.propTypes = {
  data: PropTypes.object.isRequired,
  setupDragAndDropCards: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  listId: PropTypes.string.isRequired,
  handleDeleteMenuCard: PropTypes.func.isRequired,
  handleEditMenuCard: PropTypes.func.isRequired
};
