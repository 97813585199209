import React, { useEffect, useRef } from "react";
import Graph from "../Graph/Graph";
import PropTypes from "prop-types";
import "./MermaidEditor.css";
import { Editor } from "@monaco-editor/react";

const MermaidEditor = ({ graph, onChange, label }) => {
  const editorRef = useRef(null); // Referencia para el editor

  // Esta función se llama cuando el editor se monta
  const handleEditorDidMount = (editor) => {
    editorRef.current = editor; // Guardamos la referencia al editor
    editor.layout(); // Ajustamos el tamaño del editor al montarse
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.layout(); // Redimensiona el editor cuando cambia el gráfico
    }
  }, [graph]); // Dependencia para el nuevo gráfico

  return (
    <span className="mermaid-edit-container">
      {label && <span className="mermaid-edit-label">{label}</span>}
      <div className="mermaid-edit-graph-container">
        <div className="mermaid-editor-container">
          <Editor
            language="mermaid"
            onChange={onChange}
            defaultValue={graph}
            width={500}
            height="200px" // Establece la altura como un string para evitar problemas
            theme="mermaid-dark"
            options={{
              minimap: { enabled: false },
              automaticLayout: true,
            }}
            editorDidMount={handleEditorDidMount} // Asignamos la función de montaje
          />
        </div>
        <div className="mermaid-graph-container">
          <Graph chart={graph} />
        </div>
      </div>
    </span>
  );
};

MermaidEditor.propTypes = {
  graph: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default MermaidEditor;
