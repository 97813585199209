import { Icon } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import './WorkingClock.css'
import { UserActivityContext } from 'src/modules/contexts/UserActivityContextProvider'
import { addMilisecondsToDuration, fetchAction, fetchActivity, milisecondsToHHHMMM } from 'src/modules/actions/userActivityActions'
const activityTypes = {
  start: "start",
  pause: "pause",
  continue: "continue",
  stop: "stop",
  none: "none"
}

const WorkingClock = () => {
  const { state, dispatch } = useContext(UserActivityContext)
  const { activity, duration } = state
  useEffect(() => {
    fetchActivity(dispatch)
  }, [dispatch])

  useEffect(() => {
    let idInterval
    if (activity === 'start' || activity === 'continue') {
      idInterval = setInterval(() => {
        const timeElapsed = Math.abs(new Date() - state.lastDateUpdatedDuration)
        if (timeElapsed >= 60_000) {
          addMilisecondsToDuration(dispatch, duration, 60_000)
        }
      }, 1_000)
    }
    return () => clearInterval(idInterval)
  }, [activity, dispatch, duration, state.lastDateUpdatedDuration])

  function renderIconActivity(activity) {
    switch (activity) {
      case "start": case "continue":
        return "work"
      default:
        return "spa"
    }
  }
  const handleStart = async () => {
    await fetchAction(activityTypes.start, dispatch)
  }

  const handlePause = async () => {
    await fetchAction(activityTypes.pause, dispatch)
  }

  return (
    <div className='header__worktime'>
      <div className="worktime__clock"><Icon >{renderIconActivity(activity)}</Icon><span>{milisecondsToHHHMMM(duration)}</span></div>
      <div className='worktime__clock-dropdown'>
        {['pause', 'stop', 'none'].includes(activity) && (<div className='clock-dropdown__item' onClick={handleStart}><Icon>play_arrow</Icon><span>Continuar</span></div>)}
        {['continue', "start"].includes(activity) && (<div className='clock-dropdown__item' onClick={handlePause}><Icon>pause</Icon><span>Parar</span></div>)}
      </div>

    </div>
  )
}

export default WorkingClock