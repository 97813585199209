import React from 'react';
// import Notifications from './Notifications/Notifications';
import TimeContainer from './TimeContainer/TimeContainer';
// import Calendar from './Calendar/Calendar';
import Absences from './Absences/Absences';
import ClockIns from './ClockIns/ClockIns';
import Vacations from './Vacations/Vacations';
import './UserActivity.css';

// Importar datos de prueba desde data.js
import { /**notifications,*/ absences, vacations } from './testData.js';
import Analysis from './Analysis/Analysis';
import Activity from './Activity/Activity';

function UserActivity() {
    const handleRequestAbsence = () => {
        alert('Solicitar ausencia');
    };

    const handleRequestClockIn = () => {
        alert('Solicitar fichaje');
    };

    const handleRequestVacation = () => {
        alert('Solicitar vacaciones');
    };

    return (
        <div className="user-activity-dashboard">
            {/* Notificaciones */}
            {/* <Notifications notifications={notifications} /> */}
            <Analysis />

            {/* Reloj y Estado de la Jornada */}
            <TimeContainer />

            {/* Calendario */}
            {/* <Calendar title="Calendario">
                <div className="calendar__grid">
                    {['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'].map((day, index) => (
                        <div key={index}>{day}</div>
                    ))}
                </div>
            </Calendar> */}
            <Activity />

            {/* Ausencias */}
            <Absences absences={absences} onRequest={handleRequestAbsence} />

            {/* Fichajes */}
            <ClockIns onRequest={handleRequestClockIn} />

            {/* Vacaciones */}
            <Vacations vacations={vacations} onRequest={handleRequestVacation} />
        </div>
    );
}




export default UserActivity;
