
export const notifications = [
    'Las vacaciones del día 10/10/2024 - 15/10/2024 han sido aprobadas',
    'Tienes 4 ausencias injustificadas',
    'Nueva política de empresa disponible para revisión',
    'Reunión de equipo programada para el 20/10/2024',
  ];
  
  export const absences = [
    { type: 'Ausencia médica', status: 'Aceptada', dates: '01/01/2024 - 01/01/2024' },
    { type: 'Ausencia personal', status: 'Pendiente', dates: '08/01/2024 - 08/01/2024' },
    { type: 'Ausencia familiar', status: 'Rechazada', dates: '05/05/2024 - 05/05/2024' },
    { type: 'Ausencia familiar', status: 'Rechazada', dates: '05/05/2024 - 05/05/2024' },
    { type: 'Ausencia familiar', status: 'Rechazada', dates: '05/05/2024 - 05/05/2024' },
    { type: 'Ausencia familiar', status: 'Rechazada', dates: '05/05/2024 - 05/05/2024' },
    { type: 'Ausencia familiar', status: 'Rechazada', dates: '05/05/2024 - 05/05/2024' },
    { type: 'Ausencia familiar', status: 'Rechazada', dates: '05/05/2024 - 05/05/2024' },
  ];
  
  export const clockIns = [
    { time: '18:30', color: 'blue', duration: '07h 45min' },
    { time: '15:30', color: 'blue', duration: '04h 45min' },
    { time: '09:00', color: 'blue', duration: '08h 00min' },
  ];
  
  export const vacations = [
    { time: '18:30', duration: '07h 45min' },
    { time: '10:00', duration: '04h 00min' },
    { time: '14:15', duration: '05h 30min' },
  ];
  
  