import React from 'react';
import './Absences.css';
import PropTypes from "prop-types"
function Absences({ absences, onRequest = () => { } }) {
    return (
        <div className="user-activity-dashboard__absences--hidden">
            <div className="absences__header">
                <h2 className="absences__title">Ausencias</h2>
                <button className="absences__request-btn" onClick={onRequest}>Solicitar ausencia</button>
            </div>
            <div className="absences__items">
                {absences.map((absence, index) => (
                    <div key={index} className="absence">
                        <div className="absence__header">
                            <span className="absence__type">{absence.type}</span>
                            <span className={`absence__status absence__status--${absence.status.toLowerCase()}`}>
                                {absence.status}
                            </span>
                        </div>
                        <div className="absence__dates">{absence.dates}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Absences;

Absences.propTypes = {
    absences: PropTypes.array.isRequired,
    onRequest: PropTypes.func
}