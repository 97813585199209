import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Cartridge, Flow } from "src/lib/api";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import Table from "src/modules/components/common/Table/Table";
import FormGenerator from "src/modules/components/FormGenerator/FormGenerator";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { useParams } from "react-router-dom";
import LoadingSpinner from "src/modules/components/common/LoadingSpinner/LoadingSpinner";
import { saveStructureData } from "src/modules/actions/structureActions";
const StructureMenuFlows = ({ editedItems, dispatch }) => {
  const { filetypeName } = useParams();
  const generateIdData = editedItems.filetype.header.flows
    ? editedItems.filetype.header.flows.map((item, index) => {
        const id = index;
        return { ...item, id };
      })
    : [];

  const [data, setData] = useState(generateIdData || []);
  const varAddFlow = "addFlow";
  const varEditFlow = "editFlow";
  const [showModal, setShowModal] = useState(null); // addFlow, editFlow
  const [cartridgeOptions, setCartridgeOptions] = useState([]);
  const [refreshingFlows, setRefreshingFlows] = useState(false);
  const flowValuesInit = {
    name: "",
    subject: "",
    cartridge: "",
    default: "",
    logic: "",
  };
  const [flowValues, setFlowValues] = useState(flowValuesInit);

  useEffect(() => {
    async function fetchCartridges() {
      const cartridges = await Cartridge.list();
      const options = cartridges.map((cartridge) => ({
        value: cartridge.cartridgecode,
        label: cartridge.name,
      }));
      setCartridgeOptions(options);
    }

    fetchCartridges();
  }, []);

  const handleCheckBoxChange = (key, checked) => {
    const payload = {
      filetype: {
        header: {
          ...editedItems.filetype.header,
          [key]: checked,
        },
        structure: [
          ...editedItems.filetype.structure,
        ],
      },
    };
    saveStructureData(payload, dispatch);
  };

  useEffect(() => {
    if (editedItems.filetype.header.flows !== data) {
      dispatch({
        type: "UPDATE_HEADER",
        payload: {
          ...editedItems.filetype.header,
          flows: data,
        },
      });
    }
  }, [data, dispatch, editedItems.filetype.header]);

  const deleteRow = (e) => {
    const idElement = e.id;
    const newData = data.filter((item) => item.id !== idElement);
    setData(newData);
  };

  const editRow = (e) => {
    setShowModal(varEditFlow);
    setFlowValues(e);
  };

  const reDoFlow = () => {
    const fetchResetFlow = (filetype) => {
      setTimeout(() => {
        setRefreshingFlows(false);
      }, 3000);
      return Flow.resetOneFlow(filetype);
    };
    setRefreshingFlows(true);
    fetchResetFlow(filetypeName);
  };
  const addNewFlow = (e) => {
    setData((prev) => [
      ...prev,
      {
        ...e,
        id: prev.length,
        filetype: editedItems.filetype.header.name || "",
      },
    ]);
    setShowModal(null);
  };

  const editFlow = (e) => {
    const newData = data.map((item) => {
      if (item.id === flowValues.id) {
        return { ...e, id: item.id };
      }
      return item;
    });
    setData(newData);
    setShowModal(null);
    setFlowValues(flowValuesInit);
  };

  const columns = [
    {
      label: "Nombre",
      key: "name",
      type: "text",
    },
    {
      label: "Sujeto",
      key: "subject",
      type: "string",
    },
    {
      label: "Cartucho",
      key: "cartridge",
      type: "string",
    },
    {
      label: "Por defecto",
      key: "default",
      type: "string",
    },
    {
      label: "Lógica",
      key: "logic",
      type: "string",
    },
    {
      label: "Acciones",
      key: "table_actions",
      type: "actions",
      buttons: [
        {
          icon: "EditIcon",
          onClick: (e) => {
            editRow(e);
          },
        },
        {
          icon: "DeleteIcon",
          onClick: (e) => {
            deleteRow(e);
          },
        },
      ],
    },
  ];

  const elements = [
    {
      name: "name",
      component: "input",
      type: "text",
      label: "Nombre",
      key: "name",
      placeholder: "tramitación",
    },
    {
      name: "subject",
      component: "input",
      type: "text",
      label: "Sujeto",
      key: "subject",
      placeholder: "dossier.file.clientes.fullname",
    },
    {
      name: "cartridge",
      component: "input",
      type: "text",
      label: "Cartucho",
      key: "cartridge",
      placeholder: "dossier.file.contrato.tramites",
    },
    {
      name: "default",
      component: "select",
      type: "text",
      label: "Por defecto",
      key: "default",
      placeholder: "tramitedefecto",
      options: cartridgeOptions,
    },
    {
      name: "logic",
      component: "input",
      type: "text",
      label: "Lógica",
      key: "logic",
    },
  ];

  return (
    <div className="dossier-dashboard__section-container">
      <h3>Flujos</h3>
      <p>Indica si el filetype tiene los flows activados.</p>
      <div className="structure-header__value">
        <span className="structure-header-key">Activado:</span>
        <Input
          type="checkbox"
          defaultValue={editedItems.filetype.header["flow"]}
          onChange={(e) => handleCheckBoxChange("flow", e.target.checked)}
          autoFocus
        />
      </div>
      <div className="structure-body__value">
        <div
          className="structure-body-buttons__value"
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Button
            content="Añadir nuevo flujo"
            onClick={() => setShowModal(varAddFlow)}
            tooltip="Añadir nuevo flujo"
          />
          <Button
            content={refreshingFlows ? <LoadingSpinner /> : <RecyclingIcon />}
            onClick={() => reDoFlow()}
            tooltip="Rehacer flujos"
            disabled={refreshingFlows}
          />
        </div>
        {showModal === varAddFlow && (
          <BoxFloat title="Añadir Flujo" onClickExit={() => setShowModal(null)}>
            <FormGenerator
              form={{ elements: elements }}
              onSubmit={addNewFlow}
              key={flowValues.id}
            />
          </BoxFloat>
        )}
        {showModal === varEditFlow && (
          <BoxFloat title="Editar Flujo" onClickExit={() => setShowModal(null)}>
            <FormGenerator
              form={{ elements: elements }}
              onSubmit={editFlow}
              initialValues={flowValues}
              key={flowValues.id}
            />
          </BoxFloat>
        )}
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
};

// Validación de props
StructureMenuFlows.propTypes = {
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      header: PropTypes.shape({
        flows: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            subject: PropTypes.string,
            cartridge: PropTypes.string,
            default: PropTypes.string,
            logic: PropTypes.string,
          })
        ),
        flow: PropTypes.bool,
        name: PropTypes.string,
        
      }),
      structure: PropTypes.array
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default StructureMenuFlows;
