import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client"; // Cambia a ReactDOM.createRoot
import PropTypes from "prop-types";
import Graph from "src/modules/components/common/Graph/Graph";
import Icon from "@mui/material/Icon";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Template } from "src/lib/api.js";
import { useLocation, useNavigate } from "react-router";

// Define valores por defecto para las props en caso de que no se pasen
const TemplateFrame = ({ frame }) => {
  const navigate = useNavigate()
  const { role, publicSetup } = useAppContext(); // Elimina 'name' y 'email'
  const [viewcontent, setviewcontent] = useState(
    <>
      <p>
        Loading template {frame.templatecode}/{frame.datacode} ...
      </p>
    </>
  );
  const [subviewcontent, setsubviewcontent] = React.useState([]);
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  function editTemplate(templateCode) {
    navigate(`/template/edit?templatecode=${templateCode}`);
  }
  useEffect(() => {
    const fetchTemplate = async () => {
      const data = await Template.render(
        frame.templatecode,
        queryParams.get('dc'),
        frame.config?.content
      );
      if (!data || data?.error || data?.message) {
        setviewcontent(
          <p>
            Problema al procesar el Template &quot;{frame.templatecode}&quot; con code &quot;
            {frame.datacode}&quot; : {data?.error} - {data?.message}
          </p>
        );
      } else {
        const { component, graphs } = createHtml(data);
        setviewcontent(component);
        setsubviewcontent(graphs);
      }
    };
    fetchTemplate();
  }, [frame]);

  // Para cada gráfico mermaid encontrado en el html, lo renderiza
  useEffect(() => {
    subviewcontent.forEach((graph, index) => {
      const element = document.getElementById(`viaGraph-${index + 1}`);
      if (element) {
        const root = ReactDOM.createRoot(element); // Usa ReactDOM.createRoot
        root.render(<Graph chart={graph} />);
      }
    });
  }, [subviewcontent]);

  return (
    <div className="content-container">
      {publicSetup.developmode &&
        (role === "developer" || role === "superadmin") && (
          <div
            className="editColumnsModal"
            style={{
              position: "absolute",
              top: "45px",
              left: "10px",
              zIndex: 1001,
            }}
            title="Pulse aquí para editar la plantilla."
          >
            <Icon
              style={{
                color: "orange",
                border: "2px solid blue",
                borderRadius: "50%",
                cursor: "pointer"
              }}
              onClick={() => {
                editTemplate(frame.templatecode);
              }}
            >
              edit
            </Icon>
          </div>
        )}
      <div className="groupSetup">
        <div className="setup-container">{viewcontent}</div>
      </div>
    </div>
  );
};


TemplateFrame.propTypes = {
  frame: PropTypes.object.isRequired,
};

function createHtml(html) {
  const graphs = [];
  const re = /<pre lang="mermaid">([^]*?)<\/pre>/g;
  html = html
    ? html.replace(re, function (match, p1, offset, string) {
        graphs.push(p1);
        return `<div id="viaGraph-${graphs.length}">Graph ${graphs.length}</div>`;
      })
    : "";
  return {
    component: <div dangerouslySetInnerHTML={{ __html: html }} />,
    graphs,
  };
}

export default TemplateFrame;
