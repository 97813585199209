import React, { useContext } from 'react';
import './JornadaInfo.css';
import { UserActivityContext } from 'src/modules/contexts/UserActivityContextProvider';
import { milisecondsToHHHMMM } from 'src/modules/actions/userActivityActions';

function JornadaInfo() {
    const { state } = useContext(UserActivityContext)
    return (
        <div className="time-container__jornada-info">
            <span className="jornada-info__day">hoy</span>
            <span className="jornada-info__duration">{milisecondsToHHHMMM(state.duration)}</span>
        </div>
    );
}

export default JornadaInfo;