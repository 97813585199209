import React, { useEffect, useState } from "react";
import { Dossier, Template, Flow, Check, Error, Dev } from "src/lib/api.js";
import "./Developer.css";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Tabs from "src/modules/components/header/Tabs/Tabs";
import Title from "src/modules/components/common/Title/Title";
import Iframe from "src/modules/components/common/Iframe/Iframe";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import LoadingSpinner  from "src/modules/components/common/LoadingSpinner/LoadingSpinner";

function Developer() {
  const [inputValues, setInputValues] = useState({
    dossiercode: "",
  });
  const [showStructure, setShowStructure] = useState(false);
  const [structure, setStructure] = useState({});
  const [explain, setExplain] = useState(false);
  const { setNavBarTitle } = useAppContext();

  useEffect(() => {
    setNavBarTitle("Desarrollador");
  });

  const handleInputChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  };
  const clickTemplate = async () => {
    const template = {
      name: "Template created from frontend developer",
      description:
        "This template was created from the frontend developer in auto mode",
      filename: "fichero",
      scope: "dossier",
      view: "private",
      active: true,
    };
    const response = await Template.create(template, inputValues.dossiercode);
    if (response) {
      setShowStructure(true);
    }
  };
  const dossierStructure = async () => {
    const response = await Dossier.structure(inputValues.dossiercode);
    if (response) {
      setStructure(response);
      setShowStructure(true);
    }
  };
  const dossierExplain = async () => {
    const response = await Dossier.explain(inputValues.dossiercode);
    if (response) {
      setExplain(response);
      setShowStructure(true);
    }
  };

  const mihtml = "<h1>hola</h1>";

  // const dossierRender = async () => {
  //   // Supongamos que dossierCode está disponible aquí
  //   const dossierCode = inputValues.dossiercode;
  //   const response = Dossier.render(dossierCode);
  //   if (response) {
  //     setRender(response);
  //     setShowRender(true);
  //   }
  // };

  const renderStructure = () => {
    const arrayPropiedades = Object.keys(structure);
    return (
      <>
        {arrayPropiedades.map((el, index) => (
          <div className="dossier-prop-container" key={index}>
            <div className="dossier-prop">
              <p>{el}:</p>
            </div>
            <div className="dossier-prop">
              <p>{structure[el]}</p>
            </div>
          </div>
        ))}
        {explain && <div className="dossier-prop">{createHtml(explain)}</div>}

        {createHtml(mihtml)}
        <h1>Mi componente con iframe</h1>
        <Iframe
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"
          title="Video de Rick Astley - Never Gonna Give You Up"
          width="560"
          height="315"
          frameBorder="0"
          allowFullScreen
        />
      </>
    );
  };
  const flowReset = (content) => {
    const resetFlowsButton = showData[1].buttons.find(
      (button) => button.content === "Resetea los flows"
    );
    delete resetFlowsButton.content;
    resetFlowsButton.disabled = true;
    setTabsData(createTabs(showData));

    setTimeout(async () => {
      await Flow.reset();
      resetFlowsButton.content = "Resetea los flows";
      delete resetFlowsButton.disabled;
      setResetted("Flows reseteados correctamente");
      setTabsData(createTabs(showData));
    }, 2000);
  };
  const checkReset = async () => {
    await Check.reset();
    setResetted("Checks reseteados correctamente");
  };
  const cleanErrors = async () => {
    await Error.clean();
    setResetted("Errores limpiados correctamente");
  };
  const menuReset = async () => {
    await Dev.fillmenu();
    window.location.reload();
    await setResetted("Menus, templates y views actualizados correctamente");
  };
  // Conductor
  const sendaction = async () => {
    const data = {
      name: inputValues.name,
      data: inputValues.data,
    };
    await Dev.action(data);
    setActionresponse("Accion enviada correctamente");
  };
  const [reseted, setResetted] = useState("");
  const [actionresponse, setActionresponse] = useState("");

  const showData = [
    {
      name: "Dossiers",
      id: "estructura",
      inputs: [
        {
          name: "dossiercode",
          type: "text",
          placeholder: "dossier.header.code",
          value: inputValues.dossiercode,
          onChange: handleInputChange,
          label: "Code del dossier.",
        },
      ],
      buttons: [
        {
          content: "Estructura",
          onClick: dossierStructure,
        },
        {
          content: "Explain",
          onClick: dossierExplain,
        },
        {
          content: "auto Template",
          onClick: clickTemplate,
        },
      ],
      showComponent: showStructure,
      renderComponent: renderStructure,
    },
    {
      name: "Reset",
      id: "autorender",
      inputs: [],
      buttons: [
        {
          content: "Resetea los flows",
          onClick: () => flowReset("Resetea los flows"),
        },
        {
          content: "Resetea los checks",
          onClick: checkReset,
        },
        {
          content: "limpia los errores",
          onClick: cleanErrors,
        },
        {
          content: "Actualiza menús",
          onClick: menuReset,
        },
      ],
      showComponent: reseted,
      renderComponent: () => <Title text={reseted} />,
    },
    {
      name: "Conductor",
      id: "conductor",
      inputs: [
        {
          name: "name",
          type: "text",
          placeholder: "action1",
          value: inputValues.name,
          onChange: handleInputChange,
          label: "name",
        },
        {
          name: "data",
          type: "text",
          placeholder: '{"var1": "value1", "var2": "value2"}',
          value: inputValues.data,
          onChange: handleInputChange,
          label: "data",
        },
      ],
      buttons: [
        {
          content: "send action",
          onClick: sendaction,
        },
      ],
      showComponent: actionresponse,
      renderComponent: () => <Title text={actionresponse} />,
    },
  ];
  const [tabsData, setTabsData] = useState(createTabs(showData));

  useEffect(() => {
    setTabsData(createTabs(showData));
  }, [reseted, actionresponse, showStructure, explain, inputValues]);

  return (
    <div className="content-container">
      <div className="groupSetup">
        <div className="setup-container">
          <Tabs tabsData={tabsData} />
        </div>
      </div>
    </div>
  );
}

function createHtml(html) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

function createTabs(data) {
  return data.map((tab) => ({
    name: tab.name,
    component: (
      <>
        <div className="dev-element">
          {tab.inputs.map((input) => (
            <Input
              key={input.name}
              name={input.name}
              type={input.type}
              placeholder={input.placeholder}
              value={input.value}
              onChange={input.onChange}
              label={input.label}
              activeLabel={true}
            />
          ))}
          {tab.buttons.map((button) => (
            <Button
              key={button.content}
              onClick={button.onClick}
              content={button.content ? button.content : <LoadingSpinner />}
              buttonStyle={{ maxWidth: "200px" }}
              disabled={button.disabled}
            />
          ))}
        </div>
        {tab.showComponent && tab.renderComponent()}
      </>
    ),
    id: tab.id,
  }));
}

export default Developer;
